/*******************************/
/* FourSeasonsScrollStatistics */
/**********************************************************************************************/
/* Calculates and presents all statistics related to the Four Sceasons scroll.                */ 
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC, all rights reserved.                           */
/**********************************************************************************************/
import {FourSeasonsConstants,
        FourSeasonsColors}        from './FourSeasonsConst.js'                   ;

        
/*******************************/
/* FourSeasons_SetScrollMarker */
/**********************************************************************************************/
/* Save the specified scroll marker for later access.                                         */
/* @param whichMarker identifies the scroll marker about which information will be set.       */
/* @param marker      is the indicated marker element.                                        */
/**********************************************************************************************/
let FourSeasons_Marker = {
  [FourSeasonsConstants.scrollMarker.scrollMarker_Top   ] : null,
  [FourSeasonsConstants.scrollMarker.scrollMarker_Spring] : null,
  [FourSeasonsConstants.scrollMarker.scrollMarker_Summer] : null,
  [FourSeasonsConstants.scrollMarker.scrollMarker_Autumn] : null,
  [FourSeasonsConstants.scrollMarker.scrollMarker_Winter] : null,
  [FourSeasonsConstants.scrollMarker.scrollMarker_Bottom] : null,
}; /* FourSeasons_Marker */

export const FourSeasons_SetScrollMarker = (whichMarker, marker) => {
  // remember this (exitButton) element but if only (whichExitButton) is known:
  switch (whichMarker) {
    case FourSeasonsConstants.scrollMarker.scrollMarker_Top :
      FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Top   ] = marker;
      break;
    case FourSeasonsConstants.scrollMarker.scrollMarker_Spring :
      FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Spring] = marker;
      break;
    case FourSeasonsConstants.scrollMarker.scrollMarker_Summer :
      FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Summer] = marker;
      break;
    case FourSeasonsConstants.scrollMarker.scrollMarker_Autumn :
      FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Autumn] = marker;
      break;
    case FourSeasonsConstants.scrollMarker.scrollMarker_Winter :
      FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Winter] = marker;
      break;
    case FourSeasonsConstants.scrollMarker.scrollMarker_Bottom :
      FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Bottom] = marker;
      break;
    default :
  }; /*switch*/
}; /* FourSeasons_SetScrollMarker */


/*******************************/
/* FourSeasons_GetScrollMarker */
/**********************************************************************************************/
/* Retrieve access to the specific marker element.                                            */
/* @param  whichMarker identifies the scroll marker about which information will be set.      */
/* @return             the indicated marker element or null.                                  */
/**********************************************************************************************/
export const FourSeasons_GetScrollMarker = (whichMarker) =>{
  // return the known marker element but only when (whichExitButton) is valid:
  switch (whichMarker) {
    case FourSeasonsConstants.scrollMarker.scrollMarker_Top :
      return (FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Top]);
    case FourSeasonsConstants.scrollMarker.scrollMarker_Spring :
      return (FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Spring]);
    case FourSeasonsConstants.scrollMarker.scrollMarker_Summer :
      return (FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Summer]);
    case FourSeasonsConstants.scrollMarker.scrollMarker_Autumn :
      return (FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Autumn]);
    case FourSeasonsConstants.scrollMarker.scrollMarker_Winter :
      return (FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Winter]);
    case FourSeasonsConstants.scrollMarker.scrollMarker_Bottom :
      return (FourSeasons_Marker[FourSeasonsConstants.scrollMarker.scrollMarker_Bottom]);
  }; /*switch*/

  // invalid input:
  return null;
}; /* FourSeasons_GetScrollMarker */


export class FourSeasonsScrollStatistics {


  /*---------*/
  /* Getters */
  /*---------------------------------------------------------------------------------------------*/
  /* Access to all statistical data                                                              */
  /*---------------------------------------------------------------------------------------------*/

  
  /****************/
  /* GetXitNumber */
  /********************************************************************************************/
  /* Gain access to the enumerated number of the calculated Xit button to display.            */
  /*                                                                                          */
  /* @return exit button number.                                                              */ 
  /********************************************************************************************/
  static GetXitNumber() {
    // return a calculated exit number if there is one:
    if (FourSeasonsScrollStatistics.ScrollResults.CalculatedXitButton) {
      return (FourSeasonsScrollStatistics.ScrollResults.CalculatedXitButton);
    } /*if*/
    
    // default: Spring
    return (FourSeasonsConstants.xItNumber.xItSpring);
  }; /* GetXitNumber */
  

  /*********************/
  /* GetSaturatedColor */
  /********************************************************************************************/
  /* Gain access to the saturated color to use.  This string has the format of "#RRGGBB" .    */
  /*                                                                                          */
  /* @return the saturated color this class has calculated using the last invocation of       */
  /*         CalculateStats().                                                                */
  /*         The default value is the topmost value of the scroll.                            */
  /********************************************************************************************/
  static GetSaturatedColor() {
    if ((FourSeasonsScrollStatistics.ScrollResults) &&
        (FourSeasonsScrollStatistics.ScrollResults.BackgroundRGB.saturated)) {
      return (FourSeasonsScrollStatistics.ScrollResults.BackgroundRGB.saturated);
    } /*if*/
      
    return (FourSeasonsColors.saturatedColor.markerSpring);
  }; /* GetSaturatedColor */
  

  /*********************/
  /* GetLightenedColor */
  /********************************************************************************************/
  /* Gain access to the lightened color to use.  This string has the format of "#RRGGBB" .    */
  /*                                                                                          */
  /* @return the lightened color this class has calculated using the last invocation of       */
  /*         CalculateStats().                                                                */ 
  /*         The default value is the topmost value of the scroll.                            */
  /********************************************************************************************/
  static GetLightenedColor() {
    if ((FourSeasonsScrollStatistics.ScrollResults) &&
        (FourSeasonsScrollStatistics.ScrollResults.BackgroundRGB.lightened)) {
      return (FourSeasonsScrollStatistics.ScrollResults.BackgroundRGB.lightened); 
    } /*if*/
      
    return (FourSeasonsColors.readingColor.markerSpring);
  }; /* GetLightenedColor */
  

  /****************************/
  /* GetScapeAboveInformation */
  /********************************************************************************************/
  /* Gain access to information about the 'above' scape for the Scape Animation.              */
  /*                                                                                          */
  /* @return information about the 'above' scape.                                             */
  /*         members:  marker and opacity                                                     */
  /********************************************************************************************/
  static GetScapeAboveInformation() {
    if ((FourSeasonsScrollStatistics.ScrollResults) &&
        (FourSeasonsScrollStatistics.ScrollResults.Scape)) {
      return (FourSeasonsScrollStatistics.ScrollResults.Scape.above); 
    } /*if*/

    return ({
              marker  : (FourSeasonsConstants.xItNumber.xItSpring),
              opacity : 1, 
           });
  }; /* GetScapeAboveInformation */

  
  /****************************/
  /* GetScapeBelowInformation */
  /********************************************************************************************/
  /* Gain access to information about the 'above' scape for the Scape Animation.              */
  /*                                                                                          */
  /* @return information about the 'above' scape.                                             */
  /*         members:  marker and opacity                                                     */
  /********************************************************************************************/
  static GetScapeBelowInformation() {
    if ((FourSeasonsScrollStatistics.ScrollResults) &&
        (FourSeasonsScrollStatistics.ScrollResults.Scape)) {
      return (FourSeasonsScrollStatistics.ScrollResults.Scape.below); 
    } /*if*/

    return ({
              marker  : (FourSeasonsConstants.xItNumber.xItSummer),
              opacity : 1, 
           });
  }; /* GetScapeBelowInformation */

  
  
  /*-------------------------*/
  /* Calculators             */
  /*---------------------------------------------------------------------------------------------*/
  /* The consumer process need not worry about nor access the calculators                        */
  /*---------------------------------------------------------------------------------------------*/

  
  /***************************/
  /* CalculateExitStatistics */
  /********************************************************************************************/
  /* Calculate which of the exit buttons to display and set the appropriate styles.           */
  /* This experience will show only a single, whole exit button at a time.  Which exit button */
  /* that this experience will display depends entirely upon which marker is closest to the   */
  /* top of the dialog but still at or above it.                                              */
  /********************************************************************************************/
  static CalculateExitStatistics() {
  
    // Find the one exit button which is closest, but less than the top scroll of the box.
    var markerWinner = FourSeasons_GetScrollMarker(FourSeasonsConstants.scrollMarker.scrollMarker_Top);
    
    for (var marker in FourSeasonsConstants.scrollMarker) {
      var markerElement = FourSeasons_GetScrollMarker(FourSeasonsConstants.scrollMarker[marker]);
      if ((markerElement.offsetTop > markerWinner.offsetTop) &&
          (markerElement.offsetTop <=  FourSeasonsScrollStatistics.CurrentScrollPixels.top)) {
        /* a new winner */
        markerWinner = markerElement;
      } /*if*/
    }; /*for*/
    
    FourSeasonsScrollStatistics.ScrollResults.CalculatedXitButton = FourSeasonsColors.xItNumber[markerWinner.id];
  }; /* animateExit */


  /*******************************/
  /* CalculateVerticalInfluences */
  /********************************************************************************************/
  /* Calculate and return vertical influence.                                                 */
  /* Products of this calculation are the two markers, above marker and below marker,         */
  /* and their respective vertical influence from the above marker and from the below marker. */
  /* Markers will be of the set of scroll markers, influences will be percentage that sum to  */
  /* one.                                                                                     */
  /*                                                                                          */
  /* @return an object.  Object references are:                                               */
  /*     .above.marker                                                                        */
  /*     .above.influence                                                                     */
  /*     .below.marker                                                                        */
  /*     .below.influence                                                                     */
  /********************************************************************************************/
  static CalculateVerticalInfluences() {
    // Loop through the (FourSeasonsMarker)s and find the closest one above the point-of-reference
    // and the closest one below the point-of-reference.
    var markerAboveWinner      = FourSeasons_GetScrollMarker(FourSeasonsConstants.scrollMarker.scrollMarker_Top);
    var markerAboveWinnerID    = FourSeasonsConstants.xItNumber.xItSpring;
    var markerBelowWinner      = FourSeasons_GetScrollMarker(FourSeasonsConstants.scrollMarker.scrollMarker_Bottom);
    var markerBelowWinnerID    = FourSeasonsConstants.xItNumber.xItWinter;
    
    var verticalOffsetInScroll = (( FourSeasonsScrollStatistics.CurrentScrollPixels.top 
                                  + FourSeasonsScrollStatistics.CurrentScrollPixels.bottom) / 2 );

    // find the two scroll markers that will allow this method to calculate background colors:
    // the selected top marker will be the lowest above the target scroll value (verticalOffsetInScroll)
    // the selected bottom marker will be the highest below this value
    for (var marker in FourSeasonsConstants.scrollMarker) {
      var markerElement = FourSeasons_GetScrollMarker(FourSeasonsConstants.scrollMarker[marker]);
      if ((markerElement.offsetTop > markerAboveWinner.offsetTop) && 
          (markerElement.offsetTop <= verticalOffsetInScroll)) {
        markerAboveWinner   = markerElement;
        markerAboveWinnerID = FourSeasonsColors.xItNumber[markerElement.id];
      }
      if ((markerElement.offsetTop < markerBelowWinner.offsetTop) && 
          (markerElement.offsetTop >= verticalOffsetInScroll)) {
        markerBelowWinner   = markerElement;
        markerBelowWinnerID = FourSeasonsColors.xItNumber[markerElement.id];
      }      
    }; /*for*/

    // calculate the influence of the two markers on the background color
    // the marker closer to the target vertical offset will have a mathematically greater
    // influence over the final, calculated colors.
    var verticalDistanceToAbove    = (verticalOffsetInScroll - markerAboveWinner.offsetTop);
    var verticalDistanceToBelow    = (markerBelowWinner.offsetTop - verticalOffsetInScroll);
    var verticalDistance           = (verticalDistanceToBelow + verticalDistanceToAbove);
    var verticalInfluenceFromAbove = (1 - (verticalDistanceToAbove / verticalDistance));
    var verticalInfluenceFromBelow = (1 - verticalInfluenceFromAbove);
    
    // return the calculated data as per the method contract:
    return ({
              above : {
                marker    : (markerAboveWinner         ), 
                id        : (markerAboveWinnerID       ),
                influence : (verticalInfluenceFromAbove),
              },
              below : {
                marker    : (markerBelowWinner         ),
                id        : (markerBelowWinnerID       ),
                influence : (verticalInfluenceFromBelow),
              },
           }); /*return*/
  }; /* CalculateVerticalInfluences */
  
  
  /*****************************/
  /* CalculateBackgroundColors */
  /********************************************************************************************/
  /* The two "Four Seasons" backgrounds, this experience will animate the background colors   */
  /* of both the inner and outer text boxes.  The outer text box will display the fully       */
  /* saturated, text box background color while the inner text box will use a very lightened  */
  /* version of the same color (for easier reading of the Four Seasons Sonnets text.)         */
  /*                                                                                          */
  /* @param verticalInfluences as described and calculated by (CalculateVerticalInfluences).  */
  /********************************************************************************************/
  static CalculateBackgroundColors(verticalInfluences) {
  
    // determine the two boundary colors based upon discovered above and below colors:
    var colorsAbove = [ FourSeasonsColors.saturatedRGB[verticalInfluences.above.marker.id],     // saturated color above
                        FourSeasonsColors.lightenedRGB[verticalInfluences.above.marker.id],     // lightened color above
                      ]; /* colorsAbove */
    var colorsBelow = [ FourSeasonsColors.saturatedRGB[verticalInfluences.below.marker.id],     // saturated color below
                        FourSeasonsColors.lightenedRGB[verticalInfluences.below.marker.id],     // lightened color below
                      ]; /* colorsBelow */
    
    // for an additional aesthetic, pin the saturated color to the exit button color:
    var aboveInfluence = verticalInfluences.above.influence;
    var belowInfluence = verticalInfluences.below.influence;
    if (aboveInfluence < 0.15) {
      aboveInfluence = 0;
      belowInfluence = 1;
    } /*if*/
    else {
      if (belowInfluence < 0.15) {
        aboveInfluence = 1;
        belowInfluence = 0;
      } /*if*/
    } /*else*/
    
    // determine the components of the two new mixed colors based upon the above two colors and their influences:
    var redsMixed   = [ Math.round((colorsAbove[0].R * aboveInfluence) + (colorsBelow[0].R * belowInfluence)), // saturated red
                        Math.round((colorsAbove[1].R * aboveInfluence) + (colorsBelow[1].R * belowInfluence)), // lightened red
                      ];
    var greensMixed = [ Math.round((colorsAbove[0].G * aboveInfluence) + (colorsBelow[0].G * belowInfluence)), // saturated green
                        Math.round((colorsAbove[1].G * aboveInfluence) + (colorsBelow[1].G * belowInfluence)), // lightened green
                      ];
    var bluesMixed  = [ Math.round((colorsAbove[0].B * aboveInfluence) + (colorsBelow[0].B * belowInfluence)), // saturated blue
                        Math.round((colorsAbove[1].B * aboveInfluence) + (colorsBelow[1].B * belowInfluence)), // lightened blue
                      ];
    
    // compute the RGB's
    FourSeasonsScrollStatistics.ScrollResults.BackgroundRGB.saturated = ('#' + redsMixed[0].toString(16) + greensMixed[0].toString(16) + bluesMixed[0].toString(16)); 
    FourSeasonsScrollStatistics.ScrollResults.BackgroundRGB.lightened = ('#' + redsMixed[1].toString(16) + greensMixed[1].toString(16) + bluesMixed[1].toString(16));
        
  }; /* CalculateBackgroundColors */


  /*******************/
  /* CalculateScapes */
  /********************************************************************************************/
  /* Calculate statistics for the scapes animation.  With this animation, this calculator will   */
  /* pick two of the four images:                                                                */
  /*   (1) a background image that will be displayed with full opacity to block any background   */
  /*       noise.                                                                                */
  /*   (2) a foreground image that will be displayed with partial transparency to simulate the   */
  /*       seasons changing effect.                                                              */
  /*                                                                                             */
  /* @param verticalInfluences as described and calculated by (CalculateVerticalInfluences).     */
  /********************************************************************************************/
  static CalculateScapes(verticalInfluences) {

    // translate markers to comparable ID values:
    var aboveID =verticalInfluences.above.id; //.above.id; //xItNumber.id; //[verticalInfluences.above.marker];
    var belowID =verticalInfluences.below.id; //FourSeasonsConstants.xItNumber[verticalInfluences.below.marker];
  
    // create a new object that mimics the one returned by (CalculateVerticalInfluences) but
    // with keys that make more sense for the scape animation:
    var aboveIt = FourSeasonsColors.xItNumber[aboveID];
    var belowIt = FourSeasonsColors.xItNumber[belowID];

    var scapeStats = {
          above : {
            marker   : (aboveID),
            opacity  : (verticalInfluences.above.influence),
          }, /* above */
          below : {
            marker   : (belowID),
            opacity  : (1),
          }, /* below */
        }; /* scapeStats */  

    FourSeasonsScrollStatistics.ScrollResults.Scape = scapeStats; 
  } /* CalculateScapes */
  

  /******************/
  /* CalculateStats */
  /********************************************************************************************/
  /* Calculate all stats given a single scroll position.                                         */
  /* @param scrollPixels is an object with two keys:                                             */
  /*                     top is the number of pixels from the element's top to the top of the    */
  /*                     scrolled element.                                                       */
  /*                     bottom is the number of pixels from the element's bottom to the top of  */
  /*                     the scrolled element.                                                   */
  /* @param scrollPixels is an object provided the location within the document of the top and   */
  /*                     the bottom of the scroll.  Member (top) represents the top while        */
  /*                     (bottom) represents the bottom, both values must be pixel measurements. */
  /********************************************************************************************/
  static CalculateStats(scrollPixels) {
    // initialize any values for cases when calculations could fail:
    FourSeasonsScrollStatistics.CurrentScrollPixels = scrollPixels;
    FourSeasonsScrollStatistics.Initialize();
    
    // perform all calculations:
    FourSeasonsScrollStatistics.CalculateExitStatistics();
    
    var verticalInfluence = FourSeasonsScrollStatistics.CalculateVerticalInfluences(); 
    FourSeasonsScrollStatistics.CalculateBackgroundColors(verticalInfluence);
    FourSeasonsScrollStatistics.CalculateScapes          (verticalInfluence);
  }; /* calculateStats */
  

  /**************/
  /* Initialize */
  /********************************************************************************************/
  /* Ensure that these (FourSeasonsScrollStatistics) start off at base state, carry over no old  */
  /* statistics.  This situation will occur in-between invocations of the Four Seasons dialog.   */
  /********************************************************************************************/
  static Initialize() {
    // All statistics are maintain in the ScrollResults object member of this class.
    FourSeasonsScrollStatistics.ScrollResults = {
      // exot button statistics
      CalculatedXitButton : (FourSeasonsConstants.xItNumber.xItSpring),
      
      // background color statistics
      BackgroundRGB  : {
        saturated : (FourSeasonsColors.saturatedRGB.marketTop),
        lightened : (FourSeasonsColors.lightenedRGB.marketTop),
      }, /* BackgroundRGB */
    }; /* FourSeasonsScrollStatistics */
  }; /* Initialize */
  
}; /* FourSeasonsScrollStatistics */



