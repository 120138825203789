/**********************/
/* FootnoteContentAll */
/**********************************************************************************************/
/* Generate all footnote content footnote dialogs.                                            */
/* Copyright (C) 2019 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React                        from 'react'                          ; 
import {FootnoteContentLifeOfBrian} from './FootnoteContentLifeOfBrian.js';
import {FootnoteContentAsimov     } from './FootnoteContentAsimov.js'     ;
import {FootnoteContentFourSeasons} from './FootnoteContentFourSeasons.js';


export class FootnoteContentAll extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* no setup, no logic, just render all footnote dialogs.                                    */
  /********************************************************************************************/
  render() {
    return (
      <>
        <FootnoteContentLifeOfBrian/>
        <FootnoteContentAsimov     />
        <FootnoteContentFourSeasons/>
      </>
    ); /*return*/
  }; /* render */
}; /* FootnoteContentAll */
