
/***********************/
/* inanyeventStyles.js */
/**********************************************************************************************/
/* Styles for this In Any Event... page.                                                      */
/*                                                                                            */
/* Copyright (C) 2022 Orcadia Labs LLC                                                        */    
/**********************************************************************************************/
import {inanyeventConst} from './inanyeventConst.js';

export const inanyeventStyles = {
  wholePage : {
    // layout the page
    right   : (inanyeventConst.minimumPageMargin
                     + inanyeventConst.iaeCorner.spacings.betweenBlock.horizontal
                     + inanyeventConst.iaeCorner.spacings.insideBlock.horizontal
                    ), 
    top     : (inanyeventConst.scroll.scrollHeader.height),
//    (inanyeventConst.minimumPageMargin
//                     + inanyeventConst.iaeCorner.spacings.betweenBlock.vertical
//                     + inanyeventConst.iaeCorner.spacings.insideBlock.vertical
//                    ), 
    marginBottom  : (inanyeventConst.minimumPageMargin),
    marginLeft    : (inanyeventConst.minimumPageMargin), 
    display       : 'flex',
    flexDirection : 'row',
    flexWrap      : 'wrap',
    position      : 'fixed',
    flex          : 1,

//  background:
//    backgroundImage  : ('url(https://www.orcadialabs.com/content/inanyevent/assets/images/text-bg-lightt.png)'),
//    backgroundImage  : ('./assets/images/text-bg-lightt.png)'),
//    backgroundImage  : (InAnyEventPageBackgroundImage),
//    backgroundRepeat : 'repeat',
//    backgroundColor : '#FF0000',
  }, /* wholePage */
  
  // Orcadia Labs brand image, title and related styles:
  // both (brandHeading) and (brandSpacer) should have similar styling
  // (brandHeading) is the fixed heading space with (brandSpacer) statically spaces around it.
  brandHeading : {
    position        : 'fixed',
    top             : 0,
    right           : 0,
    width           : '100%',
    display         : 'flex',
    flexDirection   : 'row-reverse',
    flexWrap        : 'wrap',
    visibility      : 'visible',
    backgroundColor : 'transparent', // solid color is #bbbbbb
    height          : (inanyeventConst.scroll.scrollHeader.height),
  }, /* brandHeading */
  brandSpacer : {
    width         : '100%',
    display       : 'flex',
    flexDirection : 'row-reverse',
    flexWrap      : 'wrap',
    visibility    : 'hidden',
  }, /* brandSpacer */
  heading : {
    width         : ( 2 * inanyeventConst.scroll.scrollHeader.height),
    height        : (inanyeventConst.scroll.scrollHeader.height),
    margin        : (inanyeventConst.brandOffset),
  }, /* heading */
  brand    : {
    width         : (inanyeventConst.brandSize  ),
    height        : (inanyeventConst.brandSize  ),
    margin        : (inanyeventConst.brandOffset),
  }, /* brand */

  // for the InAnyEvent title image:
  // this image needs spacing on the bottom to avoid runover by the iaeCorner icons.
  // place the image within a spacer box so that it aligns well with all other blocks.
  titleImageBox : {
    width          : inanyeventConst.titleImage.box.width,
    height         : inanyeventConst.titleImage.box.height,
    backgroundColor : (inanyeventConst.paperColors.threeQuarterMedianColor),         // issue #185.4
    justifyContent  : 'center',
    alignItems      : 'center',
    paddingTop      : (inanyeventConst.iaeCorner.spacings.betweenBlock.vertical  ),
    paddingRight    : (inanyeventConst.iaeCorner.spacings.betweenBlock.horizontal),
    paddingBottom   : (inanyeventConst.iaeCorner.spacings.betweenBlock.vertical  ),
    paddingLeft     : (inanyeventConst.iaeCorner.spacings.betweenBlock.horizontal),
  }, /* titleImageBox */
  
  titleImage : {
    width        : inanyeventConst.titleImage.image.width,
    height       : inanyeventConst.titleImage.image.height,
    resizeImage  : 'contain',
  }, /* titleImage */

  // In Any Event... page has 4-level block structure:
  // outerBlock - defines physical dimensions, hosts the unclipped no-waste image
  // block      - defines the dimensions and spacings
  // midBlock   - is the background coloring layer
  // foreBlock  - defines the physical dimensions of the content
  outerBlock : {
    opacity        : 1,
    width          : (inanyeventConst.blockWidth),
    overflow       : 'visible',
    paddingTop     : (inanyeventConst.iaeCorner.spacings.betweenBlock.vertical  ),
    paddingRight   : (inanyeventConst.iaeCorner.spacings.betweenBlock.horizontal),
    paddingBottom  : (inanyeventConst.iaeCorner.spacings.betweenBlock.vertical  ),
    paddingLeft    : (inanyeventConst.iaeCorner.spacings.betweenBlock.horizontal),
    position       : 'relative',
    top            : 0,
    overflow       : 'visible',
  }, /* block */
  block    : {
    opacity        : 1,
    position       : 'relative',
    top            : 0,
    overflow       : 'hidden',
    borderRadius   : 32,
  }, /* block */
  contentBlockV : {
    display        : 'flex',
    flexDirection  : 'column',
//    width          : 256,
  },
  contentBlockH : {
    display        : 'flex',
    flexDirection  : 'row',
  },
  midBlockTopBottom : {
//    width   : 256,
    height  : (inanyeventConst.iaeCorner.spacings.insideBlock.vertical  ),
    opacity : 0.4,
  }, /* midBlockTopBottom */
  midBlockLeftRight : {
    width : (inanyeventConst.iaeCorner.spacings.insideBlock.horizontal),
    opacity : 0.4,
  }, /* midBlockLeftRight */
  foreBlock : {
    width    : 300,
    maxWidth : '90%',
  }, /* midBlock */

  // Each block has a specific image to display:
  cornerImage : {
    position        : 'absolute',
    top             : (0 - inanyeventConst.iaeCorner.spacings.betweenBlock.vertical
                         - inanyeventConst.iaeCorner.spacings.insideBlock .vertical  ),
    right           : (0 - inanyeventConst.iaeCorner.spacings.betweenBlock.horizontal
                         - inanyeventConst.iaeCorner.spacings.insideBlock .horizontal),
    width           : (inanyeventConst.iaeCorner.imageDimensions.horizontal          ),
    height          : (inanyeventConst.iaeCorner.imageDimensions.vertical            ),
    zIndex          : 1,
  }, /* cornerImage */
  
  // text styles:
  text     : {
    textAlign       : 'left',
    fontWeight      : 'semibold',
    fontSize        : '1.4em',
    backgroundColor : (inanyeventConst.textColors.backgroundColor),
    padding         : 12,

  }, /* text */
  emphasis : { 
    fontSize   : '2em',
    fontWeight : 'bold',
  }, /* emphasis */
  minorEmphasis : { 
    fontWeight : 'bold',
  }, /* emphasis */
}; /* inanyeventStyles */
