/*********/
/* About */
/**********************************************************************************************/
/* Orcadia Labs about page.                                                                   */
/*                                                                                            */    
/* Copyright (C) 2020-2022 Orcadia Labs LLC                                                   */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {Text} from 'react';

// Orcadia Labs 
import {MeBox            } from '../MeBox.js'                             ;
import {InAnyEvent_Colors} from '../../constants/InAnyEvent_Colors.js'    ;
import imageMe             from '../../assets/images/UX/me0128.png'       ;
import orcadiaLabsImage    from '../../assets/images/orcadialabs_logo.png';
import '../../App.css';


/***************************/
/* OrcadiaLabs_AboutStyles */
/**********************************************************************************************/
/* All known styles.                                                                          */
/**********************************************************************************************/
let OrcadiaLabs_AboutStyles = {
  aboutPage : {                  // the about page as a whole
    display       : 'flex', 
    flexDirection : 'row' , 
    flexWrap      : 'wrap',
  }, /* aboutPage */
}; /* OrcadiaLabs_AboutStyles */


/************************/
/* OrcadiaLabsAboutPage */
/**********************************************************************************************/
/* Defines the Orcadia Labs About page.                                                       */ 
/**********************************************************************************************/
export class OrcadiaLabsAboutPage extends React.Component { 

  /**********/
  /* render */
  /********************************************************************************************/
  /* Overall vertical layout with the Orcadia Labs logo on top:                               */
  /********************************************************************************************/
  render() {
    
    return (
        <div style={OrcadiaLabs_AboutStyles.aboutPage}>

            <MeBox>
<p>
Spacing in this image is not relevant.  All spacing to be styled later to make it look cool.
</p>
<p>
What I have is a text field, an image of me and a scrollbar.  The scrollbar works to scroll the text even though they are physically separated.
</p>
<p>
Accomplish this illusion by placing an image of me (with a solid, opaque background) over the right side of the text area that occupies the complete area behnd me.  Then, place a right margin on the text, itself to ensure that it shows up only to the left of me.            
</p>
            </MeBox>
        
            <MeBox>
<p>
Spacing in this image is not relevant.  All spacing to be styled later to make it look cool.
</p>
 <img src={imageMe} style={{width:100}}/>
<p>
What I have is a text field, an image of me and a scrollbar.  The scrollbar works to scroll the text even though they are physically separated.
</p>
<img src={imageMe} style={{width:50}}/>
<p>
Accomplish this illusion by placing an image of me (with a solid, opaque background) over the right side of the text area that occupies the complete area behnd me.  Then, place a right margin on the text, itself to ensure that it shows up only to the left of me.            
</p>
<img src={imageMe} style={{width:50}}/>
            </MeBox>

            <MeBox>
              <img src={imageMe} style={{width:50}}/>
              <img src={imageMe} style={{width:100}}/>
              <img src={imageMe} style={{width:50}}/>
              <img src={imageMe} style={{width:100}}/>
              <img src={imageMe} style={{width:50}}/>
              <img src={imageMe} style={{width:100}}/>
              <img src={imageMe} style={{width:50}}/>
              <img src={imageMe} style={{width:100}}/>
              <img src={imageMe} style={{width:50}}/>
              <img src={imageMe} style={{width:100}}/>
              <img src={imageMe} style={{width:50}}/>
              <img src={imageMe} style={{width:50}}/>
            </MeBox>
        </div>
    ); /*return*/
  }; /* render */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor(props) {
    super(props);
  } /* CTOR */
  
}; /* OrcadiaLabsAboutPage */




