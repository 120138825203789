/*****************/
/* InAnyEvent.js */
/**********************************************************************************************/
/* A React component which represents the In Any Event... tag.                                */ 
/*                                                                                            */
/* An InAnyEvent tag is, generates the name of the product with a trademark symbol.           */
/*                                                                                            */
/* Copyright (C) 2018-2022 Orcadia Labs LLC                                                   */
/**********************************************************************************************/
import React from 'react';


export class InAnyEvent extends React.Component {
  /********/
  /* CTOR */
  /********/
  constructor(props) { 
    super(props); 
  } /* CTOR */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  /********************************************************************************************/
  render() {

    var InAnyEvent_Styles = {
      inAnyEvent : {                              // the whole In Any Event... component
        display           : 'flex',
        flexDirection     : 'row',
      },
      inAnyEventBlock : {                        // main block
        alignItems        : 'flex-start',
      },
      inAnyEventText : {                         // "In Any Event..."
        fontSize          : 20,
        color             : 'black',
        textAlign         : 'left',
        fontWeight        : 'bold',
      },
      inAnyEventTM        : {                    // superscript block
        alignItems        : 'flex-end',
      },
      inAnyEventSuperText : {                    // "TM"
        fontSize          : 10,
        color             : 'black',
      },
    }; /* InAnyEvent_Styles*/  

    
    return (
      <div style={InAnyEvent_Styles.inAnyEvent}>
        <div style={InAnyEvent_Styles.inAnyEventBlock}>
          <p style={InAnyEvent_Styles.inAnyEventText}>In Any Event...</p>
        </div>
        <div style={InAnyEvent_Styles.inAnyEventTM}>
          <p style={InAnyEvent_Styles.inAnyEventSuperText}>TM</p>
        </div>
      </div>
    );
  }; /* render */
  
}; /* InAnyEvent */






