/*********************/
/* ConduitManager.js */
/**********************************************************************************************/
/* Maintains and allows access to all Conduit objects.                                        */
/*                                                                                            */    
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React                      from 'react'; 
import {OrcadiaLabs_Constants}    from "../../constants/constants.js";

/****************/
/* Conduitnames */
/**********************************************************************************************/
/* Maintains a list of all supported conduits supported by this and allows access to all      */
/* Conduit objects.                                                                          */
/**********************************************************************************************/
export let ConduitNames = {
  footnoteDefaultConduitName            : 'footnoteDefaultConduit'    ,
  footnoteLifeofBrianConduitName        : 'footnoteLifeofBrianConduit',
  footnoteAsimovConduitName             : 'footnoteAsimovConduit'     ,
  footnoteFourSeasonsConduitName        : 'footnoteFourSeasonsConduit',
  footnoteFourSeasonsContentConduitName : 'footnoteFourSeasonsContent',
}; /* ConduitNames */



/*******************************/
/* ConduitManagerKnownConduits */
/**********************************************************************************************/
/* This array contains a list of all known Conduit objects, each by its name.  This single    */
/* array of known Conduits is active and maintainable by any number of ConduitManager objects.*/
/**********************************************************************************************/
let ConduitManagerKnownConduits = {};


/***********/
/* Conduit */
/**********************************************************************************************/
/* An Conduit instance can be used as a method of communication.                              */
/* One of more Listeners of a Conduit can be notified (pinged).  One or more owners of a      */
/* particular Conduit can (ping.)  Any object can be a listener of a Conduit.  Any object can */
/* be an owner of a Conduit.  Any object can be both a listener and an owner of the same      */
/* Conduit.                                                                                   */
/**********************************************************************************************/
export class Conduit {
  
  /***************/
  /* addListener */
  /********************************************************************************************/
  /* Add a listener object to this Conduit.  When an owner of this Conduit ping()s, all       */
  /* Listener objects will receive the (listener) object if they host a pinged() method.      */
  /* @param listener    is the listener to add to this Conduit.                               */
  /********************************************************************************************/
  addListener(listener) {
    if (!this.allConduitListeners) this.allConduitListeners = [];
    this.allConduitListeners.push(listener);
  }; /* addListener */
  
  
  /********/
  /* ping */
  /********************************************************************************************/
  /* Notify all listeners of this Conduit that one of its owners has pinged it.               */
  /********************************************************************************************/
  ping() {
    if (this.allConduitListeners) {
      for (var listenerNumber = 0; listenerNumber < this.allConduitListeners.length; ++listenerNumber) {
        var listener = this.allConduitListeners[listenerNumber];
        if ((listener) && (listener.pinged)) {
          listener.pinged();
        } /*if*/
      } /*for*/
    } /*if*/
  }; /* ping */

  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Create and initialize this Conduit with no listeners.                                    */
  /********************************************************************************************/
  constructor() {
    this.allConduitListeners = [];
  }; /* CTOR */
  
}; /* Conduit */


/******************/
/* ConduitManager */
/**********************************************************************************************/
/* Each ConduitManager provides access to, and control of, any number of Conduit objects.     */
/**********************************************************************************************/
export class ConduitManager {

  /**************/
  /* getConduit */
  /********************************************************************************************/
  /* Obtain access to the Consuit of the given name.                                          */
  /* If a Conduit of the given names does not yet exist, this method will create it (with the */
  /* given name) and return it.  This method can be also used to just create, but not use, a  */
  /* Conduit just by not using the retuned value.                                             */
  /*                                                                                          */
  /* @param conduitName is the name of the conduit to return.  Every name should be listed in */
  /*                    the ConduitNames object but need not be.  As such, this method        */
  /*                    supports customized implementation of this Conduit suppoert.          */
  /* @return the named conduit.  If a Conduit by this namwe does not yet exist, this method   */
  /*         will create it.  This method will return a Conduit object in all circumstances.  */
  /********************************************************************************************/
  static getConduit(conduitName)  {
    // obtain access to the known conduit by (conduitName) if it exists.
    var conduit = ConduitManagerKnownConduits[conduitName];  
    
    // if it does not yet exist, create it
    if (!conduit) {
      conduit = new Conduit(conduitName);
      ConduitManagerKnownConduits[conduitName] = conduit;
    } /*if*/
    
    // return the known, non-null conduit of the given (conduitName)
    return conduit;
  }; /* getConduit */

}; /* ConduitManager */




