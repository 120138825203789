/***************/
/* Footnote.js */
/**********************************************************************************************/
/* Orcadia Labs Library Component Footnote for displaying additional information in a         */
/* non-traditional fashion.                                                                   */
/*                                                                                            */
/* Supported properties:                                                                      */
/* @prop text  is the text to display in the footnote.                                        */
/* @prop href  is the URL of a page to display in the footnote.                               */
/*                                                                                            */
/* Copyright (C) 2019 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React                   from 'react'                                           ; 
import {OrcadiaLabs_Constants} from '../../constants/constants.js'                    ;
import {ConduitManager, 
        Conduit              } from '../conduit/ConduitManager.js'                    ;
import {FootnoteConstants    } from './FootnoteConstants.js'                          ;
import hawkOrcadiaLabs         from '../../assets/images/UX/hawk-orcadialabs.png'     ;
import hawkOrcadiaLabsBack     from '../../assets/images/UX/hawk-orcadialabs-back.png';
import exitButton              from '../../assets/images/xit.png'                     ;


/***************************/
/* FootnoteVisibilityState */
/**********************************************************************************************/
/* A Orcadia Labs footnote dialog element has one of three visibility states:                 */
/*                                                                                            */
/*   h: hidden  - the footnote is not displayed anywhere                                      */
/*   v: visible - the footnote is displayed but not on top of stack                           */
/*   f: focused - the footnote is displayed and on top of stack.                              */
/*                                                                                            */
/* The actual, literal value for each state is not relevant nor interesting.  The important   */
/* feature is that each value is different from all of the others.                            */
/*                                                                                            */
/* A footnote dialog supports the following transitions:                                      */
/*   'h' -> 'f'  dialog is displayed for first time to user.                                  */
/*   'v' -> 'f'  dialog is placed on top of stack, user can sees it.                          */
/*   'v' -> 'h'  if dialog is not on top of stack, part of it may                             */
/*               be still howing, so unstack and undisplay it.                                */
/*   'f' -> 'h'  dialog is undisplayed and not within stack.                                  */
/*   'f' -> 'v'  dialog is moved down on stack, covered by another.                           */
/*                                                                                            */
/* A footnote dialog does NOT support the following transitions:                              */
/*   'h' -> 'v'  the user may see part of the dialog not covered by the union of all others,  */
/*               yet this transition seems bad since, when requested to be shown, the user    */
/*               will still not see (all of) it.                                              */
/*                                                                                            */
/* Every transition from a state to the same state is a no-op.                                */
/**********************************************************************************************/
const FootnoteVisibilityState = {
  hidden   : 'h',  // hidden  - defined above
  visible  : 'v',  // visible - defined above
  focused  : 'f',  // focused - defined above
}; /* FootnoteVisibilityState */


/******************/
/* FootnoteDialog */
/**********************************************************************************************/
/* The pop-up dialog window associated with a Footnote.                                       */
/**********************************************************************************************/
export class FootnoteDialog extends React.Component {


  /********************************/
  /* foremostFootnoteDialogZIndex */
  /********************************************************************************************/
  /* A value guaranteed to the foremost z-index value of all FooteDialog objects.             */
  /********************************************************************************************/
  static foremostFootnoteDialogZIndex = 3000;
  

  /**************************************/
  /* getNewForemostFootnoteDialogZIndex */
  /********************************************************************************************/
  /* A value guaranteed to the foremost z-index value of all FootnoteDialog objects.          */
  /********************************************************************************************/
  static getNewForemostFootnoteDialogZIndex = () => {
    FootnoteDialog.foremostFootnoteDialogZIndex += 100;
    return (FootnoteDialog.foremostFootnoteDialogZIndex);
  }; /* getNewForemostFootnoteDialogZIndex */
  

  /**********/
  /* getSrc */
  /********************************************************************************************/
  /* @return the URL to display in the footnote, null or empty.                               */
  /********************************************************************************************/
  getSrc() {
    return this.footnoteSource;
  }; /* getHref */
  
  
  /***********/
  /* isShown */
  /********************************************************************************************/
  /* Shortcut method which returns whether this dialog is shown.                            . */
  /* The only dialog visibility that is shown is 'focued'.  The other two states describe     */
  /* content that is either fully or partially shown.                                         */
  /* @return is focused                                                                       */
  /********************************************************************************************/
  isShown() {
    return (FootnoteVisibilityState.focused == this.getVisibility());
  }; /* getVisibility */

  
  /*****************/
  /* getVisibility */
  /********************************************************************************************/
  /* @return the current visiblity state of this footnote dialog.                             */
  /*         Supported visibility state values are described above.                           */
  /********************************************************************************************/
  getVisibility() {
    return (this.visibility);
  }; /* getVisibility */

  
  /*****************/
  /* setVisibility */
  /********************************************************************************************/
  /* Set the visibility state of this footnote dialog to a new state.                         */
  /* @parm visibility is the new visibility, default hidden.                                  */
  /********************************************************************************************/
  setVisibility(visibility) {
    this.visibility = FootnoteVisibilityState.hidden;
    
    if (arguments.length > 0) {
      if ((FootnoteVisibilityState.hidden  == visibility) ||
          (FootnoteVisibilityState.visible == visibility) ||
          (FootnoteVisibilityState.focused == visibility)) {
        this.visibility = visibility;
      } /*if*/
    } /*if*/
  }; /* setVisibility */

  
  /********/
  /* show */
  /********************************************************************************************/
  /* Transition this foot dialog from its current visibility state to the 'focused' state.    */
  /* If the dialog is 'focused,' just rerender it in place.                                   */
  /* Otherwise, handle the state transition.                                                  */
  /********************************************************************************************/
  show() {
    switch(this.getVisibility()) {
      case FootnoteVisibilityState.focused :
        // just redraw the dialog, it is already focused 
        this.forceRerender(false);
        break;
      case FootnoteVisibilityState.visible :
        // showing a visible dialog just moves it to the top, don't animate it:
        this.setVisibility(FootnoteVisibilityState.focused);
        this.forceRerender();
        break;
      case FootnoteVisibilityState.hidden  :
        // dialog is currently visible or hidden, make it focused.
        // redraw and animate.
        this.setVisibility(FootnoteVisibilityState.focused);
        this.forceRerender(true);
        break;
      default : // no pathway to here, coded for clarity
    } /*switch*/
  }; /* show */
  
  
  /********/
  /* hide */
  /********************************************************************************************/
  /* Hide the Footnote dialog.  Hide the given dialog.                                        */
  /* If it is already hidden, do nothing.                                                     */
  /********************************************************************************************/
  hide() {
    switch(this.getVisibility()) {
      case FootnoteVisibilityState.focused :
      case FootnoteVisibilityState.visible :
        this.setVisibility(FootnoteVisibilityState.hidden);
        this.forceRerender(false /* just undraw it, don't animate it */);
        break;
      case FootnoteVisibilityState.hidden  :
        // do nothing, this dialog is already hidden
        break;
      default : //* no pathway to here, coded for clarity
    } /*switch*/
  }; /* hide */
  
  
  /**********/
  /* toggle */
  /********************************************************************************************/
  /* Hide footnote dialog if focused or visible, focus footnote if it is currently hidden.    */
  /********************************************************************************************/
  toggle() {
    switch(this.getVisibility()) {
      case FootnoteVisibilityState.hidden  :
      case FootnoteVisibilityState.visible :
        this.show();
        break;
      case FootnoteVisibilityState.focused :
        this.hide();
        break;
      default : //* no pathway to here, coded for clarity
    } /*switch*/
  }; /* hide */
  
  
  /**********/
  /* pinged */
  /********************************************************************************************/
  /* Accepts a ping from the footnote conduit.                                                */
  /* This Footnote dialog maintains only one reaction to being pinged, to toggle itself.      */
  /********************************************************************************************/
  pinged() {
    // toggle the dialog, set the zIndex to the top and see it go:
    this.toggle       ();
  }; /* pinged */
  
  
  /*****************/
  /* forceRerender */
  /********************************************************************************************/
  /* Rerender this dialog.                                                                    */
  /* @param reAnimate will be true if the dialog is to be re-animated to the focused position.*/
  /*                  Otherwie, the parameter will be false if to redraw the dialog in-place, */
  /*                  no animation, no change in (z)                                          */
  /********************************************************************************************/
  forceRerender(reAnimate) {
    // by default, do not reanimate, just rerender in place:
    var x = 0;
    var z = this.state.z;
    
    // if a reanimation is requested, set up and (x) and (z):
    if (reAnimate) {
      x = -1000;
      z = FootnoteDialog.getNewForemostFootnoteDialogZIndex();
    } /*if*/

    //  redraw this dialog, either in place or (re)animated:
    this.setState({ x : (x), z : (z)});
  }; /* forceRerender */
  
  
  /************************/
  /* forceRerenderInPlace */
  /********************************************************************************************/
  /* Rerender this dialog in place, just a change in z-index.                                 */
  /********************************************************************************************/
  forceRerenderInPlace() {
    this.setState({ x : (0), z : (FootnoteDialog.getNewForemostFootnoteDialogZIndex())});
  }; /* forceRerender */
  
  
  /*********************/
  /* renderSingleFrame */
  /********************************************************************************************/
  /* Render a single frame of the footnote dialog animation.                                  */
  /* @param x        is the horizontal offset as specific as (%).                             */
  /* @param z        is the z-index for this dialog this time.                                */
  /* @param children are the children of this particular dialog.                              */
  /********************************************************************************************/
  renderSingleFrame(x, z, children) {
  
    // top space for the top border line:
    var topMarginBorder = 2;
    
    
    // normal, plain dialog styles:
    var dialogStyles = {
          // the whole, animated dialog object:
          dialog  : {                        /* the fully dialog window */
            position               : 'fixed',
            top                    : (0 - (2 * x)),
            left                   : ('' + x + '%'), 
            width                  : '100%',
            zIndex                 : (z),
            display                : 'flex',
            flexDirection          : 'row',
            flexWrap               : 'wrap',
            borderBottomWidth      : 16,     // pixels high of simulated border
            backgroundColor        : 'transparent',
          }, /* dialog */
          
          dialogOuter : {
            backgroundColor        : (OrcadiaLabs_Constants.colors.teals.OrcadiaLabs_DarkestTeal),
            borderTopLeftRadius    : 28,
            borderBottomLeftRadius : 28,
            marginTop              : 0,
            maxWidth               : '100%',
            overflowY              : 'scroll',
            maxHeight              : '100vh',
          }, /* dialogOuter */
          
          // inside the outer dialog, the inner dialog simulates a bottom border: 
          dialogInner : {
            marginTop              :  (FootnoteConstants.topMarginBorder),  // <---
            marginRight            :  0,
            marginBottom           :  4,
            marginLeft             :  0,
            borderBottomLeftRadius : 28,
            backgroundColor        : 'white',
            width                  : '100%', 
 textAlign : 'left',
          },
          
          // space for the Orcadia Labs brand image:
          brandContainer : {                 /* provide styles 'around' the (brand) */
            marginBottom : (0),
            marginTop    : (0 - FootnoteConstants.topMarginBorder),
            marginRight  : 8,  // <---
          }, /* brandContainer */
          brand   : {                        /* embedded brand image    */
            width     : (OrcadiaLabs_Constants.SPA.brand.standardWidth ),
            height    : (OrcadiaLabs_Constants.SPA.brand.standardHeight),
            maxWidth  : '100%'                                          ,
            marginTop : 0,
          }, /* brand */
          brandBack   : {                        /* embedded brand image    */
            position        : 'absolute',
            left            : 0,
            top             : 0,
            width           : (OrcadiaLabs_Constants.SPA.brand.standardWidth ),
            height          : (OrcadiaLabs_Constants.SPA.brand.standardHeight),
            maxWidth        : '100%'                                          ,
            backgroundColor : 'transparent',
            opacity         : 1,
          }, /* brand */
    }; /* dialogStyles */
    
    
    // A hidden Footnoe Dialog cannot be seen:
    if (!this.isShown()) return (<div></div>);
    
    if (this.props.footnoteTopSpace) {
      dialogStyles.dialogOuter.marginTop       = FootnoteConstants.optionalTopOffset;
      dialogStyles.brand.marginTop             = (0 - FootnoteConstants.optionalTopOffset);
      dialogStyles.brandBack.top               = (0 - FootnoteConstants.optionalTopOffset);
      dialogStyles.brandBack.opacity           = (0.5);
      dialogStyles.brandContainer.marginBottom = (8 + FootnoteConstants.optionalTopOffset);
    } /*if*/
    
    return (
      <div style = {dialogStyles.dialog}>
        <div style = {dialogStyles.dialogOuter}>
          <div style = {dialogStyles.dialogInner}>
            <div style={dialogStyles.brandContainer}>
              <img id='orcadialabs-brand' style={dialogStyles.brand    } src={hawkOrcadiaLabs    } alt="OrcadiaLabs.com Brand" />
            </div>
            {children}
          </div>
        </div>
      </div>
    ); /*return*/
  }; /* renderSingleFrame */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  render() {
    // A hidden Footnoe Dialog cannot be seen:
    if (FootnoteVisibilityState.hidden == this.getVisibility()) return (<div></div>);
    
    // finally, calculate the offset so that we 'slide' from the right:
    // start at 100% and work leftwards.
    // during countdown, reschedule next animation frame.
    var thisX = 0;
    var thisZ = 0;
    if ((!this.state) || (this.state.x < 0)) {
      thisX = 100;
    } /*if*/
    else             {
      thisX = this.state.x - 10;
      if (0 > thisX) thisX = 0;
    } /*else*/
    
    // copy forward this, particular zIndex
    thisZ = this.state.z;
      
    if (0 < thisX) setTimeout(() => this.setState({x : (thisX),z:(thisZ)}), 50);
    
    return (
      <div>
        {this.renderSingleFrame(thisX, thisZ, this.props.children)}
      </div>
    ); /*return*/
  }; /* render */
  
  
  /********/ 
  /* CTOR */
  /********************************************************************************************/
  /* Construct this footnote dialog.  Support several options.                                */
  /* This component supports one or more of these properties simultaneously.                  */
  /* This constructor will process the declaration of any of the above supported properties.  */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    
    // set the renderable state for this (FootnoteDialog).
    // set the z-index to make it foremost.
    this.state = {x:-1,z:(FootnoteDialog.getNewForemostFootnoteDialogZIndex())};
    this.setVisibility();
    
    // This FootnoteDialog will listen to pings:
    var conduitName = props[OrcadiaLabs_Constants.footnote.footnoteConduitProp];
    var dialogConduit = ConduitManager.getConduit(conduitName);
    dialogConduit.addListener(this);
  }; /* CTOR */
  
}; /* FootnoteDialog */


/************/
/* Footnote */
/**********************************************************************************************/
/* Footnote Component                                                                         */
/**********************************************************************************************/
export class Footnote extends React.Component {

  /******************/
  /* setConduitName */
  /********************************************************************************************/
  /* Remember the name of the communication conduit between this Footnote component and its   */
  /* associated FootnodeDialog component.                                                     */
  /* @param conduitName is a string that holds this name.                                     */
  /********************************************************************************************/
  setConduitName(conduitName) {
    this.conduitName = conduitName;
  };


  /***************/
  /* pingConduit */
  /********************************************************************************************/
  /* Ping the footnote dialog component associated with this Footnote                         */
  /********************************************************************************************/
  ping() {
    ConduitManager.getConduit(this.conduitName).ping(); 
  };


  /*******************/
  /* clickedFootnote */
  /********************************************************************************************/
  /* A footnote has been clicked, toggle the footnote dialog.                                 */
  /********************************************************************************************/
  clickedFootnote() { 
    this.ping(); 
  }; /* clickedFootnote */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  /* footnote content.                                                                        */
  /********************************************************************************************/
  render() {
    // Generate the clickable portion of the footnote:
    return (
      <div style={{display:'inline-block'}}>
        <div onClick = {() => {this.clickedFootnote()}} >
          {this.props.children}
        </div>
      </div>
    ); 
  }; /* render */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Construct this footnote.  Support several options.                                       */
  /* This component supports one or more of these properties simultaneously.                  */
  /* This constructor will process the declaration of any of the above supported properties.  */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.state = {count:0};  
    this.setConduitName(this.props[OrcadiaLabs_Constants.footnote.footnoteConduitProp]);
  }; /* CTOR */
  
  
}; /* Footnote */

