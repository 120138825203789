/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* Landscape support.                                                                         */ 
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC, all rights reserved.                           */
/**********************************************************************************************/
import React,
       {Text,
        TouchableWithoutFeedback}        from 'react'                                   ; 
import {FourSeasonsConstants}            from './FourSeasonsConst.js'                   ;
import {FourSeasonsScrollStatistics,
        FourSeasons_SetScrollMarker,
        FourSeasons_GetScrollMarker}     from './FourSeasonsStats.js'                   ;
import ImageScapeSpring                  from './assets/images/s4_scape1.png'           ;
import ImageScapeSummer                  from './assets/images/s4_scape2.png'           ;
import ImageScapeAutumn                  from './assets/images/s4_scape3.png'           ;
import ImageScapeWinter                  from './assets/images/s4_scape4.png'           ;



/********************/
/* FourSeasonsScape */
/**********************************************************************************************/
/* Four Seasons Sonnets Landscape image                                                          */
/* The "Four Seasons Sonnets" landscape is an image representing the season.                     */
/* @prop -- id identifies which () exit button to generate.                                      */
/**********************************************************************************************/
export class FourSeasonsScape extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw just the full-sized block background with color.                                    */
  /********************************************************************************************/
  render() { 
    var scapeStyles = {
          position        : 'absolute',
          visibility      : 'visible',
          opacity         : 0.1,
          width           : (FourSeasonsConstants.scape.scaledImage.width ),
          height          : (FourSeasonsConstants.scape.scaledImage.height),
          bottom          : 0,
          left            : 0,
    }; /* xItButtonStyles */

    // Display the indicated scape
    // Scapes will be displayed with calculated values of opacity.
    var whichScape = this.props.id;
    
    // The above and below images to display have been already calculated.
    // along with the two images the data includes their calculated opacity.
    // With this information, make the necessary changes:
    var aboveScape = FourSeasonsScrollStatistics.GetScapeAboveInformation();
    var belowScape = FourSeasonsScrollStatistics.GetScapeBelowInformation();
    if (whichScape == aboveScape.marker) {
      scapeStyles.opacity = aboveScape.opacity;
    } /*if*/
    if (whichScape == belowScape.marker) {
      scapeStyles.opacity = belowScape.opacity;
    } /*if*/
    
    // select which landscape image to build: 
    var scapeImage = undefined;
    
    switch (whichScape) {
      case FourSeasonsConstants.xItNumber.xItSpring :
      case FourSeasonsConstants.xItNumber.xItNone   :
             scapeImage = ImageScapeSpring;
             break;
      case FourSeasonsConstants.xItNumber.xItSummer :
             scapeImage = ImageScapeSummer;
             break;
      case FourSeasonsConstants.xItNumber.xItAutumn :
             scapeImage = ImageScapeAutumn;
             break;
      case FourSeasonsConstants.xItNumber.xItWinter :
             scapeImage = ImageScapeWinter;
             break;
      default : // all expected use cases are defined above.
    }; /*switch*/
    
    return (
      <img id  = {whichScape} style={scapeStyles} 
           src = {scapeImage} />
    ); /*return*/
  }; /* render */
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* FourSeasonsScape */
