
/*******************/
/* In Any Event... */
/**********************************************************************************************/
/* Orcadia Labs In Any Event... page.                                                         */
/*                                                                                            */    
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */    
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {Text} from 'react';

import {InAnyEvent_Colors} from '../../constants/InAnyEvent_Colors.js';
import {inanyeventConst  } from './inanyeventConst.js'                ;
import {inanyeventStyles } from './inanyeventStyles.js'               ;
import {IAEaBlock        } from './IAEaBlock.js'                      ;


// Orcadia Labs 
import '../../App.css';
import IAEScrollHeader        from './assets/images/scrollheader.png'                ;  
import InAnyEventTitle        from './assets/images/inanyevent.png'                  ;
import OrcadiaLabsBrandImage  from '../../assets/images/UX/logo/text-bg-mediumt4.png';
import CtAInAnyEvent          from '../home/assets/images/CtA-IAE.png'               ;
import CtAInAnyEventSlideshow from '../home/assets/images/iae-director.png'          ;


const inanyeventProperties = {
  brand    : {
    src :  OrcadiaLabsBrandImage,
  }, /* brand */
  heading  : {
    src :  IAEScrollHeader,
  }, /* brand */
  title : {
    src : InAnyEventTitle,
  },
}; /* inanyeventProperties */


/**************/
/* InAnyEvent */
/**********************************************************************************************/
/* InAnyEvent is a React component that displays the name "In Any Event..." in a consistent   */
/* manner.                                                                                    */
/**********************************************************************************************/
class InAnyEvent extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Render "In Any Event..."                                                                 */
  /********************************************************************************************/
  render() {
    return(
      <span style={inanyeventStyles.minorEmphasis}>In Any Event...</span>
    ); /*return*/
  }; /* render*/
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* InAnyEvent */


/*******************************/
/* OrcadiaLabsInAnyEventHeader */
/**********************************************************************************************/
/* Renders the header for the In Any Event Page.                                              */
/* The reason that this task is its own component is that this page needs to render its       */
/* header twice:                                                                              */
/* (1) A visible form which always resides at the top of the page.                            */
/* (2) An invisible form which resides at the top of the scroll content to ensure that the    */
/*     scroll does not cover the header when viewed from above.                               */
/*                                                                                            */
/* This component knows how to render the header.  It accepts one prop, whether to render     */
/* the header visible.                                                                        */
/**********************************************************************************************/
class OrcadiaLabsInAnyEventHeader extends React.Component {


  /********************************************/
  /* OrcadiaLabsInAnyEventHeaderIsVisibleProp */
  /********************************************************************************************/
  /* Property used to indicate whether to return a visible or invisible header.               */
  /********************************************************************************************/
  static OrcadiaLabsInAnyEventHeaderIsVisibleProp = 'OrcadiaLabsInAnyEventHeaderIsVisibleProp';
  
  
  /*********************/
  /* markHeaderVisible */
  /* isHeaderVisible   */
  /********************************************************************************************/
  /* Register whether to render the header visible.                                           */
  /* @parm headerVisible (true | false) whether to render the header visible, default false.  */
  /********************************************************************************************/
  markHeaderVisible(headerVisible = false) {
    this.headerVisible = headerVisible;
  }; /* markHeaderVisible */

  isHeaderVisible() {
    return (this.headerVisible);
  }; /* isHeaderVisible */
  

  /**********/
  /* render */
  /********************************************************************************************/
  /* Render the In Any Event header.  This method will return a header.                       */
  /* It will be marked visible or invisible but it will be a header.                          */
  /********************************************************************************************/
  render() {
  
    // constant declaration of the header container:
    // assume default value of true (is visible)
    var headerStyles = {
          height        : (inanyeventConst.scroll.scrollHeader.height),
          display       : 'flex', 
          flexDirection : 'row-reverse', 
          flex          : 1, 
          flexWrap      : 'wrap',
          visibility    : 'visible',
        }; /* headerStyles */
        
    // if the header should be invisible, mark it
    if (!this.isHeaderVisible()) {
      headerStyles.visibility = 'hidden';
    } /*if*/
    
    // generate the header:
    return (    
      <div style={headerStyles}>
        <img {...inanyeventProperties.brand  } 
             style={{width:inanyeventConst.scroll.scrollBrandImage.imageWidth, 
                     height:inanyeventConst.scroll.scrollBrandImage.imageHeight, 
                     marginLeft :50, marginRight:50, zIndex:34444}} />
        <img {...inanyeventProperties.heading} 
             style={{height:inanyeventConst.scroll.scrollHeaderImage.imageHeight, 
                   width:inanyeventConst.scroll.scrollHeaderImage.imageWidth}} />
        </div>
    ); /*return*/
  }; /* render */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    
    // record visibility:
    this.markHeaderVisible(props[OrcadiaLabsInAnyEventHeader.OrcadiaLabsInAnyEventHeaderIsVisibleProp]);
  }; /* CTOR */
  
};  /* OrcadiaLabsInAnyEventHeader */


/*****************************/
/* OrcadiaLabsInAnyEventPage */
/**********************************************************************************************/
/* Defines the Orcadia Labs In Any Event... contact page.                                     */ 
/*                                                                                            */
/* The "no waste" icons lay out in the blocks like this:                                      */
/*         --ww-w-ww--                                                                        */
/*   The icon lays-out similarly both vertically and horizontally.                            */
/*   W is an equally-sized portion of the icon.                                               */
/*   Therefore, there is twice the space inside a block (between edge of block and text of    */
/*   block) than between blocks.                                                              */
/**********************************************************************************************/
export class OrcadiaLabsInAnyEventPage extends React.Component { 
  
  /**********/
  /* render */
  /********************************************************************************************/
  /********************************************************************************************/
  render() {

    var headerVisibility = {
      visibleHeader : {
        [OrcadiaLabsInAnyEventHeader.OrcadiaLabsInAnyEventHeaderIsVisibleProp] : true,
      }, /* visibleHeader */
      invisibleHeader : {
        [OrcadiaLabsInAnyEventHeader.OrcadiaLabsInAnyEventHeaderIsVisibleProp] : false,
      }, /* invisibleHeader */
    }; /* visibleHeader */
    
    return (
      <>
      <div className='defaultPage' style={{zIndex:-4374}}/>
      
      <div style={{display:'flex', flexDirection:'column', flex:1}}>
        <OrcadiaLabsInAnyEventHeader {...headerVisibility.invisibleHeader}/>
            
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', 
                     marginTop:100, paddingRight:70, paddingLeft:40}}>
          <div style = {inanyeventStyles.titleImageBox}>
            <img style={inanyeventStyles.titleImage} src = {CtAInAnyEvent}/>
          </div>
          
          <a href="https://docs.google.com/presentation/d/e/2PACX-1vS_ZIulPYbcHfV0X5vU4nCGLg6AkH_KTPN2DQMxluUA3sGROWzHBH8wFA__AV8fplx_qRXyOmpwdQml/pub?start=false&loop=false&delayms=10000">
            <div style = {inanyeventStyles.titleImageBox}>
              <img style={inanyeventStyles.titleImage} src = {CtAInAnyEventSlideshow}/>
            </div>
          </a>

          <IAEaBlock>
                             For a person at an event, 
                             <br/><InAnyEvent/> provides 
                             the services they need and offers the information they want
                             directly on their phone.
          </IAEaBlock>
  
          <IAEaBlock block={'07'}>
                             While other products also can work at an event,
                             what sets <InAnyEvent/> apart is how it works,
                             with uniquely attractive and inspiring designs.
          </IAEaBlock>
                           
          <IAEaBlock block={'11b'}>
                             Event designs can be exciting, complex, artistic, powerful and meaningful.
                             <InAnyEvent/> supports every design.
          </IAEaBlock>
                           
          <IAEaBlock block={'11c'}>
                             <InAnyEvent/> helps a person at an event.  It helps them enjoy it.  
                             Its event designs help them feel it.
          </IAEaBlock>
        
          <IAEaBlock block={'11a'}>
                             <InAnyEvent/> designs provide attendees immersive event experiences 
                             that help events reach or exceed their goals.
          </IAEaBlock>
                           
          <IAEaBlock block={'12'}>
                             <InAnyEvent/> designs support events of all sizes, 
                             also for the small and not for just the very large.  
                             It supports every event, reunions, art shows, weddings, school trips and more.  
          </IAEaBlock>
                           
          <IAEaBlock block={'07b'}>
                             <InAnyEvent/> is for the rest of us. <br/><InAnyEvent/> is for all of us.
          </IAEaBlock>

        </div> 
        
        <div style = {inanyeventStyles.titleImageBox}>
          <img style={inanyeventStyles.titleImage} src = {InAnyEventTitle}/>
        </div>
        
      </div>
      
      <div style={{position:'fixed', top:0, width:'100%', zIndex:1000}}>
        <OrcadiaLabsInAnyEventHeader {...headerVisibility.visibleHeader}/>
      </div>
      
      </>
    ); /*return*/
  } /* render */
    
  
  constructor(props) {
    super(props);
  } /* CTOR */
  
  
} /* OrcadiaLabsInAnyEventPage */


// For reference, here is the previous text.
// To me, it sounds a little too preachy:
//
//    Events
//    A great design can be key to a successful event.  
//    When they inspire patrons,  promote sales, and motivate donors, 
//    they can help attendees better enjoy their time, improving results.
//        
//   In Any Event... offers an attendee the services they need, the information they want 
//   and at a very personal level, their phones.
//        
//   In Any Event... uniquely provides these services 
//   through the very event design right on a phone.  
//   In Any Event... can support any design, without limit.  
//   Through this fully immersive experience, In Any Event... helps to greatly enhance event results.
//        
//   In Any Event... provides these services and experience for events of any size.  
//   People at even medium-sized to smaller events can enjoy it.  
//   Reunions, hobby expos, art shows, weddings, school trips and more.
//        
//   Event Directors can use In Any Event... 
//   to offer all services and information to every attendee 
//   before during and after an event.
//        
//    Event Designers can know that In Any Event... 
//    will authentically present their design to the attendee on a personal level and 
//    that this uniquely and fully immersive experience will help the event reach its goals and improve results.


