/*************/
/* Portfolio */
/**********************************************************************************************/
/* Orcadia Labs portfolio page.                                                               */
/*                                                                                            */    
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {Text} from 'react';

// Orcadia Labs 
import {OrcadiaLabs_Constants  } from '../../constants/constants.js'                      ;
import {MeBox                  } from '../MeBox.js'                                       ;
import {InAnyEvent_Colors      } from '../../constants/InAnyEvent_Colors.js'              ;
import {Footnote               } from '../../library/footnote/Footnote.js'                ; 
import {ConduitManager         ,
        Conduit                ,
        ConduitNames           } from '../../library/conduit/ConduitManager.js'           ;
import ImageAsimov               from './assets/images/asimov20button.png'                ; 
import ImageLifeOfBrian          from './assets/images/life20button.png'                  ; 
import ImageFourSeasons          from '../fourSeasons/assets/images/s4_title20cbutton.png'; 
import imageMe                   from '../../assets/images/UX/me0128.png'                 ;
import orcadiaLabsImage          from '../../assets/images/orcadialabs_logo.png'          ;
import '../../App.css';
// finally, the Motivations Animations are moving to the foreground:
import {OrcadiaLabsMotivations}    from '../motivations/OrcadiaLabsMotivations.js';           


/****************************/
/* OrcadiaLabsPortfolioInfo */
/**********************************************************************************************/
/* Information for rendering the Orcadia Labs portfolio page.                                 */
/**********************************************************************************************/

let OrcadiaLabsPortfolioInfo = {
  // styles for each of  buttons:
  buttonContainer     : {
    position        : 'relative',
    padding         : 50,
    display         : 'flex',
    flexDirection   : 'row',
    flexWrap        : 'wrap',
    justifyContents : 'space-between',
  }, /* buttonContainer */
  
  button              : {
    position : 'relative',
    marginLeft        : -15,
    marginRight       : -15,
    marginTop         : -24,
    marginBottom      : -24,
    overflow          : 'visible',
    display           : 'inline-block',
  }, /* button */
  
  // Images:
  AllImageStyles  : {
    width            : 256,  
    maxWidth         : '100%', 
    marginTop        : 8,
    
    // I don't know yet why I need to establish a z-index,
    // even though it has the default value.
    zIndex           : 0,
  },
  
 //  The Issac Asimov image 
  AsimovImage     : {
    src   : (ImageAsimov),
    alt   : ("Isaac Asimov"),
    width : '100%',
  }, /* AsimovImage */
  AsimovFootnote : {
    footnoteConduit : ConduitNames.footnoteAsimovConduitName,
  }, /* AsimovFootnote */
  
  //  The Life of Brian image 
  LifeOfBrianImage   : {
    src   : (ImageLifeOfBrian),
    alt   : ("Life of Brian (c) 2019 Python (Monty) Pictures Ltd"),
    width : '100%',
  }, /* LifeOfBrianImage */  
  LifeOfBrianFootnote : {
    footnoteConduit : ConduitNames.footnoteLifeofBrianConduitName,
  }, /* LifeOfBrianFootnote */
  
  //  The FourSeasons image 
  FourSeasonsImage     : {
    src   : (ImageFourSeasons),
    alt   : ("Four Seasons Sonnets"),
    width : '100%',
  }, /* FourSeasonsImage */
  FourSeasonsFootnote : {
    footnoteConduit : ConduitNames.footnoteFourSeasonsConduitName,
  }, /* LifeOfBrianFootnote */
   
};  /* OrcadiaLabsPortfolioInfo */


 /*********************/
/* LifeOfBrianButton */
/**********************************************************************************************/
/* Code required for Life of Brian                                                            */
/* LifeOfBrian owns its own Footnote object.                                                  */
/**********************************************************************************************/
class LifeOfBrianButton extends React.Component {

  render() {
    /* ensure that the LifeOfBrian button owns a Footnote object:     */
    return (
      <div style={OrcadiaLabsPortfolioInfo.AllImageStyles}>
        <Footnote {...OrcadiaLabsPortfolioInfo.LifeOfBrianFootnote}>
          <img {...OrcadiaLabsPortfolioInfo.LifeOfBrianImage} style = {OrcadiaLabsPortfolioInfo.AllImageStyles}/>
        </Footnote>
      </div>
    ); /*return*/
  }; /* render */
  
  constructor(props) {
    super(props);
  }; /* CTOR */
};  /* LifeOfBrianButton */


/****************/
/* AsimovButton */
/**********************************************************************************************/
/* Code required for Isaac Asimov                                                             */
/* Asimov owns its own Footnote object.                                                       */
/**********************************************************************************************/
class AsimovButton extends React.Component {

  render() {
    // ensure that the AsimovButton owns a Footnote object: 
    return (
      <div style={OrcadiaLabsPortfolioInfo.AllImageStyles}>
        <Footnote {...OrcadiaLabsPortfolioInfo.AsimovFootnote}>
          <img {...OrcadiaLabsPortfolioInfo.AsimovImage} style = {OrcadiaLabsPortfolioInfo.AllImageStyles}/>
        </Footnote>
      </div>
    ); /*return*/
  }; /* render */
  
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* AsimovButton */  


/*********************/
/* FourSeasonsButton */
/**********************************************************************************************/
/* Code required for the Four Seasons Sonnets experience.                                     */ 
/* Four Seasons owns its own Footnote object.                                                 */
/**********************************************************************************************/
class FourSeasonsButton extends React.Component {

  render() {
    // ensure that the AsimovButton owns a Footnote object: 
    return (
      <div style={OrcadiaLabsPortfolioInfo.AllImageStyles}>
        <Footnote {...OrcadiaLabsPortfolioInfo.FourSeasonsFootnote}>
          <img {...OrcadiaLabsPortfolioInfo.FourSeasonsImage}/>
        </Footnote>
      </div>
    ); /*return*/
  }; /* render */
  
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* FourSeasonsButton */  


/*******************************/
/* OrcadiaLabs_PortfolioStyles */
/**********************************************************************************************/
/* All known styles.                                                                          */
/**********************************************************************************************/
let OrcadiaLabs_PortfolioStyles = {
  portfolioPage : {                  // the Portfolio page as a whole
    display       : 'flex', 
    flexDirection : 'row' , 
    flexWrap      : 'wrap',
  }, /* portfolioPage */
}; /* OrcadiaLabs_PortfolioStyles */


/****************************/
/* OrcadiaLabsPortfolioPage */
/**********************************************************************************************/
/* Defines the Orcadia Labs portfolio page.                                                       */ 
/**********************************************************************************************/
export class OrcadiaLabsPortfolioPage extends React.Component { 

  /**********/
  /* render */
  /********************************************************************************************/
  /* Overall vertical layout with the Orcadia Labs logo on top:                               */
  /********************************************************************************************/
  render() {
    
    return (
        <div style={OrcadiaLabs_PortfolioStyles.portfolioPage}>
          <AsimovButton           />
          <OrcadiaLabsMotivations />    
          <LifeOfBrianButton      />
          <FourSeasonsButton      />
        </div>
    ); /*return*/
  }; /* render */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor(props) {
    super(props);
  } /* CTOR */
  
}; /* OrcadiaLabsPortfolioPage */




