/************************/
/* FootnoteConstants.js */
/**********************************************************************************************/
/* A convenient location for all Footnote constant values.                                    */
/*                                                                                            */
/* Note:                                                                                      */
/*   * For an interesting effect, it is possible to display an item IN the footnote dialog    */
/*     before even the dialog, itself, has animated into view!  Nice effect.  For instance,   */
/*     the dialog displays a quote mark in-place before the animation:                        */
/*     The secret is the fixed positioning.                                                   */
/*          <img {...(footnoteProps.quote)}                                                   */
/*               style   = {{position:'fixed',top:20,left:20,}}/>                             */
/*                                                                                            */
/*                                                                                            */
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/

export const FootnoteConstants = {
  xitButton : {
    width  : 32,
    height : 32,
  }, /* xitButton */
  
  optionalTopOffset : 32,  // space along the top when requested
  topMarginBorder   :  4,  // size of top border
}; /* FootnoteConstants */




