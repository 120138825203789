/**********************/
/* FootnoteContent.js */
/**********************************************************************************************/
/* A convenient location for all FootnoteDialog content.                                      */
/*                                                                                            */
/* Note:                                                                                      */
/*   * For an interesting effect, it is possible to display an item IN the footnote dialog    */
/*     before even the dialog, itself, has animated into view!  Nice effect.  For instance,   */
/*     the dialog displays a quote mark in-place before the animation:                        */
/*     The secret is the fixed positioning.                                                   */
/*          <img {...(footnoteProps.quote)}                                                   */
/*               style   = {{position:'fixed',top:20,left:20,}}/>                             */
/*                                                                                            */
/* Copyright (C) 2020-2022 Orcadia Labs LLC                                                   */   
/**********************************************************************************************/
import React,
       {Text}                      from 'react'                                              ; 
import {OrcadiaLabs_Constants    } from '../../../constants/constants.js'                    ;
import {ConduitManager, Conduit,
        ConduitNames             } from '../../conduit/ConduitManager.js'                    ;
import {FootnoteDialog           } from '../Footnote.js'                                     ;

import ImageQuote                  from '../../../assets/images/quotes.png'                  ; 
import ImageFourSeasons            from '../../../content/fourSeasons/assets/images/s4_title.png';  
import exitButton                  from '../../../assets/images/xit.png'                     ;
import {FootnoteConstants        } from '../FootnoteConstants.js'                            ;


const FootnoteDialogStyles = {
  footnote : {
    zIndex       : undefined,
    position     : 'fixed',
    display      : 'none',
    top          : 0,
    right        : 0,
    maxWidth     : '50%',
    borderWidth  : 20,
  }, /* footnoteDialogStyles */  
}; /* footnoteDialogStyles */


/***************/
/* FootnoteXit */
/**********************************************************************************************/
/* Each footnote content scope decides for itself where to place the footnote exit button.    */
/* Include this component in the JSX for the specific footnote content to build that button.  */
/* This component will place this button in the upper-right corner of whichever context the   */
/* specific footnote content chooses.                                                         */
/*                                                                                            */
/* @prop dialog is the dialog object.  This object controls how the dialog and exit button    */
/*              behave.                                                                       */
/**********************************************************************************************/
export class FootnoteXit extends React.Component {
   render() {
     var xit = {                           /* exit button             */
       position : 'absolute',
       alignSelf : 'flex-end',
       width    : FootnoteConstants.xitButton.width,
       height   : FootnoteConstants.xitButton.height,
     }; /* xit */
   
     /* if this footnote exit button was constructed correctly, then  */
     /* build it:                                                     */
     if (this.conduit) {
       return (
        <img id='orcadialabs-xit' style={xit} src={exitButton} alt="exit" 
             onClick = {() => {ConduitManager.getConduit(this.conduit).ping()}}/>
       ); /*return*/
     } /*if*/
     
     return null;
  }; /* render */

  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Store the dialog object for which this footnote button is being built.                   */
  /********************************************************************************************/
  constructor(props) {
    super(props);
 
    var conduitProp = props[OrcadiaLabs_Constants.footnote.footnoteConduitProp];
    this.conduit = conduitProp ? conduitProp : undefined;
  }; /* CTOR */
}; /* FootnoteXit */




/**************************/
/* FootnoteContentDefault */
/**********************************************************************************************/
/* Basic, test footnote contnet.                                                              */
/**********************************************************************************************/
export class FootnoteContentDefault extends React.Component {
  render() {
    var footnoteProps = {
      footnoteConduit : ConduitNames.footnoteDefaultConduitName,
    }; /* footnoteProps */

    return (
      <FootnoteDialog {...footnoteProps}>
        Hello Default
      </FootnoteDialog>   
    ); /*return*/
  }; /* render */
}; /* FootnoteContentDefault */


