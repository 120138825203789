/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* Verse Title support.                                                                       */ 
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC, all rights reserved.                           */
/**********************************************************************************************/
import React,
       {Text,
        TouchableWithoutFeedback}        from 'react'                                   ; 
import {FourSeasonsConstants}            from './FourSeasonsConst.js'                   ;

// titles:
import ImageTitleSpring                  from './assets/images/s4_title-springs.png'    ;
import ImageTitleSummer                  from './assets/images/s4_title-summers.png'    ;
import ImageTitleAutumn                  from './assets/images/s4_title-autumns.png'    ;
import ImageTitleWinter                  from './assets/images/s4_title-winters.png'    ;
import ImageTitleOverlaySpring           from './assets/images/s4_title-spring.png'     ;
import ImageTitleOverlaySummer           from './assets/images/s4_title-summer.png'     ;
import ImageTitleOverlayAutumn           from './assets/images/s4_title-autumn.png'     ;
import ImageTitleOverlayWinter           from './assets/images/s4_title-winter.png'     ;



const titleImagePixels = 96;             // # pixels of title image, both width and height


/********************/
/* FourSeasonsTitle */
/**********************************************************************************************/
/* Four Seasons Sonnets Title.                                                                */
/* A title component for the Four Seasons Sonnets encapsulates two elements:                  */
/*   (1) children, which will include the text name of the title.                             */
/*   (2) image   , which is the stylized image to display for the title.                      */
/* @prop -- id identifies which title image to generate.                                      */
/**********************************************************************************************/
export class FourSeasonsTitle extends React.Component {

   static FourSeasonsTitleStyles = {
              sonnetTitle : {
                paddingTop      :  24,                // spacing between this verse and previous
              }, /* sonnetTitle */
              sonnetImage : {
                position        : 'absolute',
                left            : (FourSeasonsConstants.title.horizontalOffset),
                width           : (FourSeasonsConstants.title.dimensions.horizontal), // dimensions of image
                height          : (FourSeasonsConstants.title.dimensions.vertical  ), // should be square
                opacity         : 1,
              }, /* sonnetImage */
   }; /* sonnetTitle */

   
   static TitleIdentifiers = {
   //   each season's marker identifier                      : <FourSeasonsTitle> image tag
   //                                                          <FourSeasonsTitleOverlay> image tag
     [FourSeasonsConstants.scrollMarker.scrollMarker_Spring] : {
         titleImage        : null,
         titleOverlayImage : null,
         overlayOffsetTop  : NaN,
     },
     [FourSeasonsConstants.scrollMarker.scrollMarker_Summer] : {
         titleImage        : null,
         titleOverlayImage : null,
         overlayOffsetTop  : NaN,
     },
     [FourSeasonsConstants.scrollMarker.scrollMarker_Autumn] : {
         titleImage        : null,
         titleOverlayImage : null,
         overlayOffsetTop  : NaN,
     },
     [FourSeasonsConstants.scrollMarker.scrollMarker_Winter] : {
         titleImage        : null,
         titleOverlayImage : null,
         overlayOffsetTop  : NaN,
     },
   }; /* TitleIdentifiers */
   
   
  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw just the full-sized block background with color.                                    */
  /********************************************************************************************/
  render() {
   
    /* select which marker to register: */
    var whichMarker = this.props.id;
    var titleImage  = undefined;
    
    switch (whichMarker) {
      case FourSeasonsConstants.scrollMarker.scrollMarker_Spring :
             titleImage = ImageTitleSpring;
             break;
      case FourSeasonsConstants.scrollMarker.scrollMarker_Summer :
             titleImage = ImageTitleSummer;
             break;
      case FourSeasonsConstants.scrollMarker.scrollMarker_Autumn :
             titleImage = ImageTitleAutumn;
             break;
      case FourSeasonsConstants.scrollMarker.scrollMarker_Winter :
             titleImage = ImageTitleWinter;
             break;
      default : // all expect use cases are defined above.
    }; /*switch*/

    //          {this.props.children}

    return (
      <>
        <div style={FourSeasonsTitle.FourSeasonsTitleStyles.sonnetTitle} />
        <img src = {titleImage} style={FourSeasonsTitle.FourSeasonsTitleStyles.sonnetImage}
             ref = {(image) => { FourSeasonsTitle.IdentifyTitle(this.props.id, image)  }}/>
      </>
    ); /*return*/
  }; /* render */

  
  /*****************/
  /* IdentifyTitle */
  /********************************************************************************************/
  /* Record the absolute location of a <ForeSeasonsTitle> image element.  This method does    */
  /* not verify the input data.  Only the first reference identified need be kept.            */
  /* @param marker is the (FourSeasonsConstants.scrollMarker) associate with this title.      */
  /* @param image  is the React image element representing this title.                        */
  /********************************************************************************************/
  static IdentifyTitle(marker, image) {
    // verify that all data is supplied and only interested in the first identification:
    if ((marker) && (image) &&
        (!FourSeasonsTitle.TitleIdentifiers[marker].titleImage)) {
      FourSeasonsTitle.TitleIdentifiers[marker].titleImage = image;
    } /*if*/
  }; /* IdentifyTitle */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* FourSeasonsTitle */


/***************************/
/* FourSeasonsTitleOverlay */
/**********************************************************************************************/
/* The overlay component for an associated Four Seasons Sonnets Title component.  This        */
/* component represents an image which overlays exactly an associated title image but in a    */
/* unscrolled absolute fashion which allows it to overlay all other fixed or absolute dialog  */
/* content                                                                                    */
/* @prop id identifies which title overlay image to generate.                                 */
/*          This (id) must be the same (id) component which this component overlays.          */           
/**********************************************************************************************/
export class FourSeasonsTitleOverlay extends React.Component {

   static FourSeasonsTitleOverlayStyles = {
            sonnetImage : {
              position        : 'absolute',
              visibility      : 'visible',        // will be shown by default
              top             : NaN,              // will be calculated below
              left            : 8,
              width           : (FourSeasonsConstants.title.dimensions.horizontal), // dimensions of image
              height          : (FourSeasonsConstants.title.dimensions.vertical  ), // should be square
              opacity         : 1,
            }, /* sonnetImage */
   }; /* sonnetTitle */
   
   
  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw just the full-sized block background with color.                                       */
  /********************************************************************************************/
  render() {
    // create a modifiable styles object:
    var overlayStyles = Object.assign({}, FourSeasonsTitleOverlay.FourSeasonsTitleOverlayStyles.sonnetImage);
   
    /* select which marker to register: */
    var whichMarker = this.props.id;
    var titleImage  = undefined;
    
    switch (whichMarker) {
      case FourSeasonsConstants.scrollMarker.scrollMarker_Spring :
             titleImage = ImageTitleOverlaySpring;
             break;
      case FourSeasonsConstants.scrollMarker.scrollMarker_Summer :
             titleImage = ImageTitleOverlaySummer;
             break;
      case FourSeasonsConstants.scrollMarker.scrollMarker_Autumn :
             titleImage = ImageTitleOverlayAutumn;
             break;
      case FourSeasonsConstants.scrollMarker.scrollMarker_Winter :
             titleImage = ImageTitleOverlayWinter;
             break;
      default : // all expect use cases are defined above.
    }; /*switch*/

    // - Perform calculation which aligns this overlay with the corresponding scrolled title image:
    // - The top of the scroll is not the top of the dialog, so adjust the vertical
    //   positioning of this title overlay image to compensate
    // - When an overlay image is first rendered, the correspoing scrolled image may have not yet
    //   been.  When this happens, this overlay image may be rendered in the wrong position, so
    //   just hide it.
    
    
    // NOTE: situation with offset calculation 
    //       if !FourSeasonsTitle.TitleIdentifiers[whichMarker].overlayOffsetTop
    //           then it has not yet been calculated, so just hide the image:
    //       This situation should have only on first render, after first scroll then all is ok.
    if ((FourSeasonsTitle.TitleIdentifiers[whichMarker]                 ) &&
        (FourSeasonsTitle.TitleIdentifiers[whichMarker].titleImage      ) &&
        (FourSeasonsTitle.TitleIdentifiers[whichMarker].overlayOffsetTop)) {
      overlayStyles.top        = FourSeasonsTitle.TitleIdentifiers[whichMarker].overlayOffsetTop
                               + FourSeasonsConstants.experience.scrollBoxPadding.top;
    } /*if*/
    else {
      overlayStyles.visibility = 'hidden';
    } /*else*/
    
    return (
      <img src = {titleImage} style={overlayStyles}
           ref = {(image) => { FourSeasonsTitleOverlay.IdentifyTitleOverlay(this.props.id, image)  }}/>
    ); /*return*/
  }; /* render */
  
  
  /************************/
  /* IdentifyTitleOverlay */
  /***********************************************************************************************/
  /* Record the absolute location of a <ForeSeasonsTitleOverlay> image element.                  */
  /* This method does not verify the input data.                                                 */
  /* Only the first reference identified need be kept.                                           */
  /* @param marker is the (FourSeasonsConstants.scrollMarker) associate with this title.         */
  /* @param image  is the React image element representing this title.                           */
  /***********************************************************************************************/
  static IdentifyTitleOverlay(marker, image) {
    // verify that all data is supplied and only interested in the first identification:
    if ((marker) && (image) &&
        (!FourSeasonsTitle.TitleIdentifiers[marker].titleOverlayImage)) {
      FourSeasonsTitle.TitleIdentifiers[marker].titleOverlayImage = image;
    } /*if*/
  }; /* IdentifyTitleOverlay */
  
  
  /****************/
  /* HandleScroll */
  /***********************************************************************************************/
  /* The scroll handler for the overlay title images of the Four Seasons Sonnets experience.     */
  /* Iterate throught the list of all known <FourSeasonsTitleOverlay> components and match their */
  /* vertical offsets to the corresponding <FourSeasonsTitle> elements.                          */
  /* @param scrollPixels is an array of [top, bottom] pixel offset of the viewport.              */
  /***********************************************************************************************/
  static HandleScroll(scrollPixels) {
    for (var title in FourSeasonsTitle.TitleIdentifiers) {
      // For each of the four title overlay image elements:
      // skip an erroneous reference for incomplete data (should never happen:)
    var titleImage            = FourSeasonsTitle.TitleIdentifiers[title].titleImage;
    var titleOverlayImage     = FourSeasonsTitle.TitleIdentifiers[title].titleOverlayImage;
    var titleOverlayOffsetTop = titleImage.offsetTop - scrollPixels.top;
    FourSeasonsTitle.TitleIdentifiers[title].overlayOffsetTop = titleOverlayOffsetTop;
    
// the following lines writes to a read-only attribute:
//    titleOverlayImage.offsetTop = titleImage.offsetTop;
    
    
//      if ((FourSeasonsTitle.TitleIdentifiers[title][0]) &&
//          (FourSeasonsTitle.TitleIdentifiers[title][1])) {
//        // move the title overlay image to be accurately (over) the primary title image:
//        FourSeasonsTitle.TitleIdentifiers[title][1].offsetTop
//        = FourSeasonsTitle.TitleIdentifiers[title][0].offsetTop;
//      } /*if*/
    } /*for*/
    
  }; /* HandleScroll */
  
}; /* FourSeasonsTitleOverlay */


  
