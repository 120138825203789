
/***********************/
/* inanyeventConst.js */
/**********************************************************************************************/
/* Constant for this In Any Event... page.                                                    */
/*                                                                                            */
/* Copyright (C) 2022 Orcadia Labs LLC                                                        */    
/**********************************************************************************************/

// initial calculations for the /inanyevent scroll:
var orcadiaLabsScrollHeaderImageWidth  = 980;
var orcadiaLabsScrollHeaderImageHeight = 490;
var orcadiaLabsHeightToWidthRatio = (orcadiaLabsScrollHeaderImageHeight / orcadiaLabsScrollHeaderImageWidth);
var orcadiaLabsBlockWidth              = 256;

// Title dimensions to calculate block:
var OrcadiaLabsTitleImageWidth = 1200;
var OrcadiaLabsTitleImageHeight = 1400;

export const inanyeventConst = {

  // the noWaste icons are the upper-right square images:
  // these noWaste icons are square and 
  // 21.01.28:  I've advanced to the non-square (iaeCorner) icons
  // noWaste : {
  //   // for no waste image calculations, 40% of image is text margin, 2 margins leaves 20% left
  //   // of that 20%, half is left-right or top-bottom. (0.4 + 0.4 + 0.1 + 0.1 == 1.0)
  //   imageSize             :  96,            // 96 pixels constant
  //   imageSizeInsideBlock  : (96 * 0.4),     // 40% of icon side inside block
  //   imageSizeBetweenBlock : (96 * 0.1),     // 20% of icon between blocks
  // }, /* noWaste */
  
  // the iaeCorner icons are the upper-right rectangular images:
  // Calculations for (iaeCorner) are similar to (noWaste) except that the images 
  // are rectangular, not square.
  iaeCorner : {
    imageDimensions : {
      horizontal :  96,  // each corner image is 96 pixels wide
      vertical   : 144   // each corner image has a scale ratio of 3:2
    }, /* imageDimensions */
    spacings : {
      insideBlock : {
        horizontal : ( 96 * 0.4),  // each corner image is 96 pixels wide
        vertical   : (144 * 0.4),  // each corner image has a scale ratio of 3:2
      }, /* insideBlock */
      betweenBlock : {
        horizontal : ( 96 * 0.1),  // each corner image is 96 pixels wide
        vertical   : (144 * 0.1)   // each corner image has a scale ratio of 3:2
      }, /* betweenBlock */
    }, /* spacings */
  }, /* iaeCorner */
  
  minimumPageMargin :  16,
  brandSize         : 128,
  brandOffset       :   8,
  blockWidth        : (orcadiaLabsBlockWidth),

  titleImage : {
    box : {
      width : (orcadiaLabsBlockWidth),
      height : ((orcadiaLabsBlockWidth) 
                * (OrcadiaLabsTitleImageHeight / OrcadiaLabsTitleImageWidth)),
    },
    image : {
      width  : (orcadiaLabsBlockWidth - 8),
      height : ((orcadiaLabsBlockWidth - 8) 
                * (OrcadiaLabsTitleImageHeight / OrcadiaLabsTitleImageWidth)),
    },
  },
  
  // Some additional information for rendering the /inanyevent scroll   
  scroll : {
    scrollHeader : {
      height : 150,
      width  : '100%',
    }, /* scrollHeader */
    scrollHeaderImage : {
      imageWidth         : (150 * orcadiaLabsScrollHeaderImageWidth / orcadiaLabsScrollHeaderImageHeight) ,
      imageHeight        : 150,
    }, /* scrollHeaderImage */
    scrollBrandImage : {
      imageWidth         : 150,
      imageHeight        : 150,
    }, /* scrollBrandImage */
  }, /* InAnyEvent */
  

  // Color values for text background:
  // (1) Each block has a monochromatic, semi-transparent Orcadia Labs teal border 
  //     and a fully-opaque background text.
  // (2) The background of the entire /inanyevent page is an image of paper with
  //     multiple shades of grey.
  //
  // The actual color of the border, as viewed, is the combination of this semi-
  // transparent border color
  // with the multiple shades of background grey.  The result is multiple shades 
  // of Orcadia Labs teal.
  // Object (colors) contains the RGBs of some of these multiple shades of teal.  
  // They all work as a text background color.  I will pick one (uncomment) by my taste.
  // If the used shade of Orcadia Labs teal (InAnyEventBlockBackgroundImage) ever changes, 
  // the actual, acceptable
  // colors of the text background must be re-sampled.
  // 21.07.14 - I sampled three Orcadia Labs teal colors for background color:
  //            (InAnyEvent_Colors.OrcadiaLabs_AlmostWhiteTeal).
  //            I also create two artificially light teals 10% and 5% lighter than the 
  //            lightest sample.
  //            Having this very much lighter teal helps set the text apart from the 
  //            border and just looks better.
  textColors : {
    //backgroundColor : '#bbf9f6',       // artificially lightest Orcadia Labs teal (1.10 * lightest)
      backgroundColor : '#b5e0dc',       // artificially lighter  Orcadia Labs teal (1.05 * lightest)
    //backgroundColor : '#aad6d2',       // sampled      lightest Orcadia Labs teal
    //backgroundColor : '#a5d0cc',       // sampled      lighter  Orcadia Labs teal #2
    //backgroundColor : '#a3cecb',       // sampled      lighter  Orcadia Labs teal #1
    //backgroundColor : '#99c5c1',       // sampled      light    Orcadia Labs teal
  }, /* colors */
  
  // Paper Colors are typical colors from the crumpled paper background.
  // These colors are roughly ordered from lightest to darkest
  paperColors : {
    'lightestColor'           : '#b8bab4',
    'quarterMedianColor'      : '#b7bab4',
    'medianColor'             : '#b3b5be',
    'threeQuarterMedianColor' : '#adb0a9',
    'darkestColor'            : '#9ea09a',
    'allColors'               : [
      '#b8bab4', '#b7bab3', '#b7bab4', '#b5b7b1', '#b3b6b0', '#b3b5be', 
      '#b1b3bd', '#b0b3bc', '#adb0a9', '#a7a9a3', '#9ea09a'
    ],
  }, /* papaerColors */
}; /* inanyeventConst */

