/****************/
/* VigorPage.js */
/**********************************************************************************************/
/* Orcadia Labs Vigor Page.                                                                   */
/*   -- production design services --                                                         */
/*                                                                                            */    
/* Copyright (C) 2019 - 2021 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React,
       {Image}                 from 'react'                       ; 
import {OrcadiaLabs_Constants} from "../../constants/constants.js";


/************************/
/* OrcadiaLabsVigorInfo */
/**********************************************************************************************/
/* Content for the orcadialabs.com vigor page.                                                */
/**********************************************************************************************/
let OrcadiaLabsVigorInfo = {
  button : {
    position         : 'relative',
    margin           : 0,
    width            : 500,
    maxWidth         : '75%',
    overflow         : 'visible',
    display          : 'inline-block',
  }, /* button */
  
  buttonImage : {
    width            : '100%',
  }, /* buttonImage */
  
  radiant     : {
    display          : 'block',
    position         : 'absolute',
    zIndex           : -1,
    width            : '120%',
    left             : '-25%',
    top              : '5%',
  }, /* radiant */
  
};  /* OrcadiaLabsVigorInfo */
  


/************************/
/* OrcadiaLabsVigorPage */
/**********************************************************************************************/
/* Content for the orcadialabs.com home page.                                                 */
/**********************************************************************************************/
export class OrcadiaLabsVigorPage extends React.Component {


  /**********/
  /* render */
  /********************************************************************************************/
  /********************************************************************************************/
  render() {
    return (
      <div className = "defaultPage">
        <p className = "textGeneral">Vigor Page is coming soon.</p>
      </div>
    ); /*return*/
  }; /* render */
}; /* OrcadiaLabsVigorPage */







