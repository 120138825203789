/*********************************/
/* FootnoteContentLifeOfBrian.js */
/**********************************************************************************************/
/* Footnote Context for "Life of Brian"                                                       */
/*                                                                                            */
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React,
       {Text}                         from 'react'                                 ; 
import {OrcadiaLabs_Constants       } from '../../../constants/constants.js'        ;
import {ConduitNames                } from '../../conduit/ConduitManager.js'       ;
import ImageQuote                     from '../../../assets/images/quotes.png'     ; 
import ImageBrian                     from '../../../assets/images/lifeofbrian.png'; 
import {FootnoteXit                 } from './FootnoteContent.js'                  ;
import {FootnoteDialog              } from '../Footnote.js'                        ;
import {FootnoteConstants           } from '../FootnoteConstants.js'               ;


/******************************/
/* FootnoteContentLifeOfBrian */
/**********************************************************************************************/
/* Generate content of footnote for "Life of Brian"                                           */
/**********************************************************************************************/
export class FootnoteContentLifeOfBrian extends React.Component {
  render() {
    var footnoteProps = {
      // every footnote needs to know its own conduit for communication:
      footnote : {
        footnoteConduit : ConduitNames.footnoteLifeofBrianConduitName,
      }, /* footnote */
      
      // the Life of Brian image in the footnote:
      // 528 X 268 pixels
      lifeOfBrian : {
        src             : (ImageBrian),
        alt             : ("Life of Brian"),
        width           : 150,
        height          :  76,
      }, /* lifeOfBrian */
        
      // both footnote quote marks need to know the quote image to display:
      quote    : {
        src             : (ImageQuote),
        alt             : ("quote"),
      }, /* quote */
    }; /* footnoteProps */
    
    var quoteWidth = 60;
    var textWidth  = 14 * 48;
    var footnoteStyles = {
      lyricContainer : {
        display       : 'flex',
        flexDirection : 'column',
        padding       : 8,
      }, /* lyric */
      lyric : {
        display       : 'flex',
        flexDirection : 'column',
        paddingRight  : (4 + FootnoteConstants.xitButton.width),
      }, /* lyric */
      subText    : {
        display       : 'flex',
        flexDirection : 'column',
        padding       : 16,
      }, /* lyric */
      text : {
        fontSize       : 16,
        marginTop      : 0,
        marginBottom   : 0,
        width          : '100%',
      }, /* text */
      attribution : {
        fontSize       : 12,
        marginTop      : 0,
        marginBottom   : 0,
        width          : '100%',
      }, /* attribution */
      brian : {
        width    : 106,
        height   : 54,
        opacity  : 1,
      }, /* brian */
      quoteLeft : {
        position : 'absolute',
        alignSelf : 'flex-start',
        width    : (quoteWidth),
        height   : (quoteWidth * 395 / 492),
        opacity  : 0.3,
      }, /* quoteLeft */
      quoteRight : {
        position    : 'absolute',
        alignSelf   : 'flex-end',
        marginRight : (FootnoteConstants.xitButton.width), // make room for exit button
        width       : (quoteWidth),
        height      : (quoteWidth * 395 / 492),
        opacity     : 0.3,
        transform : 'rotate(180deg)'
      }, /* quoteRight */
    }; /* footnoteStyles */

    return (
      <FootnoteDialog {...footnoteProps.footnote}>
        <img {...(footnoteProps.lifeOfBrian)} style = {footnoteStyles.fourSeasons}/>
        <div style = {footnoteStyles.lyricContainer}>
          <FootnoteXit {...(footnoteProps.footnote)} />
          <div style = {footnoteStyles.lyric}>
            <img {...(footnoteProps.quote)} style   = {footnoteStyles.quoteLeft}/>
            <img {...(footnoteProps.quote)} style   = {footnoteStyles.quoteRight}/>
            <p style={footnoteStyles.text}>When you're chewing on life's gristle,</p>
            <p style={footnoteStyles.text}>Don't grumble, give a whistle,</p>
            <p style={footnoteStyles.text}>and this'll help things turn out for the best...</p>
          </div>
        </div>
        <div style = {footnoteStyles.subText}>
          <p style={footnoteStyles.attribution}>"Always Look on the Bright Side of Life"</p>
          <p style={footnoteStyles.attribution}>Monty Python's "Life of Brian" (1979)</p>
          <p style={footnoteStyles.attribution}>Image of Eric Idle and text of song copyright {'\u00A9'} Python (Monty) Pictures</p>
        </div>
      </FootnoteDialog>   
    ); /*return*/
  }; /* render */
}; /* FootnoteContentLifeOfBrian */


