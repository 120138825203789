/**************************/
/* OrcadiaLabsHomePage.js */
/**********************************************************************************************/
/* Orcadia Labs Home Page                                                                     */
/*                                                                                            */    
/* Copyright (C) 2019 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React                       from 'react'                                   ; 
import {OrcadiaLabs_Constants}     from "../../constants/constants.js"            ;
import {ConduitManager, Conduit,
        ConduitNames             } from '../../library/conduit/ConduitManager.js' ;
import {OrcadiaLabsVigorPage}      from '../vigor/OrcadiaLabsVigorPage.js'        ;
import CtAdonors                   from './assets/images/CtA-donors.png'          ;
import CtAsales                    from './assets/images/CtA-sales.png'           ;
import CtAInAnyEvent               from './assets/images/CtA-IAE.png'             ;
import CtAInAnyEventSlideshow      from './assets/images/iae-director.png'        ;
import CtAradiant                  from './assets/images/radiate.png'             ;
import {OrcadiaLabsMotivations}    from '../motivations/OrcadiaLabsMotivations.js';           


/***********************/
/* OrcadiaLabsHomePage */
/**********************************************************************************************/
/* Content for the orcadialabs.com home page.                                                 */
/**********************************************************************************************/
let OrcadiaLabsHomeInfo = {
  // styles for each of the CtA buttons:
  buttonContainer     : {
    position        : 'relative',
    padding         : 50,
    display         : 'flex',
    flexDirection   : 'row',
    flexWrap        : 'wrap',
    justifyContents : 'space-between',
  }, /* buttonContainer */
  
  button              : {
    position          : 'relative',
    marginLeft        : -15,
    marginRight       : -15,
    marginTop         : -24,
    marginBottom      : -24,
    overflow          : 'visible',
    display           : 'inline-block',
  }, /* button */
  
  buttonImage         : {
    position          : 'absolute',
    left              : '21%',
    top               : '4%',
    width             : '78%',
  }, /* buttonImage */
  
  buttonRadiantStyles : {
    width             : '100%',
    // smallest width to handle entire button on phones
    minWidth          : 150,          
    maxWidth          : 300,
    height            : 'auto',
  }, /* buttonRadiantStyles */

  buttonRadiantProps  : {
    src               : (CtAradiant),
    alt               : "Orcadia Labs",
  }, /* buttonRadiantProps */

  // styles for just the block of CtA buttons:
  CtAInfo : {
    CtASales : {
      src             : (CtAsales),
      alt             : ("View information about Production Design for businesses and customers"),
      link            : ("../vigor/OrcadiaLabsVigorPage")                                           /* Instead use ROUTER ! */
    }, /* CtASales */
    CtADonors : {
      src             : (CtAdonors),
      alt             : ("View information about Production Design for charities and donors"),
      link            : ("../vigor/OrcadiaLabsVigorPage")                                           /* Instead use ROUTER ! */
    }, /* CtADonors */
    CtAInAnyEvent : {
      src             : (CtAInAnyEvent),
      alt             : ("View information about In Any Event... for event directors and attendees"),
      link            : ("../inanyevent/InAnyEventPage")                                           /* Instead use ROUTER ! */
    }, /* CtAInAnyEvent */  
    CtAInAnyEventSlideshow : {
      src             : (CtAInAnyEventSlideshow),
      alt             : ("In Any Event... slideshow presentation"),
      link            : ("https://docs.google.com/presentation/d/e/2PACX-1vS_ZIulPYbcHfV0X5vU4nCGLg6AkH_KTPN2DQMxluUA3sGROWzHBH8wFA__AV8fplx_qRXyOmpwdQml/pub?start=false&loop=false&delayms=10000")                                           /* Instead use ROUTER ! */
    }, /* CtAInAnyEventSlideshow */  
  }, /* CtAInfo */
  
  // styles for all of the home content
  homeContainer   : {
  },
  
  // Images:
  AllImageStyles  : {
    width            : 256,  
    maxWidth         : '100%', 
    marginTop        : 8
  },
  
  
};  /* OrcadiaLabsHomeInfo */
  

  

/***********************/
/* OrcadiaLabsHomePage */
/**********************************************************************************************/
/* Content for the orcadialabs.com home page.                                                 */
/**********************************************************************************************/
export class OrcadiaLabsHomePage extends React.Component {

  /*******************/
  /* clickInAnyEvent */
  /********************************************************************************************/
  /* Click to the In Any Event... page                                                        */
  /********************************************************************************************/
  clickInAnyEvent() {
    console.log("bloop");
  }; /* clickInAnyEvent */

  
  /**********/
  /* render */
  /********************************************************************************************/
  /* Home page content.                                                                       */
  /********************************************************************************************/
  render() {
    var cTaButtonProperties = {
      buttonInAnyEvent : {
        buttonLayer : '3',
        buttonInfo  : (OrcadiaLabsHomeInfo.CtAInfo.CtAInAnyEvent),
        href        : ("/inanyevent"),
      }, /* buttonInAnyEvent */
      buttonInAnyEventSlideshow : {
        buttonLayer : '3',
        buttonInfo  : (OrcadiaLabsHomeInfo.CtAInfo.CtAInAnyEventSlideshow),
        href        : ("https://docs.google.com/presentation/d/e/2PACX-1vS_ZIulPYbcHfV0X5vU4nCGLg6AkH_KTPN2DQMxluUA3sGROWzHBH8wFA__AV8fplx_qRXyOmpwdQml/pub?start=false&loop=false&delayms=10000"),
      }, /* buttonInAnyEventSlideshow */
      buttonSales : {
        buttonLayer : '2',
        buttonInfo  : (OrcadiaLabsHomeInfo.CtAInfo.CtASales),
//        href        : ("http:www.orcadialabs.com/inanyevent"),
      }, /* buttonSales */
      buttonDonors : {
        buttonLayer : '1',
        buttonInfo  : (OrcadiaLabsHomeInfo.CtAInfo.CtADonors),
//        href        : ("http:www.orcadialabs.com/inanyevent"),
      }, /* buttonDonors */
    }; /* cTaButtonProperties */
 
 
   // 22.03.09 - I removed the two production design buttons
   //            to concentrate my focus on IAE.
   //       <OrcadiaLabsCtAButton {...cTaButtonProperties.buttonSales}/>
   //       <OrcadiaLabsCtAButton {...cTaButtonProperties.buttonDonors}/>
   
    return (
      <>            
        <div style={ OrcadiaLabsHomeInfo.buttonContainer}>
          <OrcadiaLabsCtAButton {...cTaButtonProperties.buttonInAnyEvent}/>
          <OrcadiaLabsCtAButton {...cTaButtonProperties.buttonInAnyEventSlideshow}/>
          <OrcadiaLabsMotivations />    
        </div>    
      </>
    ); /*return*/
  }; /* render */


  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Establish home page state.                                                               */
  /* @param props are the home page properties.                                               */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* OrcadiaLabsHomePage */


/************************/
/* OrcadiaLabsCtAButton */
/**********************************************************************************************/
/* One CtA button for the orcadialabs.com vigor page.                                         */
/* @prop buttonInfo is the object of information for which to build an OrcadiaLabs CtA button */
/**********************************************************************************************/
let OrcadiaLabsCtAButton = (props) => {
  // order the buttons on the home page.
  var buttonStyles = Object.assign({}, OrcadiaLabsHomeInfo.button);
  buttonStyles.zIndex = props.buttonLayer;
  
  // remove the overlap, this change is temporary and for only as
  // long as there is more on the home page than just CtA buttons.
  buttonStyles.marginLeft        = 15;
  buttonStyles.marginRight       = 15;
  buttonStyles.marginTop         = 24;
  buttonStyles.marginBottom      = 24;

  return (
    <a href={props.href}>
      <div style={(buttonStyles)}>
        <img src={(CtAradiant)} style={(OrcadiaLabsHomeInfo.buttonRadiantStyles)}/>
        <img {...(props['buttonInfo'])} style={OrcadiaLabsHomeInfo.buttonImage} />
      </div>
    </a>
  ); /*return*/
}; /* OrcadiaLabsCtAButton */


