/****************************/
/* OrcadiaLabsForeground.js */
/**********************************************************************************************/
/* The background for the new orcadialabs.com website.                                        */
/*                                                                                            */    
/* Copyright (C) 2021 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React            from 'react'                               ; 
        
import {OrcadiaLabs_Constants    ,
        OrcadiaLabs_CommonStyles } from '../constants/constants.js'                        ;
import hawkMG                      from '../assets/images/UX/hawk-mg.png'                    ; 
import hawkFG                      from '../assets/images/UX/hawk-fg.png'                    ; 
import hawkOrcadiaLabs             from '../assets/images/UX/hawk-orcadialabs.png'           ; 
import hawkOrcadiaLabsBack         from '../assets/images/UX/hawk-orcadialabs-back.png'      ;

// finally, the Motivations Animations are moving to the foreground:
import {OrcadiaLabsMotivations}    from '../content/motivations/OrcadiaLabsMotivations.js';           


/***********************/
/* ForegroundConstants */
/**********************************************************************************************/
const ForegroundConstants = {
  zValues : {
    foreGroundBorder : 2000,
    foreGround       : 1000,
    border           :  800,
    midGround        :  600,
    backGround       :  400,
    animation        :  500,
  }, /* zValues */
  
  brand : {
    width       : (OrcadiaLabs_Constants.SPA.brand.widthToUse ),
    height      : (OrcadiaLabs_Constants.SPA.brand.heightToUse),
    fullWidth   : '95vw',
    fullHeight  : '' + (95 * (64/154)) + 'vw',
  }, /* brand */
  
  hawk  : {
    scaledToWidthFit :  '27.405%',              // fb to gb ratio:  186px / 686px
    standardHeight   : 140 
  }, /* hawk */
  
  layers : {                                     // zIndex values for each layer
    behindLayer      : 10,
    contentLayer     : 20,
    inFrontLayer     : 30,                       // hawk et al
    upFrontLayer     : 40,                       // brand is always in front of everything
  }, /* layers */
}; /* ForegroundConstants */


/*********************/
/* GetBlockTopStyles */
/**********************************************************************************************/
/* Obtain styles for the top block.                                                           */
/* @param  titleBlock should be true if the block is intended for the visible page title.     */
/*                    By default, this function assumes the styles are for the scroll.        */
/* @return the styles object for the top block.                                               */
/**********************************************************************************************/
let GetBlockTopStyles = (titleBlock = false) => {
  var blockStyles ={
       position  : undefined                                 ,
       left      : undefined                                 ,
       top       : undefined                                 ,
       zIndex    : undefined                                 ,
       width     : (ForegroundConstants.brand.width         ),
       height    : (ForegroundConstants.brand.height        ),
       maxWidth  : (ForegroundConstants.brand.fullWidth     ),
       maxHeight : (ForegroundConstants.brand.fullHeight    ),
     };
  
  // all default style values are for the scrolling block, make changes for the title block:
  if (titleBlock) {
    blockStyles.position = 'fixed';
    blockStyles.left     = 0;
    blockStyles.top      = 0;
    blockStyles.zIndex   = (ForegroundConstants.layers.upFrontLayer);
  } /*if*/
  
  return (blockStyles);
}; /* GetBlockTopStyles */


/************************/
/* GetBlockBottomStyles */
/**********************************************************************************************/
/* Obtain styles for the bottom block.                                                        */
/* Styles for the bottom block are already collected, just render them on the correct layer.  */
/* @return the styles object for the bottom block.                                            */
/**********************************************************************************************/
let GetBlockBottomStyles = () => {
  var blockStyles = Object.assign({}, 
                                  OrcadiaLabs_CommonStyles.landingFrameHawk, 
                                  {zIndex : ForegroundConstants.layers.inFrontLayer});
  return (blockStyles);                      
}; /* GetBlockBottomStyles */


/*************************/
/* OrcadiaLabsForeground */
/**********************************************************************************************/
/* A component which renders and manages everything associated with the SPA foreground.       */
/* The SPA OrcadiaLabsForeground acts as the common foreground for all SPA pages.             */
/**********************************************************************************************/
export class OrcadiaLabsForeground extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Render the foreground material including brand and hawk.                                 */
  /********************************************************************************************/
  render() {    
    var ForegroundStyles = {
      foreground : {
        zIndex   : (ForegroundConstants.layers.inFrontLayer  ),
      }, /* foreground */
      
      // place the hawk image
      // the hawk image goes in a div already sized for it:
      foregroundHawk : {
        position : 'absolute'                                 ,
        left     : 0                                          ,
        bottom   : 0                                          ,
        width    : '100%'                                     ,
      },  /* foreground */
    }; /* ForegroundStyles */
     
    var brandStyles = GetBlockTopStyles   (true);
    var hawkStyles  = GetBlockBottomStyles(    );
    
    // draw the Hawk, render all necessary images
    // to reinstate the Bull's Eye animation, merely include:
    //     <Bullseye {...animationProps} /> 
    //
    // the following two statements draw the white "Orcadia Labs"
    //   <img  style={brandStyles} src={hawkOrcadiaLabsBack} alt="OrcadiaLabs.com home" />
    //   <img  style={brandStyles} src={motivationsOrcadiaLabs    } alt="OrcadiaLabs.com Brand"/>

    return (
      <>
        <div style={hawkStyles}>
          <img style={ForegroundStyles.foregroundHawk} src={hawkMG} alt="OrcadiaLabs.com home" />
          <img style={ForegroundStyles.foregroundHawk} src={hawkFG} alt="OrcadiaLabs.com home" />
          <img  style={brandStyles} src={hawkOrcadiaLabsBack}       alt="OrcadiaLabs.com home" />
          <img  style={brandStyles} src={hawkOrcadiaLabs    }       alt="OrcadiaLabs.com home" />
        </div>
      </>
    );
  }; /* render */
  

  /*********************/
  /* componentDidMount */
  /********************************************************************************************/
  /* After first rendering the background, start the animation.                               */
  /********************************************************************************************/
  componentDidMount() {
  }; /* ComponentDidMount */

  
  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */

}; /* OrcadiaLabsForeground */



/************************/
/* OrcadiaLabsSpacerTop */
/**********************************************************************************************/
/* A component which renders a spacer component that exactly matches the size of the portion  */
/* of the top of the foreground layer which orcadialabs.com wishes to clear.                  */
/* This component has dimension but is not positioned.                                        */
/**********************************************************************************************/
export class OrcadiaLabsSpacerTop extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Render the foreground material including brand and hawk.                                 */
  /********************************************************************************************/
  render() {    
    var brandStyles = GetBlockTopStyles();

    return (
        <img  style={brandStyles} src={hawkOrcadiaLabsBack} alt="OrcadiaLabs.com home" />
    );
  }; /* render */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */

}; /* OrcadiaLabsSpacerTop */



/***************************/
/* OrcadiaLabsSpacerBottom */
/**********************************************************************************************/
/* A component which renders a spacer component that exactly matches the size of the portion  */
/* of the bottom of the foreground layer which orcadialabs.com wishes to clear.               */
/* This component has dimension but is not positioned.                                        */
/**********************************************************************************************/
export class OrcadiaLabsSpacerBottom extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Render the foreground material including brand and hawk.                                 */
  /********************************************************************************************/
  render() {    
    var bottomSpacerStyles = {
          width  : '100%',
          height : (OrcadiaLabs_CommonStyles.landingFrameHawk.maxHeight),
        }; /* bottomSpacerStyles */

    return (
        <div style={bottomSpacerStyles}/>
    );
  }; /* render */
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */

}; /* OrcadiaLabsSpacerBottom */



