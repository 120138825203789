/****************/
/* constants.js */
/**********************************************************************************************/
/* Generally useful stuff to know for orcadialabs.com                                         */
/* Copyright (C) 2020-2022 Orcadia Labs LLC                                                   */   
/**********************************************************************************************/

// known image data values:
var hawkBackgroundWidth               = 2743;
var hawkBackgroundHeight              = 1269;
var backgroundImageHeightToWidthRatio = (hawkBackgroundHeight / hawkBackgroundWidth);
var backgroundWidthMin                =  800;
var backgroundWidthMax                = 1200;
var backgroundHeightMin               = (backgroundWidthMin * backgroundImageHeightToWidthRatio);
var backgroundHeightMax               = (backgroundWidthMax * backgroundImageHeightToWidthRatio);

var hawkImageWidth                         =  188;
var hawkImageHeight                        =   90;
var hawkImageHeightToWidthRatio            = (hawkImageHeight / hawkImageWidth     ); 

// 21.08.25.1500 - At this point in time, the hawk image (188X90) and the background image (2743X1269)
//                 are not on the same scale.  A scaled hawk image should have around ~740px.
//                 Until I make a properly scaled hawk image.  These calculations will need to scale
//                 the two different parts at different ratios.  The scaling factor to use right now is:
//                     188/686 = 27.405%
//                 The proper statement should be:
//                     var hawkImageWidthToBackgroundWidthRatio   = (hawkImageWidth  / hawkBackgroundWidth);
var hawkImageWidthToBackgroundWidthRatio   = 0.27405;


var hawkImageWidthMin                      = (hawkImageWidthToBackgroundWidthRatio * backgroundWidthMin);
var hawkImageWidthMax                      = (hawkImageWidthToBackgroundWidthRatio * backgroundWidthMax);
var hawkImageHeightMin                     = (hawkImageWidthMin * hawkImageHeightToWidthRatio);
var hawkImageHeightMax                     = (hawkImageWidthMax * hawkImageHeightToWidthRatio);

// statistics about the Orcadia Labs brand (with portal image:
var orcadiaLabsBrandImageWidth             = 1875;
var orcadiaLabsBrandImageHeight            =  812;
var orcadiaLabsBrandImageRatio             = (orcadiaLabsBrandImageHeight / orcadiaLabsBrandImageWidth);
var orcadiaLabsBrandStandardWidth          = 308;
var orcadiaLabsBrandStandardHeight         = (orcadiaLabsBrandStandardWidth  * orcadiaLabsBrandImageRatio);
var orcadiaLabsBrandLargeWidth             = (orcadiaLabsBrandStandardWidth  * 2);
var orcadiaLabsBrandLargeHeight            = (orcadiaLabsBrandStandardHeight * 2);
var orcadiaLabsBrandVeryLargeWidth         = (orcadiaLabsBrandStandardWidth  * 3);
var orcadiaLabsBrandVeryLargeHeight        = (orcadiaLabsBrandStandardHeight * 3);

export let OrcadiaLabs_Constants = {
  // colors identifies standardized colors for consistency:
  colors : {
    teals : {
      OrcadiaLabs_DarkestTeal   : '#067e7d',
      OrcadiaLabs_VeryDarkTeal  : '#079392',
      OrcadiaLabs_DarkTeal      : '#08a8a7',
      OrcadiaLabs_Teal          : '#09bdbc',
      OrcadiaLabs_LightTeal     : '#0ac2c1',
      OrcadiaLabs_VeryLightTeal : '#0bd7d6',    
      OrcadiaLabs_LightestTeal  : '#0cfcfa',     
    }, /* teal colors */
    greens : {
      lightGreen        : '#9ABA80',  // 154 185 128
      mediumGreen       : '#93B26D',  // 147 178 110
      darkGreen         : '#5A8347',  //  90 131  71
      darkerGreen       : '#4D7000',  //  78 112   0
      greenShadow       : '#0C2A0D',  //  12  42  14
      darkGreenShadow   : '#041B00',  //   4  27   0  
    }, /* green colors */
  
    landscape : {
      lightestBranch    : '#CFCCDC',  // 207 204 221
      lighterBranch     : '#B3DD83',  // 179 221 131
      lightBranch       : '#B3AB94',  // 179 171 148
      mediumBranch      : '#927D65',  // 146 126 101
      brownLeaves       : '#67441D',  // 103 068 030
    }, /* landscape colors */
    
    hawk : {
      HawkLightFeathers : '#FFFCDD',  // 255 252 221
      HawkBrownFeathers : '#887267',  // 136 114 103
      hawkBeak          : '#FFFC5B',  // 255 252 091
      hawkFoot          : '#FFFFC9',  // 255 255 201
    }, /* hawk */
  }, /* colors */    

  footnote : {
    footnoteConduitProp : 'footnoteConduit',
    footnoteStylesProp  : 'footnoteStyles' ,
  }, /* footnote */
  
  // specific constants related to the entire app and/or OrcadiaLabs.com:
  OrcadiaLabs : {
    OrcadiaLabsTitle    : "Orcadia Labs",
  }, /* OrcadiaLabs */
  
  // SPA identifies parameters for how the SPA is layed out:
  SPA : {
  
    // navigation constants define which page to display upon change:
    // the values for these keys contain no useful information, they must be each unique
    navigation : {
      pageSPA           : 'page0',  // The home page is minimal, AtF material and gateway   
      pageAbout         : 'page1',  // about page, general landing page for orcadialabs.com
      pagePortfolio     : 'page2',  // portfolio show work Orcadia Labs has done
      pageServices      : 'page3',  // services shows work Orcadia Labs could o
      pageAccessibility : 'page4',  // accessibility show information about accessibility services
      pageInAnyEvent    : 'page5',  // in any event is the Orcadia Labs landing page for In Any Event...
    },  /* navigation */
    brand : {
      standardWidth      : (orcadiaLabsBrandStandardWidth  ),    // natural width of brand image
      standardHeight     : (orcadiaLabsBrandStandardHeight ),    // natural scaled height of brand image
      largeWidth         : (orcadiaLabsBrandLargeWidth     ),    // large image of above
      largeHeight        : (orcadiaLabsBrandLargeHeight    ),
      veryLargeWidth     : (orcadiaLabsBrandVeryLargeWidth ),    // very large
      veryLargeHeight    : (orcadiaLabsBrandVeryLargeHeight),
      heightToWidthRatio : (orcadiaLabsBrandImageRatio     ),
      widthToUse         : (orcadiaLabsBrandStandardWidth  ),
      heightToUse        : (orcadiaLabsBrandStandardHeight ),
    }, /* brand */
    image : {
      hawkBackgroundWidth  : 2743,
      hawkBackgroundHeight : 1269,
    }, /* image */
  }, /* SPA */
  
  home : {
    // Definitions for the "Four Seasons Sonnets" 
    fourSeasons : {
      width            : 640,  
      maxWidth         : '100%',
      height           : 640,
      maxHeight        : '50%',
    }, /* fourSeasons */
  }, /* home */
  
  // BackgroundConstants contain common values for the (background and foreground) landing frame
  BackgroundConstants : {
  
    // the background layer contains multiple layers of woods
    zValues : {
      foreGroundBorder : 2000,
      foreGround       : 1000,
      border           :  800,
      midGround        :  600,
      backGround       :  400,
      animation        :  500,
    }, /* zValues */

    // the background animation occurs every so often; can be made faster, slower here
    // (*frameDelay)   dictates how many milliseconds to wait between animations.
    //                 the actual frame delay will be a number randomly picked between these two values.
    // (fireFrequency) dictates how requently shimmer should use the 'fire' image as opposed to the white.
    //                 a value of 0.25 = 25%
    shimmerAnimation : {
      frameDelay : {
        minimumFrameDelay : 1000, /* milliseconds */
        maximumFrameDelay : 6000, /* milliseconds */
      }, /* frameDelay */
      fireDelay   : 0.40,
    }, /* hawkAnimation */
    
    // both (backgroundImage) and (foregroundImageHawk) must be calculated in precisely the
    // same dimensions and relative frame:
    backgroundImage : {
      // real height and width values, expressed as VW's:
      backgroundImageWidthVW   : (100),
      backgroundImageHeightVW  : (100 * backgroundImageHeightToWidthRatio),
    
      // display height and width values, expressed as px's:
      backgroundImageWidthMin  : (backgroundWidthMin),
      backgroundImageWidthMax  : (backgroundWidthMax),
      backgroundImageHeightMin : (backgroundWidthMin * backgroundImageHeightToWidthRatio),
      backgroundImageHeightMax : (backgroundWidthMax * backgroundImageHeightToWidthRatio),
    }, /* backgroundImage */
  
    foregroundImageHawk : {
      // real height and width values, expressed as VW's:
      foregroundImageHawkWidthVW   : (100 * hawkImageWidthToBackgroundWidthRatio),
      foregroundImageHawkHeightVW  : (100 * hawkImageWidthToBackgroundWidthRatio * hawkImageHeightToWidthRatio),
    
      // display height and width values, expressed as px's:
      foregroundImageHawkWidthMin  : (hawkImageWidthMin ),
      foregroundImageHawkWidthMax  : (hawkImageWidthMax ),
      foregroundImageHawkHeightMin : (hawkImageHeightMin),
      foregroundImageHawkHeightMax : (hawkImageHeightMax),
    }, /* foregroundImageHawk */
    
  }, /* BackgroundConstants */
  
}; /* OrcadiaLabs_Constants */


/****************************/
/* OrcadiaLabs_CommonStyles */
/**********************************************************************************************/
/* Styles for common use amongst multiple components.                                         */
/* This object supports the following, general purpose, style objects:                        */
/*   landingFrame     : absolutely defines the space occupied by the woods image.             */
/*                      This style defines the entire space so should be used by only the     */
/*                      background layer to avoid overlays.                                   */
/*   landingFrameHawk : absolutely defines the space occupied by just the hawk of the woods   */
/*                      image.                                                                */
/*                      Though this style defines just the space occupied by the hawk, it     */
/*                      does so in relative terms related to (landingFrame.)  Thus this style */
/*                      can be used as an overlay style without obstructing.                  */
/**********************************************************************************************/
export let OrcadiaLabs_CommonStyles = {

             landingFrame : {
               position : 'fixed' ,
               overflow : 'hidden',
               
               // all widths:
               width     : '' + OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageWidthVW  + 'vw' ,
               minWidth  : OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageWidthMin             ,
               maxWidth  : OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageWidthMax             ,
            
               // all heights:
               height    : '' + OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightVW + 'vw',
               minHeight : OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightMin           ,
               maxHeight : OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightMax           ,
            
               bottom   : 0, 
               left     : 0,
             }, /* landingFrame */

             
             landingFrameHawk : {
               position : 'fixed' ,
               overflow : 'hidden',
               
               // all widths:
               width     : '' + OrcadiaLabs_Constants.BackgroundConstants.foregroundImageHawk.foregroundImageHawkWidthVW  + 'vw' ,
               minWidth  : OrcadiaLabs_Constants.BackgroundConstants.foregroundImageHawk.foregroundImageHawkWidthMin             ,
               maxWidth  : OrcadiaLabs_Constants.BackgroundConstants.foregroundImageHawk.foregroundImageHawkWidthMax             ,
            
               // all heights:
               height    : '' + OrcadiaLabs_Constants.BackgroundConstants.foregroundImageHawk.foregroundImageHawkHeightVW + 'vw',
               minHeight : OrcadiaLabs_Constants.BackgroundConstants.foregroundImageHawk.foregroundImageHawkHeightMin           ,
               maxHeight : OrcadiaLabs_Constants.BackgroundConstants.foregroundImageHawk.foregroundImageHawkHeightMax           ,
            
               bottom   : 0, 
               left     : 0,
             }, /* landingFrameHawk */
             
           }; /* OrcadiaLabs_CommonStyles */



