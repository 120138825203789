/*************************/
/* FootnoteContentAsimov */
/**********************************************************************************************/
/* Generate content of footnote for Isaac Asimov                                              */
/* Copyright (C) 2019 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React,
       {Text}                         from 'react'                                 ; 
import {OrcadiaLabs_Constants       } from '../../../constants/constants.js'       ;
import {ConduitNames                } from '../../conduit/ConduitManager.js'       ;
import ImageQuote                     from '../../../assets/images/quotes.png'     ; 
import ImageBrian                     from '../../../assets/images/lifeofbrian.png';  
import {FootnoteXit                 } from './FootnoteContent.js'                  ;
import {FootnoteDialog              } from '../Footnote.js'                        ;
import {FootnoteConstants           } from '../FootnoteConstants.js'               ;
import ImageAsimov                    from '../../../assets/images/asimovPic.png'  ; 


export class FootnoteContentAsimov extends React.Component {
  render() {
    var footnoteProps = {
      // every footnote needs to know its own conduit for communication:
      footnote : {
        footnoteConduit : ConduitNames.footnoteAsimovConduitName,
      }, /* footnote */
      
      // both footnote quote marks need to know the quote image to display:
      quote    : {
        src             : (ImageQuote),
        alt             : ("quote"),
      }, /* quote */
      
      // the Isaac Asimov image in the footnote:
      asimov : {
        src             : (ImageAsimov),
        alt             : ("Isaac Asimov"),
      }, /* asimov */
    }; /* footnoteProps */
    
    var textWidth  = 14 * 48;
    var quoteWidth = 60;
    
    // Isaac Asimov image is 420 X 499 pixels
    var asimovWidth = 105;
    var asimovHeight = 125;
    
    var footnoteStyles = {
      portraitContainer : {
        display       : 'flex',
        flexDirection : 'column',
        padding       : 8,
        width         : (asimovWidth + FootnoteConstants.xitButton.width),
      }, /* portraitContainer */
      subTextMarks    : {
        display       : 'flex',
        flexDirection : 'column',
        paddingTop    : 16,
        paddingBottom : 16,
      }, /* subText */
      subText    : {
        display       : 'flex',
        flexDirection : 'column',
        marginLeft    : (quoteWidth+4),
        marginRight   : (quoteWidth+4),
      }, /* subText */
      text : {
        fontSize       : 14,
        marginTop      : 0,
        marginBottom   : 6,
        width          : '100%',
      }, /* text */
      attribution : {
        fontSize       : 16,
        marginTop      : 2,
        marginBottom   : 2,
        width          : '100%',
      }, /* text */
      asimov : {
        width    : (asimovWidth ),
        height   : (asimovHeight),
        opacity  : 1,
      }, /* brian */
      quoteLeft : {
        position : 'absolute',
        alignSelf : 'flex-start',
        width    : (quoteWidth),
        height   : (quoteWidth * 395 / 492),
        opacity  : 0.5,
      }, /* quoteLeft */
      quoteRight : {
        position    : 'absolute',
        alignSelf   : 'flex-end',
        marginRight : (FootnoteConstants.xitButton.width), // make room for exit button
        width       : (quoteWidth),
        height      : (quoteWidth * 395 / 492),
        opacity     : 0.5,
        transform : 'rotate(180deg)'
      }, /* quoteRight */
    }; /* footnoteStyles */

    return (
      <FootnoteDialog {...footnoteProps.footnote}>
        <div style = {footnoteStyles.portraitContainer}>
          <img {...(footnoteProps.asimov)} style = {footnoteStyles.asimov}/>
          <FootnoteXit {...(footnoteProps.footnote)} />
        </div>
        <div style = {footnoteStyles.subTextMarks}>
          <img {...(footnoteProps.quote)} style   = {footnoteStyles.quoteLeft}/>
          <img {...(footnoteProps.quote)} style   = {footnoteStyles.quoteRight}/>
          <div style = {footnoteStyles.subText}>
            <p style={footnoteStyles.text}>Education isn't something you can finish</p>
            <p style={footnoteStyles.text}>Those people who think they know everything are a great annoyance to those of us who do.</p>
            <p style={footnoteStyles.text}>The most exciting phrase to hear in science, the one that heralds new discoveries, is not "Eureka", but "That's funny"</p>
            <p style={footnoteStyles.attribution}>Isaac Asimov (1920 - 1992, professor, author)</p>
          </div>
        </div>
      </FootnoteDialog>   
    ); /*return*/
  }; /* render */
}; /* FootnoteContentAsimov */


