/********************/
/* Orcadialabs.com */
/**********************************************************************************************/
/* Orcadia Labs homepage                                                                      */
/*                                                                                            */    
/* Copyright (C) 2019 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';


// Orcadia Labs - 
import {FootnoteContentAll       } from './library/footnote/content/FootnoteContentAll.js';
import {OrcadiaLabsHomePage      } from './content/home/OrcadiaLabsHomePage.js'           ;
import {OrcadiaLabsAboutPage     } from './content/about/OrcadiaLabsAboutPage.js'         ;
import {OrcadiaLabsPortfolioPage } from './content/portfolio/OrcadiaLabsPortfolioPage.js' ;
import {OrcadiaLabsVigorPage     } from './content/vigor/OrcadiaLabsVigorPage.js'         ;
import {OrcadiaLabsInvigoratePage} from './content/vigor/OrcadiaLabsInvigoratePage.js'    ;
import {OrcadiaLabsInAnyEventPage} from './content/inanyevent/inanyevent.js'              ;
import {InAnyEventPermission     } from './content/inanyevent/inanyeventPermission.js'    ;
import {InAnyEventPrivacy        } from './content/inanyevent/inanyeventPrivacy.js'       ;
import {OrcadiaLabs404           } from './OrcadiaLabs404.js'                             ;
import {OrcadiaLabs_Constants    } from './constants/constants.js'                        ;    
import {OrcadiaLabsBackground    } from './content/OrcadiaLabsBackground.js'              ;
import {OrcadiaLabsForeground    ,
        OrcadiaLabsSpacerTop     ,
        OrcadiaLabsSpacerBottom  }  from './content/OrcadiaLabsForeground.js'             ;





// OrcadiaLabs.com SPA page
export class App extends React.Component { 

  // Route to specific pages
  // To link to a specific page in the route, use ie:
  //   <Link to="/about">About Us </Link> 
  render() {
   
    // As the main app renders, change the title (Chrome tab)
    document.title = OrcadiaLabs_Constants.OrcadiaLabs.OrcadiaLabsTitle;
    
    return (
      <div className="App" >
        <Switch>
          <Route path = "/inanyevent/masterevents.json"     strict />
          <Route path = "/assets"                           strict />
          <Route path = "/vigor"                            component={OrcadiaLabsVigorPage     } exact />
          <Route path = "/invigorate"                       component={OrcadiaLabsInvigoratePage} exact />
          
          <Route path = "/inanyevent"                       component={OrcadiaLabsInAnyEventPage} exact />
          <Route path = "/inanyevent/inanyevent_permission" component={InAnyEventPermission     } exact />
          <Route path = "/inanyevent/inanyevent_privacy"    component={InAnyEventPrivacy        } exact />
          
          <Route path = "/"                 exact>
            <OrcadiaLabsBackground/>
              <OrcadiaLabsSpacerTop/>
              <OrcadiaLabsHomePage/>
              <OrcadiaLabsSpacerBottom/>
            <OrcadiaLabsForeground/>
          </Route>
          
          <Route path = "/about"            exact>
            <OrcadiaLabsBackground/>
              <OrcadiaLabsSpacerTop/>
              <OrcadiaLabsAboutPage/>
              <OrcadiaLabsSpacerBottom/>
            <OrcadiaLabsForeground/>
          </Route>
          
          <Route path = "/portfolio"        exact>
            <OrcadiaLabsBackground/>
              <OrcadiaLabsSpacerTop/>
              <OrcadiaLabsPortfolioPage/>
              <FootnoteContentAll/>
              <OrcadiaLabsSpacerBottom/>
            <OrcadiaLabsForeground/>
          </Route>
          
          <Route component={OrcadiaLabs404} exact />
        </Switch>
      </div>
    ); /*return*/
  } /* render */
  
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* App */



