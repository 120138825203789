/****************************/
/* OrcadiaLabsBackground.js */
/**********************************************************************************************/
/* The background for the new orcadialabs.com website.                                        */
/*                                                                                            */    
/* Copyright (C) 2021 - 2023 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React            from 'react'                                      ; 
        
import {OrcadiaLabs_Constants   ,
        OrcadiaLabs_CommonStyles}  from '../constants/constants.js'                  ;
import {OrcadiaLabsJsxIF        }  from '../components/OrcadiaLabs_JSXTrix.js'       ;
import {InAnyEvent_Conduit      }  from '../components/InAnyEvent_Conduit.js'        ;
import hawkBGLayer0                from '../assets/images/UX/hawklayer0.png'         ;
import hawkBGLayer1                from '../assets/images/UX/hawklayer1.png'         ;
import hawkBGLayer2                from '../assets/images/UX/hawklayer2.png'         ;
import hawkBGLayer34               from '../assets/images/UX/hawklayer3+4.png'       ;
import hawkBGLayerBlur             from '../assets/images/UX/hawklayerBlurW10.png'   ;
import hawkBGBullseye              from '../assets/images/UX/spark/bullseye.png'     ;
import hawkShimmerLeft             from '../assets/images/UX/spark/shimmer-left.png' ;
import hawkShimmerRight            from '../assets/images/UX/spark/shimmer-right.png';



/**********************/
/* AnimatorConduitProp */
/**********************************************************************************************/
/* The animator is the animation between layers of the background                             */
/* The sole role of this conduit is the one-time invocation of the start method to start the  */
/* animation.                                                                                 */
/**********************************************************************************************/
const AnimatorConduitProp = "BackgroundAnimatorConduit";


/***********/
/* Shimmer */
/**********************************************************************************************/
/* This Shimmer component is what controls the shimmer animation.                             */
/*                                                                                            */
/*   (1) for the first render, this Component will not draw anything but initialize the       */
/*       shimmer for subsequent renderings.                                                   */
/*   (2) for subsequent renders, this Component will move to the first/next step and draw     */
/*       the Shimmer.                                                                         */
/*       Note that the (next) step may differ depending upon the Shimmer's direction.         */
/*   (3) When the Shimmer is done, this Component will wait a bit and then start the next     */
/*       Shimmer at Step 1.                                                                   */
/*                                                                                            */
/* Dramatically:                                                                              */
/*   (1) the height of the Shimmer is the full height of the landing image                    */
/*   (2) the Shimmer will start one shimmer-width's distance left of the left side of the     */
/*       background image to simulate that it's shimmering the entire image equally           */
/*   (3) the Shimmer ends one shimmer-width's distance right of the right side of the         */
/*       background image for the same reason                                                 */
/**********************************************************************************************/
class Shimmer extends React.Component {

  /********************/
  /* incrementShimmer */
  /********************************************************************************************/
  /* Proceed to the next Shimmer Step 1,2,3.                                                  */
  /********************************************************************************************/
  incrementShimmer() {
    // go to a specific state after a short delay:
    var gotoLeft = (state) => {
      setTimeout(() => {this.setState({shimmerLeft : state})},
                 10);
    }; /* gotoLeft */
    
    // if this is the very first render, then reset the Shimmer state with no action:
    if (!this.state) {
      gotoLeft(0 - OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightMax);
      return;
    } /*if*/
    
    // if this is the last render for this Shimmer, then reset state:
    // restart the next Shimmer after a short delay to be not so intrusive:
    // delay for between a constant number of nilliseconds
    var minFrameDelay = OrcadiaLabs_Constants.BackgroundConstants.shimmerAnimation.frameDelay.minimumFrameDelay;
    var maxFrameDelay = OrcadiaLabs_Constants.BackgroundConstants.shimmerAnimation.frameDelay.maximumFrameDelay;
    var thisFrameDelay = (Math.random() * (maxFrameDelay - minFrameDelay)) + minFrameDelay;
    
    if (this.state.shimmerLeft >= OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageWidthMax) {
      setTimeout(() => {gotoLeft(0 - OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightMax)},
                        thisFrameDelay);
      return;
    } /*if*/
    
    // this is an intermediate render, proceed to the next step after a delay:
    gotoLeft(this.state.shimmerLeft + 10);
  }; /* incrementShimmer */
  

  /******************/
  /* startAnimation */
  /********************************************************************************************/
  /* This Shimmer animator is been instructed to start the animation, so do so.               */
  /* This animation starts when it is ping()ed.                                               */
  /********************************************************************************************/
  startAnimation() {
    this.incrementShimmer();
  }; /* startAnimation */
  
  ping() {
    this.startAnimation();
  }; /* ping */

  /**********.
  /* render */
  /********************************************************************************************/
  /* Draw the next step of the Shimmer animation.                                             */
  /* The next step of the animation may be a reset.                                           */
  /********************************************************************************************/
  render() {
    // look for valid excuses to render nothing, no state
    if ((!this.state) || (0 == this.state.shimmerLeft)) {
      this.incrementShimmer();
      return(<></>);
    } /*if*/
    
    // we have a shimmer state, render the correspondingly-appropriate shimmer image:
    var shimmerStyles = {
          width    : (OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightMax),
          height   : (OrcadiaLabs_Constants.BackgroundConstants.backgroundImage.backgroundImageHeightMax),
          position : 'absolute',
          left     : (this.state.shimmerLeft),
          bottom   : 0,
          zIndex   : OrcadiaLabs_Constants.BackgroundConstants.zValues.animation,
          opacity  : 0.4,
        }; /* shimmerStyles */
  
    // go to the next step and rerender this one:
    this.incrementShimmer();
    return (<img style={shimmerStyles} src={hawkShimmerLeft} alt="Orcadia Labs animation"/>);
  }; /* render */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Remember the conduit of communication between this Bullseye and the background.          */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.shimmerConduit = props[AnimatorConduitProp];
    this.shimmerConduit.registerListener(this);
  }; /* CTOR */
}; /* Shimmer */


// ------------------------------------------------------------------------------------------ //
// Stage Loading                                                                              //
//                                                                                            //
// The orcadialabs.com <OrcadiaLabsBackground> component needs to load it's several layers of //
// the itself in stages.  By loading it in stages, the viewer does not see any disruption in  //
// the displayed image, all other layers being loaded behind it.  The component always looks  //
// clean.  After a layer/image is loaded, it causes the next one down/below to load           //
// itself.  The owner of of <StageLoadedImage> component starts the cascade but each layer    //
// continues the cascade into tha topmost layer below it.  Each layer will notify its child   //
// layer using a <InAnyEvent_Conduit> component.  In order to manage the cascade, all         //
// children are rendered with a <StageLoadedImageWrapper> component.  This hierarchical layer //
// removes from every layer the need to manage it's own part of the cascade.                  //
//                                                                                            //
// This cascade must work with the React component lifecycle.  One layer will render its      //
// layer contents but will not render its children components until afterwards, after it is   //
// notified to do so by its parent.                                                           //
//                                                                                            //
// 23.03.08.1510 - The rules that Stage Loading has used to load stages has an additional     //
//                 step.  Before this date, I saw on several browser/platform combinations    //
//                 that thee images were being rendered as they were loading; we could see    //
//                 the images slowly appear from top to bottom.  This effect made the stage   //
//                 loading process very ugly.  At this time, I implemented a new rule for     //
//                 rendering stage-loaded image.  An image is first loaded at opacity zero.   //
//                 Then, as it is loading it doesn't show this ugly behavior.  When the       //
//                 is done loading, this class changes the opacity of the image to view.      //
//                 Stage loading looks better now.                                            //
// ------------------------------------------------------------------------------------------ //


/********************************************/
/* StageLoadedImageContentWrapperBackground */
/**********************************************************************************************/
/* A responsive component to represent just the background for a                              */
/* <StageLoadedImageContentWrapper>.  This background component, unlike as indicated by a     */
/* backgroundImage prop on a <View> supports a callback when loaded.                          */
/*                                                                                            */
/* Note: (23.03.04.0808)                                                                      */
/* This <StageLoadedImageContentWrapperBackground> will go through two stages of rendering:   */
/* (1) when still being setup or creating a space for it, before the time to render it.       */
/*     * this class' render method will retun an empty component.                             */
/*     * Stage (2) will be invoked presently.                                                 */
/* (2) when it is time to render an actual background image.  Two situations exist.           */
/*   (2A) the <StageLoadedImageContentWrapperBackground> actually has a background image to   */
/*        display as declared on the <StageLoadedImage> property                              */
/*        [OrcadiaLabsBackground.BackgroundImagePropsProp].                                   */
/*        * this class' render() method will return the background image to display.          */
/*   (2B) the <StageLoadedImageContentWrapperBackground> has no declared backround in which   */
/*        it renders fully transparent.                                                       */
/*        * this class' render() method will return no background.                            */
/* Without regard to (2A) or (2B) when done rendering, this class must indicate to            */
/* <StageLoadedImageContentWrapperContents> that it is time for it to render contents.        */
/* It performs this action by:                                                                */
/*   (2A) using <img> onLoad(): requesting that the contents component rerender itself        */
/*        (with contents).                                                                    */
/*   (2B) using componentDidUpdate() to request the contents component rerender itself.       */
/*        The React lifecycle does not permint one component's rerender during another's.     */
/**********************************************************************************************/
class StageLoadedImageContentWrapperBackground extends React.Component {

  static StageLoadedImageContentWrapperBackgroundOnLoadCallbackProp 
             = 'StageLoadedImageContentWrapperBackgroundOnLoadCallbackProp';
  static StageLoadedImageContentWrapperBackgroundConduitProp 
             = 'StageLoadedImageContentWrapperBackgroundConduitProp';
  
  
  ///////////
  // state //
  /***********************/
  /* initState          */
  /* viewBackground     */
  /* isToViewBackground */
  /********************************************************************************************/
  /* A <StageLoadedImageContentWrapperBackground> will be either rendered or not.             */
  /* A background will be rendered if there is one and only upon demand.                      */
  /********************************************************************************************/
  initState() {
    this.state = {viewBackground : false};
  }; /* initState */
  
  viewBackground () {
    this.setState({viewBackground : true});
  }; /* viewBackground */
  
  isToViewBackground() {
    return ((this.state.viewBackground));
  }; /* isToViewBackground */


  /******************/
  /* createImageRef */
  /* getImageRef    */
  /********************************************************************************************/
  /* Remember a reference to the image object for manipulation.                               */
  /********************************************************************************************/
  createImageRef() { 
    this.imageRef = React.createRef();
  }; /* setImageRef */
  
  getImageRef() {
    return (this.imageRef);
  }; /* getImageRef */
  

  /*****************************/
  /* markBackgroundWrapperDone */
  /********************************************************************************************/
  /* Without regard as to whether (2A) or (2B), see above, this class is done loading and     */
  /* displaying the background image.  Mark this fact and proceed to the next step.           */
  /* 23.03.08.1130 - View class comment of this date for a description of relevant changes.   */
  /********************************************************************************************/
  markBackgroundWrapperDone() {
    var imageRef = this.getImageRef()
    
    // if there is a valid image referent, then process it:
    if ((imageRef) && (imageRef.current)) {
      var opacityValue1 = imageRef.current.style.opacity;
      var opacityValue2 = imageRef.current.style[OrcadiaLabsBackground.BackgroundTargetOpacityStyleKey];
      
      imageRef.current.style.opacity = imageRef.current.style[OrcadiaLabsBackground.BackgroundTargetOpacityStyleKey];
      var opacityValue3 = imageRef.current.style.opacity;
      
    } /*if*/
    
    if (this.props[StageLoadedImageContentWrapperBackground
                  .StageLoadedImageContentWrapperBackgroundOnLoadCallbackProp]) {
      this.props[StageLoadedImageContentWrapperBackground
                .StageLoadedImageContentWrapperBackgroundOnLoadCallbackProp]();
    } /*if*/
  }; /* markBackgroundWrapperDone */
  

  /*****************************/
  /* isBackgroundImageSupplied */
  /********************************************************************************************/
  /* A <StageLoadedImageContentWrapperBackground> does not require an actual background image */
  /* to display.                                                                              */
  /* @return whether a background image is available.                                         */
  /********************************************************************************************/
  isBackgroundImageSupplied() {
    return (this.props[OrcadiaLabsBackground.BackgroundImagePropsProp]);
  }; /* isBackgroundImageSupplied */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  /* When requested to do so, render the background image like an image.                      */
  /********************************************************************************************/
  render() {
    // if the background image has not yet been loaded, render just a placeholder on condition, do not render a background
    if (!this.isToViewBackground()) {
      return (<></>);                                                                   /* 1  */
    } /*if*/
    
    // if there is no background imag
    // imageOnLoadProp - when an image is loaded, update this object.
    // if there is no background image to load, just proceed with the next step:
    var imageOnLoadProp = {
      'onLoad' : (() => {this.markBackgroundWrapperDone();})
    }; /* imageOnLoadProp */
    
    // render the background image, its onLoad callback and the empty View for contents.
    // this owner <StageLoadedImageContentWrapper> may or may not have a background image.
    // if it does not have a background image, do not render one here.
    // However, we still need the callback to render contents:
    if (this.isBackgroundImageSupplied()) {                                             /* 2A */
      return(<img {...this.props[OrcadiaLabsBackground.BackgroundImagePropsProp]} 
                  {...imageOnLoadProp}
                  ref={this.imageRef}
             />);
    } /*if*/
    
    // this <StageLoadedImageContentWrapperBackground> has no background image to display.
    return(null);                                                                       /* 2B */
  }; /* render */
  

  /**********************/
  /* componentDidUpdate */
  /********************************************************************************************/
  /* After a background image should render but when this component has none.              2B */
  /********************************************************************************************/
  componentDidUpdate() {
    if (!this.isBackgroundImageSupplied()) {
      this.markBackgroundWrapperDone();
//      if (!this.props[OrcadiaLabsBackground.BackgroundImagePropsProp]) {
//        this.props[StageLoadedImageContentWrapperBackground.
//                   StageLoadedImageContentWrapperBackgroundOnLoadCallbackProp]();       /* 2B */
//      } /*if*/
    } /*if*/
  }; /* componentDidUpdate */
  

  /********/
  /* ping */
  /********************************************************************************************/
  /* Receive a request to render this <StageLoadedImageContentWrapperBackground>.             */
  /********************************************************************************************/
  ping() {
    this.viewBackground();
  }; /* ping */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.initState();
    this.createImageRef();
    this.props[ StageLoadedImageContentWrapperBackground
               .StageLoadedImageContentWrapperBackgroundConduitProp
              ].registerListener(this);
  }; /* CTOR */
  
}; /* StageLoadedImageContentWrapperBackground */


/******************************************/
/* StageLoadedImageContentWrapperContents */
/**********************************************************************************************/
/* A responsive component to represent the contents of a <StageLoadedImageContentWrapper>.    */
/**********************************************************************************************/
class StageLoadedImageContentWrapperContents extends React.Component {

  static StageLoadedImageContentWrapperContentsConduitProp 
         = 'StageLoadedImageContentWrapperContentsConduitProp';
  
  
  ///////////
  // state //
  /********************/
  /* initState        */
  /* viewContents     */
  /* isToViewContents */
  /********************************************************************************************/
  /* A <StageLoadedImageContentWrapperContents> will be either rendered or not and upon       */
  /* demand.                                                                                  */
  /********************************************************************************************/
  initState() {
    this.state = {viewContents : false};
  }; /* initState */
  
  viewContents () {
    this.setState({viewContents : true});
  }; /* viewContents */
  
  isToViewContents() {
    return this.state.viewContents;
  }; /* isToViewContents */


  /**********/
  /* render */
  /********************************************************************************************/
  /* When requested to do so, render the contents.                                            */
  /********************************************************************************************/
  render() {
  
    // skip rendering anything until directed to do so or there's nothing to render
    if ((!this.isToViewContents()) || (!this.props.children)) {
      return (<></>);
    } /*if*/
    
    // render the image, its onLoad callback and the empty View for contents.
    return(this.props.children);
  }; /* render */
  

  /********/
  /* ping */
  /********************************************************************************************/
  /* Receive a request to render this <StageLoadedImageContentWrapperBackground>.             */
  /********************************************************************************************/
  ping() {
    this.viewContents();
  }; /* ping */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.initState();
    this.props[ StageLoadedImageContentWrapperContents
               .StageLoadedImageContentWrapperContentsConduitProp
              ].registerListener(this);
  }; /* CTOR */
  
}; /* StageLoadedImageContentWrapperContents */


/**********************************/
/* StageLoadedImageContentWrapper */
/**********************************************************************************************/
/* A <StageLoadedImageContentWrapper> manages the control of its children within the cascade  */
/* rendering of <OrcadiaLabsBackground>.  The children could be a single <StageLoadedImage>   */
/* component but this stage loading architecture does not require it.                         */
/*                                                                                            */
/* A <StageLoadedImageContentWrapper> has two distinct parts.                                 */
/*   (1) a background image whose specifications are provideed in props.                      */
/*   (2) content whose specifications are provided as children.                               */
/* Phases are followed:                                                                       */
/*   (A) generate an empty container for the background image and another empty container     */
/*       for the children contents.                                                           */
/*   (B) when this <StageLoadedImageContentWrapper> is ping()d, render just the background    */
/*       image.  This background image will have an onLoad callback to this object.           */
/*   (C) when this object receives the callback, render all children.                         */
/* Thus, this wrapper provides an interatively rendered <View> that has a background image    */
/* and this background image (unlike common background images) will have its own onLoad()     */
/* callback, just like any other image.                                                       */
/*                                                                                            */
/* This component does not use state.  There is no scenario under which this component will   */
/* require a rerender.  Rather all updates are requested of this class' children.             */
/**********************************************************************************************/
class StageLoadedImageContentWrapper extends React.Component {

  /************/
  // conduits //
  /********************************************************************************************/
  /* The <StageLoadedImageContentWrapper> requires two conduits, for its child                */
  /* <StageLoadedImageContentWrapperBackground> and <StageLoadedImageContentWrapperContents>. */
  /********************************************************************************************/
  initConduits() {
    this.backgroundConduit = new InAnyEvent_Conduit();
    this.contentConduit   = new InAnyEvent_Conduit();
  }; /* initConduits */
  
  getWrapperBackgroundConduit() {
    return (this.backgroundConduit);
  }; /*getWrapperBackgroundConduit  */
  
  getWrapperContentConduit() {
    return (this.contentConduit);
  }; /*getWrapperContentConduit  */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  /* Do not render contents until told to do so.                                              */
  /*                                                                                          */
  /* This classes uses a  use InAnyEvent_Conduit.ping() to request a rerender.  More          */
  /* components than just a <StageLoadedImage> could own this                                 */
  /* <StageLoadedImageContentWrapper>.  For instance, see <OrcadiaLabsBackground>.            */
  /*                                                                                          */
  /* See comment for <StageLoadImageContentWrapper> for full information.                     */
  /********************************************************************************************/
  render() {
        
    // Properties for children of this wrapper:
    let wrapperProperties = {
      backgroundProperties : {
        [StageLoadedImageContentWrapperBackground.StageLoadedImageContentWrapperBackgroundOnLoadCallbackProp]
          : (() => {this.getWrapperContentConduit().pingListeners()}),
        [StageLoadedImageContentWrapperBackground.StageLoadedImageContentWrapperBackgroundConduitProp]
          : (this.getWrapperBackgroundConduit()),
        [OrcadiaLabsBackground.BackgroundImagePropsProp]
          : (this.props[OrcadiaLabsBackground.BackgroundImagePropsProp])
      }, /* backgroundProperties */
      contentProperties : {
        [StageLoadedImageContentWrapperContents.StageLoadedImageContentWrapperContentsConduitProp]
          : (this.getWrapperContentConduit())
      }, /* contentProperties */
    }; /* wrapperProperties */
         
    return(
      <>
        <StageLoadedImageContentWrapperBackground {...wrapperProperties.backgroundProperties}/>
        
        <StageLoadedImageContentWrapperContents   {...wrapperProperties.contentProperties   }>
          {this.props.children}
        </StageLoadedImageContentWrapperContents>
      </>
    ); /*return*/
  }; /* render */
  

  /********/
  /* ping */
  /********************************************************************************************/
  /* Start the rendering of this <StageLoadedImageContentWrapper>.  Render the background     */
  /* component and subsequently the contents component.                                       */
  /* See comment for <StageLoadImageContentWrapper> for full information.                     */
  /********************************************************************************************/
  ping() {
    this.getWrapperBackgroundConduit().pingListeners();
  }; /* ping */


  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Ensure that this <StageLoadedImageContentWrapper> has the appropriate, initialization    */
  /* state.                                                                                   */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.initConduits();
    this.props[StageLoadedImage.StageLoadedImage_ConduitProp].registerListener(this);
  }; /* CTOR */
  
}; /* StageLoadedImageContentWrapper */


/********************/
/* StageLoadedImage */
/**********************************************************************************************/
/* A <StageLoadedImage> represents, though does not include, an image and relating data.      */
/* Its sole child,a  <StageLoadedImageContentWrapper> handles all of the control and          */
/* information that this class requires to stage-load this information.                       */
/**********************************************************************************************/
class StageLoadedImage extends React.Component {

  /*****************************/
  /* StageLoadedImageDelayProp */
  /********************************************************************************************/
  /* An interesting effect that <OrcadiaLabsBackground> supports is delaying the rendering    */
  /* of any background layer.  This number is milliseconds to delay before render.            */
  /********************************************************************************************/
  static StageLoadedImageDelayProp = 'StageLoadedImageDelayProp';
  
  
  /********************************************************************************************/
  /* StageLoadedImage_ConduitProp     - used to know when to start loading image.             */
  /********************************************************************************************/
  static StageLoadedImage_ConduitProp  = "StageLoadedImage_ConduitProp" ;
  
  
  /********************************************************************************************/
  /* Use an (InAnyEvent_Conduit) to communicate between this <StageLoadedImage> and its       */
  /* child <StageLoadedImageContentWrapper>.                                                  */
  /********************************************************************************************/
  initWrapperConduit() {
    this.wrapperConduit = new InAnyEvent_Conduit();
  }; /* initWrapperConduit */
  
  
  getWrapperConduit() {
    return (this.wrapperConduit);
  }; /* getWrapperConduit */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  /* Render an image if told to do so.                                                        */
  /* Call back to the caller upon a load.                                                     */
  /********************************************************************************************/
  render() {
    // Provide the (InAnyEvent_Conduit) of communication between this <StageLoadedImage> 
    // and its child <StageLoadedImageContentWrapper>.
    // This child wrapper requires, also, the background image information property
    // the background properties prop is optional, so ensure that it is not expected.
    var wrapperProps = {
      [StageLoadedImage.StageLoadedImage_ConduitProp ] : this.getWrapperConduit(),
      [OrcadiaLabsBackground.BackgroundImagePropsProp] : undefined,
    }; /* wrapperProps */
    
    // if supplied, tell the wrapper about the declared background image:
    if (this.props[OrcadiaLabsBackground.BackgroundImagePropsProp]) {
      wrapperProps[OrcadiaLabsBackground.BackgroundImagePropsProp] = this.props[OrcadiaLabsBackground.BackgroundImagePropsProp];
    } /*if*/
    
    // otherwise, render the layer 
    // rendering involves rendering the image as provided and the onLoad prop
    return (<>
              <StageLoadedImageContentWrapper {...wrapperProps}>
                {this.props.children}
              </StageLoadedImageContentWrapper>
            </>);   
  }; /* render */
  

  /**********************/
  /* finishDelayedMount         */
  /* componentDidMount  */
  /* componentDidUpdate */
  /********************************************************************************************/
  /* The rendering of this <StageLoadedImage> creates a <StageLoadedImageContentWrapper>      */
  /* that has not yet rendered itself.  This sole child is waiting for direction to render.   */
  /* After its first actual rendering, the <StageLoadedImageContentWrapper> takes control.    */
  /* After its sole rendering and mounting, this class has no more work to do.                */
  /*                                                                                          */
  /* If a delay was indicated for this <StageLoadedImage>, delay before proceeding to the     */
  /* next layer (wrapper.)                                                                    */
  /********************************************************************************************/
  /* componentDidUpdate() {};  */
    
  finishDelayedMount() {
    this.getWrapperConduit().pingListeners();
  }; /* finishDelayedMount */
  
  
  componentDidMount () {  
    var specifiedDelay = 0;
    if (this.props[StageLoadedImage.StageLoadedImageDelayProp]) {
      specifiedDelay = this.props[StageLoadedImage.StageLoadedImageDelayProp];
    } /*if*/
    
    // delay asynchronously:
    if (specifiedDelay) {
      setTimeout(() => {this.finishDelayedMount()}, specifiedDelay);
    } /*if*/
    else {
      this.finishDelayedMount();
    } /*else*/
    
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.initWrapperConduit();
  }; /* CTOR */
  
}; /* StageLoadedImage */


/*************************/
/* OrcadiaLabsBackground */
/**********************************************************************************************/
/* A component which renders and manages everything associated with the SPA background.       */
/* The SPA OrcadiaLabsBackground acts as the common background for all SPA pages.             */
/*                                                                                            */
/*  Review of layers from the top down (4 layers:)                                            */
/*    1: foreground: fully opaque image of trees but with much of the darker greens erased to */
/*                   show the animation.                                                      */
/*    2: border    : fully opaque image which has a large portion of the center erased        */
/*    3: midground : partially opaque image layer of the full background image                */
/*    4: background: fully opaque image layer of the every part of the image                  */
/*  Then, by animating between layers 3 and 4, I can see it through the erased                */
/*  parts of layer 1, except for the border parts of layer 2.  Furthermore, by animating      */
/*  behind the partially opaque layer, I can show the animation without blocking content for  */
/*  a more realistic/interesting effect.                                                      */
/*                                                                                            */
/**********************************************************************************************/
export class OrcadiaLabsBackground extends React.Component {


  /****************************/
  /* BackgroundImagePropsProp */
  /********************************************************************************************/
  /* Every <OrcadiaLabsBackground> object requires properties about which image to display    */
  /* as the background image.  To keep these background image properties separate, they will  */
  /* all be passed under a (BackgroundImagePropsProp) property.                               */
  /********************************************************************************************/
  static BackgroundImagePropsProp = "BackgroundImagePropsProp";


  /***********************************/
  /* BackgroundTargetOpacityStyleKey */
  /********************************************************************************************/
  /* This style key represents the desired opacity of a stage-loaded layer.                   */
  /* 23.03.08.1130 - View class comment of this date for a description of relevant changes.   */
  /********************************************************************************************/
  static BackgroundTargetOpacityStyleKey = "aBackgroundTargetOpacityStyleKey";
  
  
  /************************/
  /* createAnimatorConduit */
  /* getAnimatorConduit    */
  /********************************************************************************************/
  /* The animation between layers of the background.                                          */
  /* The animator consists of a conduit of communication.                                     */
  /********************************************************************************************/
  createAnimatorConduit() {
    this.animationConduit = new InAnyEvent_Conduit();
  }; /* createAnimatorConduit */
  
  
  getAnimatorConduit() {
    return this.animationConduit;
  }; /* getAnimatorConduit */


  /*******************************************/
  /* createBackgroundWrapperContainerConduit */
  /* getBackgroundWrapperContainerConduit    */
  /********************************************************************************************/
  /* The animation between layers of the background.                                          */
  /* The animator consists of a conduit of communication.                                     */
  /********************************************************************************************/
  createBackgroundWrapperContainerConduit() {
    this.backgroundConduit = new InAnyEvent_Conduit();
  }; /* createBackgroundWrapperContainerConduit */
  
  
  getBackgroundWrapperContainerConduit() {
    return this.backgroundConduit;
  }; /* getBackgroundWrapperContainerConduit */


  /**********/
  /* render */
  /********************************************************************************************/
  /* Orcadia Labs' render includes:                                                           */
  /*   (1) all images of the woods                                                            */
  /*   (2) the hawk animation.                                                                */
  /********************************************************************************************/
  render() {
    
    // () define exactly where the hawk animation is to occur:
    var SPABackgroundStyles = {
          position        : 'fixed',
          top             : 0,
          right           : 0,
          bottom          : 0,
          left            : 0,
          backgroundColor : (OrcadiaLabs_Constants.colors.greens.darkerGreen), 
        }; /* SPABackgroundStyles */

    // draw the SPA background
    // the images differ in content
    // the only style differences is that image 3 is partially opaque
    // this makes the animation effective, it takes advantage
    //   of Orcadia Labs' midground animation concept
    //
    // property [OrcadiaLabsBackground.BackgroundTargetOpacityStyleKey] gives the
    // value of opacity for the layer that is desired after rendering.  
    // For more information, see class comment of this date 23.03.08.1510
    var backgroundStyles = {
          backgroundLayer3 : {
            position : 'absolute',
            bottom   : 0         , 
            left     : 0         ,
            width    : '100%'    ,
            height   : '100%'    ,
            opacity  : '0'       ,
            [OrcadiaLabsBackground.BackgroundTargetOpacityStyleKey]
                     : '0.9'
          }, /* backgroundLayer3 */
  
          backgroundLayer124 : {
            position : 'absolute',
            bottom   : 0         , 
            left     : 0         ,
            width    : '100%'    ,
            height   : '100%'    ,
            opacity  : '0'       ,
            [OrcadiaLabsBackground.BackgroundTargetOpacityStyleKey]
                     : '1.0'     ,
          }, /* backgroundLayer124 */
        }; /* backgroundStyles */   
        
   // each layer has its own specific properties:
   var backgroundProperties = [
     { /* 0 */
       [OrcadiaLabsBackground.BackgroundImagePropsProp] : {
          style : backgroundStyles.backgroundLayer124,
          src   : hawkBGLayer0                       ,
          alt   : "OrcadiaLabs.com home"             ,
       },
     },
     { /* 1 */
       [OrcadiaLabsBackground.BackgroundImagePropsProp] : {
         style : backgroundStyles.backgroundLayer124,
         src   : hawkBGLayer1                       ,
         alt   : "OrcadiaLabs.com home"             ,
       },
     },
     
     /* 2 */
     /* 23.03.08.1100 - Curently leave off layer 2.   */
     /* Later 2 contains a fade frame on the outside  */
     /* of the image.  During staged load, this frame */
     /* becomes visible for a few milliseconds and is */
     /* not pretty.                                   */
     //[OrcadiaLabsBackground.BackgroundImagePropsProp] : {
     //  style : backgroundStyles.backgroundLayer124,
     //  src   : hawkBGLayer2                       ,
     //  alt   : "OrcadiaLabs.com home" 
     //},
     // },
     
     { /* 3 */
       [OrcadiaLabsBackground.BackgroundImagePropsProp] : {
         style : backgroundStyles.backgroundLayer3,
         src   : hawkBGLayer34                    ,
         alt   : "OrcadiaLabs.com home"           ,
       },
     },
     { /* 4 */
       [OrcadiaLabsBackground.BackgroundImagePropsProp] : {
         style : backgroundStyles.backgroundLayer124,
         src   : hawkBGLayer34                      ,
         alt  : "OrcadiaLabs.com home"              ,
       },
       
       // delay rendering this next image so that the user can enjoy the
       // previous (blurred) one
       [StageLoadedImage.StageLoadedImageDelayProp] : 3000                              ,
     },
     { /* 5 -- the blurred image */
       [OrcadiaLabsBackground.BackgroundImagePropsProp] : {
         style : backgroundStyles.backgroundLayer124,
         src   : hawkBGLayerBlur                    ,
         alt   : "OrcadiaLabs.com home"             ,         
       },
     },
   ]; /* backgroundProperties */

   // the (shimmerProps) is how this <OrcadiaLabsBackground> communicates with its child <Shimmer>. 
   var shimmerProps = {
     [AnimatorConduitProp] : (this.getAnimatorConduit()),
   }; /* shimmerProps */
   
   var backgroundWrapperContainerProps = {
     [StageLoadedImage.StageLoadedImage_ConduitProp] : (this.getBackgroundWrapperContainerConduit())
   }; /* backgroundWrapperContainerProps */
   
   // background
   // create all five known layers
   // * each starts the next after rendering
   // * after the last layer, start the <Shimmer> animation.
   return(
      <div id='orcadialabs-landing'     style={OrcadiaLabs_CommonStyles.landingFrame}>
        <div style={SPABackgroundStyles}/>
        <StageLoadedImageContentWrapper {...backgroundWrapperContainerProps}>
          <StageLoadedImage           {... backgroundProperties[5]}  >
            <StageLoadedImage         {... backgroundProperties[4]}  >
              <StageLoadedImage       {... backgroundProperties[3]}  >
                  <StageLoadedImage   {... backgroundProperties[1]}  >
                    <StageLoadedImage {... backgroundProperties[0]} />
                  </StageLoadedImage>
              </StageLoadedImage>
            </StageLoadedImage>
          </StageLoadedImage>
        </StageLoadedImageContentWrapper>
      </div>
   ); /*return*/
  }; /* render */
  

  /*********************/
  /* componentDidMount */
  /********************************************************************************************/
  /* Start the staged load cascade.                                                           */
  /* The staged load starts with the bottom-most layer and self-mounts after that.            */
  /********************************************************************************************/
  componentDidMount() {
    this.getBackgroundWrapperContainerConduit().pingListeners();
  }; /* ComponentDidMount */

  
  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    
    // Create the conduit of communication between this component and the animation:
    // Direct order of rendering
    this.createAnimatorConduit  ();
    this.createBackgroundWrapperContainerConduit();
  }; /* CTOR */

}; /* OrcadiaLabsBackground */



