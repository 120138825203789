/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* Constants                                                                                  */
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC, all rights reserved.                           */
/**********************************************************************************************/

/************************/
/* FourSeasonsConstants */
/**********************************************************************************************/
export const FourSeasonsConstants = {
  footnote : {
  }, /* footnote */
  
  experience : {
    spaceForTitles :  32,
    defaultWidth   : 512,
    maxWidth       : '100%',
    defaultHeight  : 256,
    preferredWith  : 96,      // declared as an integer but really is a percentage.
    
    // (scrollBoxPadding) styles how the experience scroll text lays out within its container.
    scrollBoxPadding : {
      top         : 24,
      right       :  0,
      bottom      :  4,
      left        : 16,
    },
  }, /* experience */
  
  // (scape) defines values to render the landscape animations.
  scape : {
    rawImage : {
      width  : 1010,
      height :  246,
    }, /* rawImage */
    scaledImage : {
      width  :  300,
      height :   73,
    }, /* scaledImage */
    bottomExtension : 20,  // distance below the scroll to place the scape animation
  },  /* scape */
  
  // (xItNumber) specifically identifies each of the four exit buttons by (id)
  xItNumber : {
    xItSpring : 'x1',
    xItSummer : 'x2',
    xItAutumn : 'x3',
    xItWinter : 'x4',
    xItNone   : 'x0',
  }, /* xItNumber */

  // (title) introduces all constants used for titles.  
  // Other components rely on title constants for spacing and positioning:
  title : {
    horizontalOffset : 8,             /* space on left-hand side for title image */
    dimensions       : {              // size of title image (pixels)
      horizontal       : 96,
      vertical         : 96,
    },
  }, /* title */
  
  // (seasonalColor) holds the acual colors of each season
  // These colors are representational and none of them over-saturated.
  seasonalColor : {
    spring : '#5a9d2d',
    summer : '#235818',
    autumn : '#a37c19',
    winter : '#dfe4e9',
  }, /* seasonalColor */
  
  // (lightenedColor) holds the lightened colors of each season
  lightenedColor : {
    spring : '#c9dfb9',
    summer : '#b6c8b2',
    autumn : '#e1d4b3',
    winter : '#f5f6f8',
  }, /* lightenedColor */
  
  // (seasonalRGB) is like (sesonalColor) but splits the colors into RGB.
  // This object defines the R, G and B's for both the saturated and the lightened colors
  seasonalRGB : {
    saturated : {
      spring : {'R' : 0x5a, 'G' : 0x9d, 'B' : 0x2d },
      summer : {'R' : 0x23, 'G' : 0x58, 'B' : 0x18 },
      autumn : {'R' : 0xa3, 'G' : 0x7c, 'B' : 0x19 },
      winter : {'R' : 0xdf, 'G' : 0xe4, 'B' : 0xe9 },
    }, /* saturated */
    lightened : {
      spring : {'R' : 0xc9, 'G' : 0xdf, 'B' : 0xb9 },
      summer : {'R' : 0xb6, 'G' : 0xc8, 'B' : 0xb2 },
      autumn : {'R' : 0xe1, 'G' : 0xd4, 'B' : 0xb3 },
      winter : {'R' : 0xf5, 'G' : 0xf6, 'B' : 0xf8 },
    }, /* lightened */
  }, /* seasonalRGB */
  
  // (scrollMarkers) identifies locations in the scroll (by id) which represent pure colors
  // all other locations in the scroll use generated, mixed colors
  scrollMarker : {
    scrollMarker_Top    : 'markerTop'    ,
    scrollMarker_Spring : 'markerSpring' ,
    scrollMarker_Summer : 'markerSummer' ,
    scrollMarker_Autumn : 'markerAutumn' ,
    scrollMarker_Winter : 'markerWinter' ,
    scrollMarker_Bottom : 'markerBottom' ,    
  }, /* scrollMarker */
  
  // the (pointOfColorReference) is the vertical % of the scroll box which is the base point-of-reference
  // A value of 0.5 means exactly in the vertical middle of the scroll box
  // A value of 0.25 means one-quarter down from the top of the scroll box
  pointOfColorReference : 0.3,
}; /* FourSeasonsConstants */


/*********************/
/* FourSeasonsColors */
/**********************************************************************************************/
/* To make this experience more easily customizable,                                          */
/* Each Color to use at location and scroll is indexed by (FourSeasonsConstants.scrollMarker) */
/**********************************************************************************************/
export const FourSeasonsColors = {
  saturatedColor : {
    'markerTop'    : (FourSeasonsConstants.seasonalColor.spring),
    'markerSpring' : (FourSeasonsConstants.seasonalColor.spring),
    'markerSummer' : (FourSeasonsConstants.seasonalColor.summer),
    'markerAutumn' : (FourSeasonsConstants.seasonalColor.autumn),
    'markerWinter' : (FourSeasonsConstants.seasonalColor.winter),
    'markerBottom' : (FourSeasonsConstants.seasonalColor.winter),
  }, /* saturatedColor */
  readingColor : {
    'markerTop'    : (FourSeasonsConstants.lightenedColor.spring),
    'markerSpring' : (FourSeasonsConstants.lightenedColor.spring),
    'markerSummer' : (FourSeasonsConstants.lightenedColor.summer),
    'markerAutumn' : (FourSeasonsConstants.lightenedColor.autumn),
    'markerWinter' : (FourSeasonsConstants.lightenedColor.winter),
    'markerBottom' : (FourSeasonsConstants.lightenedColor.winter),
  }, /* readingColor */
  saturatedRGB : {
    'markerTop'    : (FourSeasonsConstants.seasonalRGB.saturated.spring),
    'markerSpring' : (FourSeasonsConstants.seasonalRGB.saturated.spring),
    'markerSummer' : (FourSeasonsConstants.seasonalRGB.saturated.summer),
    'markerAutumn' : (FourSeasonsConstants.seasonalRGB.saturated.autumn),
    'markerWinter' : (FourSeasonsConstants.seasonalRGB.saturated.winter),
    'markerBottom' : (FourSeasonsConstants.seasonalRGB.saturated.winter),
  }, /* saturatedRGB */
  lightenedRGB : {
    'markerTop'    : (FourSeasonsConstants.seasonalRGB.lightened.spring),
    'markerSpring' : (FourSeasonsConstants.seasonalRGB.lightened.spring),
    'markerSummer' : (FourSeasonsConstants.seasonalRGB.lightened.summer),
    'markerAutumn' : (FourSeasonsConstants.seasonalRGB.lightened.autumn),
    'markerWinter' : (FourSeasonsConstants.seasonalRGB.lightened.winter),
    'markerBottom' : (FourSeasonsConstants.seasonalRGB.lightened.winter),
  }, /* lightenedRGB */
  xItNumber : {
    'markerTop'    : (FourSeasonsConstants.xItNumber.xItSpring),
    'markerSpring' : (FourSeasonsConstants.xItNumber.xItSpring),
    'markerSummer' : (FourSeasonsConstants.xItNumber.xItSummer),
    'markerAutumn' : (FourSeasonsConstants.xItNumber.xItAutumn),
    'markerWinter' : (FourSeasonsConstants.xItNumber.xItWinter),
    'markerBottom' : (FourSeasonsConstants.xItNumber.xItWinter),
  }, /* xItNumber */
}; /* FourSeasonsColors */

