/******************************/
/* FootnoteContentfourSeasons */
/*************************************************************************************************/
/* React component and related components which, together, enable and present the                */
/*     "Four Seasons Sonnets"                                                                    */
/* experience.                                                                                   */
/* Copyright (C) 2021 - 2022 Orcadia Labs LLC                                                    */   
/*************************************************************************************************/
import React,
       {Text}                  from 'react'                                           ; 
import {OrcadiaLabs_Constants} from '../../../constants/constants.js'                 ;
import {ConduitManager, 
        Conduit,ConduitNames } from '../../conduit/ConduitManager.js'                 ;
import ImageQuote              from '../../../assets/images/quotes.png'               ; 
import ImageBrian              from '../../../assets/images/lifeofbrian.png'          ; 
import {FootnoteXit          } from './FootnoteContent.js'                            ;
import {FootnoteDialog       } from '../Footnote.js'                                  ;
import {FootnoteConstants    } from '../FootnoteConstants.js'                         ;
import {FourSeasonsExperience} from '../../../content/fourSeasons/FourSeasons.js'     ;
import ImageFourSeasons        from '../../../content/fourSeasons/assets/images/s4_title20bbutton.png'; 




/******************************/
/* FootnoteContentFourSeasons */
/**********************************************************************/
/* Generate content of footnote for "Four Seasons Sonnets"            */
/**********************************************************************/
export class FootnoteContentFourSeasons extends React.Component {
   
  render() {
    var footnoteProps = {
      // every footnote needs to know its own conduit for communication:
      footnote : {
        footnoteConduit : ConduitNames.footnoteFourSeasonsConduitName
      }, /* footnote */
      
      // the dialog box will have a special top margin:
      footnoteDialog : {
//        footnoteTopSpace : true,
      },
      // the Four Seasons image in the footnote:
      fourSeasons : {
        src             : (ImageFourSeasons    ),
        alt             : ("Four Seasons Sonnets"),
        onClick         : (() => {ConduitManager.getConduit(ConduitNames.footnoteFourSeasonsContentConduitName).ping()}),
      }, /* fourSeasons */
    }; /* footnoteProps */
    
    var textWidth     = 14 * 48;
    var quoteWidth    = 60;
    var textRooming   = 32;
    
    // Four Seasons image is 501 X 546 pixels
    var fourSeasonsWidth  = (OrcadiaLabs_Constants.home.fourSeasons.width  / 2); 
    var fourSeasonsHeight = (OrcadiaLabs_Constants.home.fourSeasons.height / 2);
    
    var footnoteStyles = {
      titleBlock : {
        width        : (fourSeasonsWidth),
        display      : 'flex',
        flexDirection:'column',
        marginLeft   : textRooming,
        marginTop    : 8,
        marginBottom : 8,
      }, /* titleBlock */
      titleContainer : {
        display       : 'flex',
        flexDirection : 'column',
        padding       : (FootnoteConstants.topMarginBorder),
        width         : (fourSeasonsWidth + FootnoteConstants.xitButton.width),
        marginLeft   : textRooming,
        marginTop    : 8,
        marginBottom : 8,
      }, /* titleContainer */
      subText    : {
        display       : 'flex',
        flexDirection : 'column',
//        marginLeft    : (quoteWidth+4),
//        marginRight   : (quoteWidth+4),
      }, /* subText */
      emphasizedText : {
        fontSize       : 18,
        marginTop      : 0,
        marginBottom   : 6,
        fontWeight     : 'semibold',
        lineHeight     : 2,
        width          : '100%',
      }, /* text */
      text : {
        fontSize       : 16,
        marginTop      : 0,
        marginBottom   : 6,
        width          : '100%',
      }, /* text */
      textInText : {
        margin         : (textRooming),
      }, /* textInText */
      attribution : {
        fontSize       : 16,
        marginTop      : 2,
        marginBottom   : 2,
        width          : '100%',
      }, /* text */
      fourSeasons : {
        width     : (fourSeasonsWidth ),
        height    : (fourSeasonsHeight),
        opacity   : 1,
      }, /* fourSeasons */
      fourSeasonsLink : {
        width     : (fourSeasonsWidth ),
        height    : (fourSeasonsHeight),
        opacity   : 1,
        margin    : (8),
      }, /* fourSeasons */
    }; /* footnoteStyles */

    return (
      <>
        <FootnoteDialog {...footnoteProps.footnote} {...footnoteProps.footnoteDialog} >
          <div style = {footnoteStyles.titleContainer}>
            <FootnoteXit {...(footnoteProps.footnote)} />
            <img {...(footnoteProps.fourSeasons)} style = {footnoteStyles.fourSeasons}/>
          </div>
          <div style = {footnoteStyles.subText}>
            <div style={footnoteStyles.textInText}>
            <p style={footnoteStyles.emphasizedText}>Web Technology as Concept Art</p>
            <p style={footnoteStyles.text}>Antonio Vivaldi published the "Four Seasons Sonnets" in 1725, alongside his violin concerto series "Four Seasons," one of the most definitive examples of program music ever composed.</p>
            <p style={footnoteStyles.text}>No one knows who wrote these sonnets, though many suspect Vivaldi, himself, did since they so closely follow the flow of the movements from his concertos.</p>
            <p style={footnoteStyles.text}>This presentation of "Four Seasons Sonnets" is not a technological tour de force.</p>
            <p style={footnoteStyles.text}>It does, however, present an avenue of exploration into the concepts of how information may be presented
                                           in a fashion that is more engaging, more informative and more captivating for more effect.</p>
            <p style={footnoteStyles.text}>Explore and please let us know how you feel.</p>
            </div>
          </div>
        </FootnoteDialog>
      </>
    ); /*return*/
  }; /* render */
    
    
  /********/
  /* CTOR */
  /***********************************************************************************************/
  /* Set up this experience to respond to the "Four Seasons" button in the "Four Seasons"        */
  /* footnote content.                                                                           */
  /***********************************************************************************************/
  constructor(props) {
    super(props);
    ConduitManager.getConduit(ConduitNames.footnoteFourSeasonsContentName).addListener(this);
  }; /* CTOR */
}; /* FootnoteContentFourSeasons */

