/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* Exit button support                                                                        */ 
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC, all rights reserved.                           */
/**********************************************************************************************/
import React,
       {Text,
        TouchableWithoutFeedback      } from 'react'                            ; 
import {FourSeasonsConstants          } from './FourSeasonsConst.js'  ;
import {FourSeasons_ScrollAnimating   } from './FourSeasonsScroll.js' ;
import {FourSeasons_GetScrollAnimating} from './FourSeasonsScroll.js' ;
import {FourSeasonsScrollStatistics   ,
        FourSeasons_SetScrollMarker   ,
        FourSeasons_GetScrollMarker   } from './FourSeasonsStats.js'                   ;
import {ConduitManager, Conduit       ,
        ConduitNames                  } from '../../library/conduit/ConduitManager.js'      ;
import ImageXitSpring                   from './assets/images/s4_xit-spring.png'       ;
import ImageXitSummer                   from './assets/images/s4_xit-summer.png'       ;
import ImageXitAutumn                   from './assets/images/s4_xit-autumn.png'       ;
import ImageXitWinter                   from './assets/images/s4_xit-winter.png'       ;


/***********************/
/* FourSeasons_SetExit */
/**********************************************************************************************/
/* Save the specified exit element for later access.                                          */
/* @param whichExitButton  identifies the particular exit button.                             */
/* @param exitButton       is the indicated exit button element.                              */
/**********************************************************************************************/
let FourSeasons_ExitButton = {
  [FourSeasonsConstants.xItNumber.xItSpring] : null,
  [FourSeasonsConstants.xItNumber.xItSummer] : null,
  [FourSeasonsConstants.xItNumber.xItAutumn] : null,
  [FourSeasonsConstants.xItNumber.xItWinter] : null,
  [FourSeasonsConstants.xItNumber.xItNone  ] : null,
}; /* FourSeasons_ExitButton */

export const FourSeasons_SetExit = (whichExitButton, exitButton) => {
  // remember this (exitButton) element but if only (whichExitButton) is known:
  switch (whichExitButton) {
    case FourSeasonsConstants.xItNumber.xItSpring :
      FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItSpring] = exitButton;
      break;
    case FourSeasonsConstants.xItNumber.xItSummer :
      FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItSummer] = exitButton;
      break;
    case FourSeasonsConstants.xItNumber.xItAutumn :
      FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItAutumn] = exitButton;
      break;
    case FourSeasonsConstants.xItNumber.xItWinter :
      FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItWinter] = exitButton;
      break;
    case FourSeasonsConstants.xItNumber.xItNone   :
      FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItNone  ] = exitButton;
      break;
    default :
  }; /*switch*/
}; /* FourSeasons_SetExit */


/***********************/
/* FourSeasons_GetExit */
/**********************************************************************************************/
/* Retrieve access to the specific exit button element.                                       */
/* @param  whichExitButton identifies the specific exit button element.                       */
/* @return                 the indicated exit button element.                                 */
/**********************************************************************************************/
export const FourSeasons_GetExit = (whichExitButton) => {
  // return the known exit buttont but only when (whichExitButton) is valid:
  switch (whichExitButton) {
    case FourSeasonsConstants.xItNumber.xItSpring :
      return (FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItSpring   ]);
    case FourSeasonsConstants.xItNumber.xItSummer :
      return (FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItSummer   ]);
    case FourSeasonsConstants.xItNumber.xItAutumn :
      return (FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItAutumn   ]);
    case FourSeasonsConstants.xItNumber.xItWinter :
      return (FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItWinter   ]);
    case FourSeasonsConstants.xItNumber.xItNone :
      return (FourSeasons_ExitButton[FourSeasonsConstants.xItNumber.xItNone     ]);
  }; /*switch*/

  // invalid input:
  return null;
}; /* FourSeasons_GetScrollMarker */


/******************/
/* FourSeasonsXit */
/**********************************************************************************************/
/* Four Seasons Sonnets Exit Button.                                                          */
/* The "Four Seasons Sonnets" exit button will have a color-coded background for special      */
/* effect.                                                                                    */
/* @prop -- id identifies which () exit button to generate.                                   */
/**********************************************************************************************/
export class FourSeasonsXit extends React.Component {


  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw just the full-sized block background with color.                                    */
  /********************************************************************************************/
  render() {
    var xItButtonStyles = {
          position        : 'absolute',
          visibility      : 'hidden',
          width           : 48,
          height          : 48,
          right           : 12,
          top             :  4,
    }; /* xItButtonStyles */

    
    // if this is the selected button, then display it:
    // all other buttons will be invisible but they will still be present.
    var whichButton = this.props.id;
    
    if (FourSeasons_GetScrollAnimating()) {    
      if (whichButton ==  FourSeasonsScrollStatistics.GetXitNumber()) {
        xItButtonStyles.visibility = 'visible';
      } /*if*/
    } /*if*/
    else {
      // scroll animating has not yet commenced, show at least the default exit button:
      if (whichButton ==  FourSeasonsConstants.xItNumber.xItNone) {
        xItButtonStyles.visibility = 'visible';
      } /*if*/
    } /*else*/
    
    // select which exit button to build: 
    var xItImage = undefined;
    
    switch (whichButton) {
      case FourSeasonsConstants.xItNumber.xItSpring :
      case FourSeasonsConstants.xItNumber.xItNone   :
             xItImage = ImageXitSpring;
             break;
      case FourSeasonsConstants.xItNumber.xItSummer :
             xItImage = ImageXitSummer;
             break;
      case FourSeasonsConstants.xItNumber.xItAutumn :
             xItImage = ImageXitAutumn;
             break;
      case FourSeasonsConstants.xItNumber.xItWinter :
             xItImage = ImageXitWinter;
             break;
      default : // all expect use cases are defined above.
    }; /*switch*/
    
    return (
        <img id  = {whichButton} style={xItButtonStyles}
             onClick = {this.Goodbye}
             ref = {(xItElement) => {FourSeasons_SetExit(whichButton, xItElement)}} 
             src = {xItImage} />
    ); /*return*/
  }; /* render */
  
 
  /***********/
  /* Goodbye */
  /********************************************************************************************/
  /* On a press of any of the exit buttons, dismiss the Four SeasonsExperience.                */
  /* Also, reset all of the statistics so that their values do not persist.                    */
  /********************************************************************************************/
  Goodbye() {
    FourSeasonsScrollStatistics.Initialize();
    ConduitManager.getConduit(ConduitNames.footnoteFourSeasonsContentConduitName).ping();
  }; /* Goodbye */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* FourSeasonsXit */





