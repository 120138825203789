/**********************/
/* InAnyEvent_Conduit */
/**********************************************************************************************/
/* Method of communication between otherwise unrelated components.                            */ 
/*                                                                                            */ 
/* Copyright (C) 2019-2022 Orcadia Labs LLC                                                   */
/**********************************************************************************************/

export class InAnyEvent_Conduit {  


  /********************/
  /* registerListener */
  /********************************************************************************************/
  /* Register a React Component as a listener to changed on this conduit.                     */
  /*                                                                                          */
  /* @param listener is the React component to be updated upon a notify action                */
  /********************************************************************************************/
  registerListener(listener) {
    this.allListeners.push(listener);
  }; /* registerListener */


  /******************/
  /* pingListeners  */
  /********************************************************************************************/
  /* Notify all listeners of a status update.                                                 */
  /* No arguments, just a notification.                                                       */
  /* @param args - for specific cases of InAnyEvent_Conduit, the owner can ping all listeners */
  /*               with specific data, including an array. The length and contents of this    */
  /*               argument lies within the cognitive domain of the owner and all listener(s) */
  /********************************************************************************************/
  pingListeners(args) {
    if (this.allListeners) {
      for (var x = 0; x < this.allListeners.length; ++x) {
        if ((this.allListeners[x]) && (this.allListeners[x].ping)) {
          this.allListeners[x].ping(args);
        } /*if*/
      } /*for*/
    } /*if*/
  }; /* pingListeners */


  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor() {
    this.allListeners = [];
  }; /* CTOR */
  
}; /* InAnyEvent_Conduit */

