  
/**********************/
/* IAEaBlock.js */
/**********************************************************************************************/
/* The block hook for inanyevent.js                                                           */
/*                                                                                            */
/* Copyright (C) 2022 Orcadia Labs LLC                                                        */    
/**********************************************************************************************/
import React            from 'react'                                      ; 

import {inanyeventStyles              } from './inanyeventStyles.js'                    ;
import  InAnyEventBlockBackgroundImage  from '../../assets/images/UX/swatch/swatch1.png';
import {OrcadiaLabsJsxIF              } from '../../components/OrcadiaLabs_JSXTrix.js';


//*********************************************************
// Specific images for the IAE text block corners:
//*********************************************************
import IAECorner00     from './assets/images/iaeCorner/iaeCorner00.png' ;  //  paper 
import IAECorner04a    from './assets/images/iaeCorner/iaeCorner04a.png';  // recycle dark
import IAECorner04b    from './assets/images/iaeCorner/iaeCorner04b.png';  // recycle leaf
import IAECorner04c    from './assets/images/iaeCorner/iaeCorner04c.png';  // recycle light
import IAECorner07     from './assets/images/iaeCorner/iaeCorner07.png' ;  // fireworks
import IAECorner07b    from './assets/images/iaeCorner/iaeCorner07b.png';  // fireworks with people
import IAECorner10     from './assets/images/iaeCorner/iaeCorner10.png' ;  // iphone plain
import IAECorner11a    from './assets/images/iaeCorner/iaeCorner11a.png';  // iphone RWCS 1
import IAECorner11b    from './assets/images/iaeCorner/iaeCorner11b.png';  // iphone RWCS 2
import IAECorner11c    from './assets/images/iaeCorner/iaeCorner11c.png';  // iphone sTEALth
import IAECorner12     from './assets/images/iaeCorner/iaeCorner12.png' ;  // just people


/*************/
/* IAEaBlock */
/**********************************************************************************************/
/* Renders one block of infomation.                                                           */
/**********************************************************************************************/
export class IAEaBlock extends React.Component {
  render() {
    // block styles:
    var blockStyles = {  
      defaultOuterBlock : {
        display  : 'inline-block',
        position : 'relative',
        top      :0,
        overflow : 'visible',
      }, /* defaultOuterBlock */
      
      defaultBlock : {
        overflow : 'hidden',
        borderRadius : 32,
      }, /* defaultBlock */
    }; /* blockStyles */
      
    // select a "no-waste" image
    var iaeIcon = null;
    switch (this.props['block']) {
        case '00'  : iaeIcon = IAECorner00 ; break;
        case '04a' : iaeIcon = IAECorner04a; break;
        case '04b' : iaeIcon = IAECorner04b; break;
        case '04c' : iaeIcon = IAECorner04c; break;
        case '07'  : iaeIcon = IAECorner07 ; break;
        case '07b' : iaeIcon = IAECorner07b; break;
        case '10'  : iaeIcon = IAECorner10 ; break;
        case '11a' : iaeIcon = IAECorner11a; break;
        case '11b' : iaeIcon = IAECorner11b; break;
        case '11c' : iaeIcon = IAECorner11c; break;
        case '12'  : iaeIcon = IAECorner12 ; break;
          
      // defualt catches case 4, but also any other unassigned case, for safety:
      default      : iaeIcon = null        ; break;
    }; /*switch*/
      
    return (<div style={inanyeventStyles.outerBlock}>
              <div style={inanyeventStyles.block}>
                <div style = {inanyeventStyles.contentBlockV}>
                  <img style = {inanyeventStyles.midBlockTopBottom} src = {InAnyEventBlockBackgroundImage}/> 
                  <div style = {inanyeventStyles.contentBlockH}>
                    <img style = {inanyeventStyles.midBlockLeftRight} src={InAnyEventBlockBackgroundImage}/> 
                    <div style = {inanyeventStyles.foreBlock}>
                      <div style = {inanyeventStyles.text}>{this.props.children}</div>
                    </div>
                    <img style = {inanyeventStyles.midBlockLeftRight} src={InAnyEventBlockBackgroundImage}/>
                  </div> 
                  <img style = {inanyeventStyles.midBlockTopBottom} src={InAnyEventBlockBackgroundImage}/> 
                </div>
              </div>
              <OrcadiaLabsJsxIF if={iaeIcon}>
                <img style={inanyeventStyles.cornerImage} src = {iaeIcon}/>
              </OrcadiaLabsJsxIF>
            </div>
      ); /*return*/
  }; /* render */
  
  
  constructor(props) {
    super (props);
  }; /* CTOR */
}; /* IAEaBlock */









