/************************/
/* InAnyEventPermission.js */
/**********************************************************************************************/
/* Orcadia Labs permissions page for In Any Event...                                          */
/*                                                                                            */    
/* Copyright (C) 2020-2022 Orcadia Labs LLC                                                   */   
/**********************************************************************************************/
import React           from 'react'; 
import InAnyEventImage from './assets/images/inanyevent.png';
import {InAnyEvent}    from '../../components/InAnyEvent.js';


/************************/
/* InAnyEventPermission */
/**********************************************************************************************/
/* Content for Orcadia Labs' In Any Event permission page.                                    */
/**********************************************************************************************/
export class InAnyEventPermission extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Home page content.                                                                       */
  /********************************************************************************************/
  render() {
    var permissionStyles = {
      permission : {                     // entire permission page
        padding          : 16            ,
        display          : 'flex'        ,
        flexDirection    : 'row'         ,
        flexWrap         : 'wrap'        ,
        justifyContent   : 'space-around',
        textAlign        : 'left'        ,
      },
      permissionText : {                // text section of block
        flexDirection    : 'column'      ,
        alignItems       : 'flex-start'  ,
      },
      p : {                             // one statement
        justifySelf      : 'flex-end'    ,
        width            : 200           ,
      },
      image : {
        width            : 120           ,
        height           : 140           ,
      }, /* image */
    }; /* permissionStyles */
    
    
    return (
      <div className = "defaultPage">
        <div style={permissionStyles.permission}>
         <img src={InAnyEventImage} style={permissionStyles.image}/>
           <div style={permissionStyles.permissionText}>
             <p style={permissionStyles.p}>Orcadia Labs, LLC</p>
             <p style={permissionStyles.p}>requires no special permissions in order to provide the fully functional support for</p>
             <InAnyEvent/> 
             <p style={permissionStyles.p}>on phones or on tablets.</p>
           </div>
        </div>
      </div>
    ); /*return*/
  }; /* render */


  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Establish page state.                                                                    */
  /* @param props are the home page properties.                                               */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* InAnyEventPermission */

// original permission text:
// Orcadia Labs, LLC requires no special permissions in order to provide the fully functional support for In Any Event... on phones or tablets.



