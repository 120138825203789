/*********************/
/* InAnyEvent_Colors */
/**********************************************************************************************/
/* Functions all related to colors and related colors.                                        */
/*                                                                                            */
/* Copyright (C) 2019-2021 Orcadia Labs LLC, all rights reserved.                             */
/**********************************************************************************************/
export class InAnyEvent_Colors {

  /********************************************************************************************/
  /* Colors - common definitions                                                              */
  /********************************************************************************************/
  static InAnyEvent_DefaultPrimaryColor   = '#000000'    ;    /* black            */
  static InAnyEvent_NoColor               = 'transparent';    /* no color         */
  static SessionDecorator_YellowHighlight = '#FFFF80'    ;    /* light yellow     */
  static InAnyEvent_DefaultScheduleColor  = '#FFFFFF'    ;    /* white            */  
  
  
  /********************************************************************************************/
  /**                   Orcadia Labs Teals                                                   **/
  /********************************************************************************************/
  /* An Orcadia Labs Teal is one defined as #RRGGBB                                           */
  /*   * RR == #06 .. #0C                                                                     */
  /*   * GG == 21RR                                                                           */
  /*   * BB == 0.995GG                                                                        */
  /*                                                                                          */
  /* The darkest  Orcadia Labs Teal is #067E7D                                                */
  /* The median   Orcadia Labs Teal is #09                                                    */
  /* The lightest Orcadia Labs Teal is #0CFCFA                                                */
  /*                                                                                          */
  /* In Any Event... could support a range of mathematically-correct teals where RR has a     */
  /* value less than #06.  However, the more towards extreme black a color is, the less it    */
  /* shows its (teal) quality.  ie. pure black (#000000) is mathematically correct for GG and */
  /* BB, but is clearly not teal.                                                             */
  /********************************************************************************************/
  static OrcadiaLabs_DarkestTeal          = '#067e7d';
  static OrcadiaLabs_VeryDarkTeal         = '#079392';
  static OrcadiaLabs_DarkTeal             = '#08a8a7';
  static OrcadiaLabs_Teal                 = '#09bdbc';
  static OrcadiaLabs_LightTeal            = '#0ac2c1';
  static OrcadiaLabs_VeryLightTeal        = '#0bd7d6';    
  static OrcadiaLabs_LightestTeal         = '#0cfcfa';     
  
  // The following values exceed Orcadia Labs' official teal definition.
  // They do not retain the 'tealness' of a true Orcadia Labs teal but may
  // be used in cases that require almost-but-not-quite- whites and blacks.
  static OrcadiaLabs_AlmostWhiteTeal      = (InAnyEvent_Colors.lightenColor(InAnyEvent_Colors.OrcadiaLabs_VeryLightTeal,4));
  static OrcadiaLabs_AlmostBlackTeal      = (InAnyEvent_Colors.darkenColor (InAnyEvent_Colors.OrcadiaLabs_DarkestTeal  ,2));
  

  /**********************/
  /* OrcadiaLabs_Colors */
  /********************************************************************************************/
  /* Access to Orcadia Labs colors as a matrix.                                               */
  /* The above colors definitions are accurate and orcadialabs.com does use them.  This       */
  /* matrix contains Orcadia Labs colors.  As much as possible, each array contains color     */
  /* values from lightest to darket.  The advantage to using this matrix instead of the       */
  /* above constant values are:                                                               */
  /*   (1) these are the exact colors used in Paintshop Pro, so match exactly colors in       */
  /*       Orcadia Labs images.                                                               */
  /*   (2) arranged in a grid with lightest colors first ([0]).  To adopt a new color, one    */
  /*       need only to change the index value than use a different constant name.            */
  /********************************************************************************************/
  static OrcadiaLabs_Colors = {
           teals     : [ '#a5ffff', '#0ffff8', '#078a86', '#046664', '#012221', '#001111', ],
           RWCS_reds : [ '#e83248', ],
         }; /* OrcadiaLabs_Colors */


  /*****************************/
  /* getClosestOrcadiaLabsTeal */
  /********************************************************************************************/
  /* Given an input color, return the Orcadia Labs teal (given the above definition) that     */
  /* most closely matches it.                                                                 */
  /*                                                                                          */
  /* @param  color is the color to covert to an Orcadia Labs teal                             */
  /* @return       the most closely matching Orcadia Labs teal                                */
  /********************************************************************************************/
  static getClosestOrcadiaLabsTeal(color) {
    // get 3 color components:
    var red   = parseInt((color.substring(1,3)), 16);
    var green = parseInt((color.substring(3,5)), 16);
    var blue  = parseInt((color.substring(5  )), 16);
    
    // compute new color components:
    var grey     = ((red + green + blue) / 3);
    var newRed   = Math.floor(6 + ((grey / 256) * 7));
    var newGreen = Math.floor(newRed * 21           );
    var newBlue  = Math.floor(newGreen * 0.995      );
    
    // construct the Orcadia Labs teal:
    var redC   = ('00' + newRed  .toString(16)).substr(-2);
    var greenC = ('00' + newGreen.toString(16)).substr(-2);
    var blueC  = ('00' + newBlue .toString(16)).substr(-2);
    var teal   = ('#' + redC + greenC + blueC              );

    return( teal );
  }; /* getClosestOrcadiaLabsTeal */
  
  
  /******************************/
  /* getFarthestOrcadiaLabsTeal */
  /********************************************************************************************/
  /* Given an input color, return the Orcadia Labs teal (given the above definition) that     */
  /* least closely matches it.  By definition, this returned teal will be either the defined  */
  /* darkest or lightest Orcadia Labs teal.                                                   */
  /*                                                                                          */
  /* This method will return the mathematically farthest Orcadia Labs teal from the input     */
  /* color.  However, that teal may not be easily readable when used with the input color.    */
  /* To calculate the most easily readable Orcadia Labs teal from an input color, see         */
  /* getComplementaryOrcadiaLabsTeal().                                                       */
  /*                                                                                          */
  /* @param color      is the color to convert to an Orcadia Labs teal                        */
  /* @return           the least closely matching Orcadia Labs teal                           */
  /********************************************************************************************/
  static getFarthestOrcadiaLabsTeal(color) {
    // get 3 color components:
    var red   = parseInt((color.substring(1,3)), 16);
    var green = parseInt((color.substring(3,5)), 16);
    var blue  = parseInt((color.substring(5  )), 16);
    
    // an OrcadiaLabs teal is mostly blue and green.
    // calculate the blue, green average and then select either of the
    // two Orcadia Labs teals is farthest from it.
    var blueGreen = (green + blue) /2;
    return ((blueGreen > 128) ? InAnyEvent_Colors.OrcadiaLabs_AlmostBlackTeal
                              : InAnyEvent_Colors.OrcadiaLabs_AlmostWhiteTeal
           );
  }; /* getFarthestOrcadiaLabsTeal */


  /***************************/
  /* getFarthesMonotoneColor */
  /********************************************************************************************/
  /* Like getFarthestOrcadiaLabsTeal() but returns pure black or pure white.                  */
  /*                                                                                          */
  /* @param  color     is the color to covert to an Orcadia Labs teal                         */
  /* @return           the least closely matching Orcadia Labs teal                           */
  /********************************************************************************************/
  static getFarthesMonotoneColor(color) {
    // get 3 color components:
    var red   = parseInt((color.substring(1,3)), 16);
    var green = parseInt((color.substring(3,5)), 16);
    var blue  = parseInt((color.substring(5  )), 16);
    
    var average = (green + blue + red) /3;
    return ((average > 128) ? '#000000' : '#FFFFFF');
  }; /* getFarthesMonotoneColor */


  /**********************************/
  /* getComplementaryOrcadiaLabsTeal */
  /********************************************************************************************/
  /* Given an input color, return the Orcadia Labs teal (given the above definition) that is  */
  /* the most easy to compare to it.  Or least closely matches it.  This Orcadia Labs teal,   */
  /* for instance would be the best for reading colored text on a colored background.         */
  /* By example, the mathematically farthest Orcadia Labs teal from pure red (#FF0000) is the */
  /* lightest Orcadia LAbs teal (#0CFCFA).  However, reading pure teal over pure red might be */
  /* difficult, both being highly saturated colors.  The key is to find the closest Orcadia   */
  /* Labs teal to the inversion of the input color.                                           */
  /* (#0CFCFA) may not be easily readable over (#FF0000) but (#067E7D) may better be.         */
  /* To calculate the mathematically farthest Orcadia Labs teal from any given color, use     */
  /* getFartheseOrcadiaLabsTeal().  This function works particular well for highly-saturated. */
  /* light and dark colors.  See the below note for medium-tone greys.                        */
  /*                                                                                          */
  /* @param  color is the color to covert to an Orcadia Labs teal                             */
  /* @return       the least closely matching Orcadia Labs teal                               */
  /*                                                                                          */
  /* TODO: this function works very well with input (color)s that are not too close to        */
  /*       medium-tone grey (#808080).  For input (color)s that are, this function may        */
  /*       require some additional consideration.  Perhaps, the darkest or lightest Orcadia   */
  /*       Labs teal might look better on a medium-tone grey.                                 */
  /********************************************************************************************/
  static getComplementaryOrcadiaLabsTeal(color) {
    return (InAnyEvent_Colors.getClosestOrcadiaLabsTeal(
            InAnyEvent_Colors.invertColor(color))
           );
  }; /* getComplementaryOrcadiaLabsTeal */
  
  
  /***************/
  /* invertColor */
  /********************************************************************************************/
  /* Calculate and return an inverted version of the given color.  If the parms is not        */
  /* provided, return white.                                                                  */
  /* The inversion of pure white is pure black.                                               */
  /* The inversion of pure black is pure white.                                               */
  /* The inversion of the most-medium grey is the most-medium grey.                           */
  /*                                                                                          */
  /* @param  color is the color to invert.                                                    */
  /* @return       the inverted color.                                                        */
  /********************************************************************************************/
  static invertColor(color) {
    if (arguments.length < 1) return '#FFFFFF';
    
    /* compute and return an inverted color:                           */
    var red   = parseInt((color.substring(1,3)), 16);
    var green = parseInt((color.substring(3,5)), 16);
    var blue  = parseInt((color.substring(5  )), 16);

    /* At this point, we can calculate an inverted color:              */
    red   = (255 - red  ).toString(16);
    green = (255 - green).toString(16);
    blue  = (255 - blue ).toString(16);

    /* inverted color component may be less than sixteen, so prefix:   */
    if (red  .length < 2) red   = '0' + red;
    if (green.length < 2) green = '0' + green;
    if (blue .length < 2) blue  = '0' + blue;

    var inverted = ('#' + red + green + blue);
    return (inverted);
  }; /* invertColor */


  /********************************************************************************************/
  /********************************************************************************************/
  /**                  COLOR MIXING                                                          **/
  /********************************************************************************************/
  /********************************************************************************************/
  
  /****************/
  /* lightenColor */
  /********************************************************************************************/
  /* Calculate and return a lightened copy of the given color.                                */
  /*   If no parms are provided, return white.                                                */
  /*   If one parm is provided return a singly-lightened (color).                             */
  /*   If two parms are provided, return the lightened (color).                               */
  /*                                                                                          */
  /* @param color      is the color to lighten.                                               */
  /* @param light      is a numerical value:                                                  */
  /*                   <= 0        - no lightening, return the original (color).              */
  /*                   > 0 and < 1 - lightened with mostly (color) and little white.          */
  /*                                 0.5 means one part white for 2 parts (color).            */
  /*                   == 1          Lighten with one part white to one part (color).         */
  /*                   == 2          Lighten with two parts white to one part (color).        */
  /*                   == 2.5        Lighten with two and a half parts white to 1 part (color)*/
  /*                   and so forth.                                                          */
  /* @return           the lightened color.                                                   */
  /********************************************************************************************/
  static lightenColor(color, light) {
    /* boundary cases: */
    if (0 == arguments.length) return '#ffffff';
    if (1 == arguments.length) return InAnyEvent_Colors.lightenColor(color,1); 
    return InAnyEvent_Colors.mixColors('#ffffff', color, light);
  }; /* darkenColor */

  
  /***************/
  /* darkenColor */
  /********************************************************************************************/
  /* Calculate and return a darkened copy of the given color.                                 */
  /* If no parms are provided, return black.                                                  */
  /* If one parm is provided return a singly-darkened (color).                                */
  /* If two parms are provided, return the darkened (color).                                  */
  /*                                                                                          */
  /* @param color      is the color to darken.                                                */
  /* @param dark       is a numerical value:                                                  */
  /*                   <= 0        - no darkening, return the original (color).               */
  /*                   > 0 and < 1 - darkened with mostly (color) and little black.           */
  /*                                 0.5 means one part black for 2 parts (color).            */
  /*                   == 1          Lighten with one part black to one part (color).         */
  /*                   == 2          Lighten with two parts black to one part (color).        */
  /*                   == 2.5        Lighten with two and a half parts black to one part      */
  /*                                 (color).                                                 */ 
  /*                   and so forth.                                                          */
  /* @return           the darkened color.                                                    */
  /********************************************************************************************/
  static darkenColor(color, dark) {
    /* boundary cases: */
    if (0 == arguments.length) return '#000000';
    if (1 == arguments.length) return InAnyEvent_Colors.darkenColor(color,1); 
    return InAnyEvent_Colors.mixColors('#000000', color, dark);
  }; /* darkenColor */
  
  
  /*************/
  /* mixColors */
  /********************************************************************************************/
  /* Calculate and return a mixed copy of the two input colors.                               */
  /* If no parms are provided,    return the greyest color (#808080),                         */
  /*                              an equal mix of black and white.                            */
  /* If one parm is provided,     return (color1).                                            */
  /* If two parms are provided,   return a 50/50 mix of (color1) and (color2)                 */
  /* If three parms are provided, return a color mix as defined:                              */
  /*                                                                                          */
  /* @param color1     is a color.                                                            */
  /* @param color2     is a color.                                                            */ 
  /* @param mixRatio   is a numerical value:                                                  */
  /*                   == 1        - even mixing, return 50/50 mix of (color1) and (color2).  */
  /*                   <= 0        - no mix, return (color1).                                 */
  /*                   > 0 and < 1 - mix colors with (mix)% color1 and (1-mix)% (color2).     */
  /*                                 0.75 means three parts (color1) to 1 part (color2).      */
  /*                   > 1           the reciprocal condition of < 1                          */
  /*                   and so forth.                                                          */
  /* @return           the mixed color.                                                       */
  /********************************************************************************************/
  static mixColors(color1, color2, mixRatio) {
    // throw out invalid values:
    if (0 == arguments.length) return '#808080';
    if (1 == arguments.length) return color1;
    if (2 == arguments.length) return InAnyEvent_Colors.mixColors(color1, color2, 1);
    if (mixRatio <= 0) return color2;
    
    // decompose (color1) and (color2)
    var red1   = parseInt((color1.substring(1,3)), 16);
    var green1 = parseInt((color1.substring(3,5)), 16);
    var blue1  = parseInt((color1.substring(5  )), 16);
    var red2   = parseInt((color2.substring(1,3)), 16);
    var green2 = parseInt((color2.substring(3,5)), 16);
    var blue2  = parseInt((color2.substring(5  )), 16);
    
    
    var redMix   = Math.round(((red1   * mixRatio) + red2  ) / (mixRatio + 1));
    var greenMix = Math.round(((green1 * mixRatio) + green2) / (mixRatio + 1));
    var blueMix  = Math.round(((blue1  * mixRatio) + blue2 ) / (mixRatio + 1));
    
    // recompose the result color:
    var redC   = ('00' + redMix  .toString(16)).substr(-2);
    var greenC = ('00' + greenMix.toString(16)).substr(-2);
    var blueC  = ('00' + blueMix .toString(16)).substr(-2);
    var mixedColor = ('#' + redC + greenC + blueC);
      
    return(mixedColor);
  };  /* mixColors */
  
    
  
} /* InAnyEvent_Colors */



