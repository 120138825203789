/*****************************/
/* OrcadiaLabsMotivations.js */
/**********************************************************************************************/
/* Orcadia Labs Home Page                                                                     */
/*                                                                                            */    
/* Copyright (C) 2021 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React                       from 'react'                                  ; 
import {OrcadiaLabs_Constants}     from "../../constants/constants.js"           ;

import motivationImageWithPortal   from './assets/images/orcadialabs_New2021f.png';
import motivationImageSolid        from './assets/images/orcadialabs_New2021f.png';

import motivationImage_A1          from './assets/images/motA1.png'              ;
import motivationImage_A2          from './assets/images/motA2.png'              ;
import motivationImage_A3          from './assets/images/motA3.png'              ;
import motivationImage_B1          from './assets/images/motB1.png'              ;
import motivationImage_B2          from './assets/images/motB2.png'              ;
import motivationImage_B3          from './assets/images/motB3.png'              ;
import motivationImage_C1          from './assets/images/motC1.png'              ;
import motivationImage_C2          from './assets/images/motC2.png'              ;
import motivationImage_C3          from './assets/images/motC3.png'              ;
import motivationImage_D1          from './assets/images/motD1.png'              ;
import motivationImage_D2          from './assets/images/motD2.png'              ;
import motivationImage_D3          from './assets/images/motD3.png'              ;
import motivationImage_E1          from './assets/images/motE1.png'              ;
import motivationImage_E2          from './assets/images/motE2.png'              ;
import motivationImage_E3          from './assets/images/motE3.png'              ;
import motivationImage_F1          from './assets/images/motF1.png'              ;
import motivationImage_F2          from './assets/images/motF2.png'              ;
import motivationImage_F3          from './assets/images/motF3.png'              ;
import motivationImage_G1          from './assets/images/motG1.png'              ;
import motivationImage_G2          from './assets/images/motG2.png'              ;
import motivationImage_G3          from './assets/images/motG3.png'              ;
import motivationImage_H1          from './assets/images/motH1.png'              ;
import motivationImage_H2          from './assets/images/motH2.png'              ;
import motivationImage_H3          from './assets/images/motH3.png'              ;
import motivationImage_me          from './assets/images/portrait.png'           ;


// create a field of all images so that they may be algorithmically accessed later:
let Motivations = [
    [ motivationImage_A1, motivationImage_A2, motivationImage_A3],
    [ motivationImage_B1, motivationImage_B2, motivationImage_B3],
    [ motivationImage_C1, motivationImage_C2, motivationImage_C3],
    [ motivationImage_D1, motivationImage_D2, motivationImage_D3],
    [ motivationImage_E1, motivationImage_E2, motivationImage_E3],
    [ motivationImage_F1, motivationImage_F2, motivationImage_F3],
    [ motivationImage_G1, motivationImage_G2, motivationImage_G3],
    [ motivationImage_H1, motivationImage_H2, motivationImage_H3],
]; /* Motivations */


/************************/
/* MotivationsConstants */
/**********************************************************************************************/
/* Most of the MotivationsConstants are self-explanatory.                                     */
/*                                                                                            */
/*   MotivationsConstants.frame:                                                              */
/*     0 - undraw the (old) afore image.                                                      */
/*     1 - undraw the (old) midground image.                                                  */
/*     2 - undraw the (old) background image.                                                 */
/*         Undrawing a background image will leave the motivations animations blank.  My best */
/*         strategy here is that I could "animate" the background layer going out, if needed, */
/*         or, at least, have a pause between "experiences."                                  */
/*     3 - draw the (new) background image, overriding the old one, if it exists.             */
/*     4 - draw the new midground image.                                                      */
/*     5 - draw the new afore image.                                                          */
/**********************************************************************************************/
const MotivationsConstants = {
  block : {                           // large Orcadia Labs image
    width     : (2 * OrcadiaLabs_Constants.SPA.brand.largeWidth ),
    height    : (2 * OrcadiaLabs_Constants.SPA.brand.largeHeight),
  }, /* block */
  
  motivation : {                      // all motivations images
    pixel : {
      width                    : 690,        // pixel width 
      height                   : 812,        // pixel height
      heightToWidthRatio       : undefined   // calculated below
    }, /* pixel */
    percent : {
      width                     : 36.8,      // percent width 
      height                    : 100,       // percent height
      widthRelativeToContainer  : undefined, // relative width - calculated below
      heightRelativeToContainer : undefined  // relative width - calculated below
    } /* percent */
  }, /* motivation */
  
  frame : {                                  // identifying which frame is current
    totalFrames            : 5,
    undrawAforegroundFrame : 0,  // undraw the afore image
    undrawMidgroundFrame   : 1,  // undraw the midground image
    undrawBackgroundFrame  : 2,  // undraw the background, get ready for new background.
    drawBackgroundFrame    : 3,  // draw the (new) background image, overriding the old one, if it exists
    drawMidgroundFame      : 4,  // draw the midground image
    drawAforeFrame         : 5,  // draw the afore image
  },
 
}; /* MotivationsConstants */

/*********************/
/* MotivationsStyles */
/**********************************************************************************************/
/* Common styles to use for animations.                                                       */
/**********************************************************************************************/
const MotivationsStyles = {
  portal : {                                 // identifying where lies the portal
    position : 'absolute',
    top      : 0,
    left     : 0,
    width    : ('' + MotivationsConstants.motivation.percent.width  + '%'),
    height   : ('' + MotivationsConstants.motivation.percent.height + '%'),
  }, /* portal */
}; /* MotivationsStyles */


// Make the entire motivations graphic fit on the screen.
// Give special consideration to phones and tablets.
var temporarySizeFactor = 0.4;
MotivationsConstants.block.width  *= temporarySizeFactor;
MotivationsConstants.block.height *= temporarySizeFactor;
MotivationsConstants.motivation.pixel.width  *= temporarySizeFactor;
MotivationsConstants.motivation.pixel.height *= temporarySizeFactor;


// Calculate some additional values:
MotivationsConstants.motivation.pixel.heightToWidthRatio  
                                = MotivationsConstants.motivation.pixel.height 
                                / MotivationsConstants.motivation.pixel.width;
MotivationsConstants.motivation.percent.widthRelativeToContainer  
                                = MotivationsConstants.motivation.pixel.width 
                                / MotivationsConstants.block.width;
MotivationsConstants.motivation.percent.heightRelativeToContainer  
                                = MotivationsConstants.motivation.pixel.height 
                                / MotivationsConstants.block.height;


/*******************/
/* MotivationsPipe */
/**********************************************************************************************/
/* The (MotivationPipe) coordinates all of the motivations animations.                        */
/* This class recognizes four different animation states:                                     */
/*   0 - undraw the afore image.                                                              */
/*   1 - undraw the midground image.                                                          */
/*   2 - draw the (new) background image, overriding the old one, if it exists.               */
/*   3 - draw the new midground image.                                                        */
/*   4 - draw the new afore image.                                                            */
/**********************************************************************************************/
export class MotivationsPipe {

  /****************/
  /* animateFrame */
  /********************************************************************************************/
  /* Perform the very next frame of animation.  Determine which is the next frame of the      */
  /* current experience and distribute the invocations accordingly.                           */
  /* All values of (experience) and (frame) will be normalized to currently supported values. */
  /* @parm realExperience is the index of the experience to animate (0 ... ?).                */
  /* @parm realFrame      is the index of the frame of this (experience).                     */
  /********************************************************************************************/
  static animateFrame(realExperience = 0, realFrame = 0) {

    // invoke the appropriate animator given two normalized values:
    // in order to show that this is an distinct order, when this animator
    // travels from one experience to the next, clear the old one.
    var afore  = MotivationsPipe.getAfore ();
    var mid    = MotivationsPipe.getAmid  ();
    var behind = MotivationsPipe.getBehind();
    
    switch (realFrame) {
      case MotivationsConstants.frame.undrawAforegroundFrame : 
             MotivationsPipe.animateFrameAndWait(
               (() => {afore.clearFrame()}),
               (realExperience                           ), 
               (MotivationsConstants.frame.undrawMidgroundFrame));
             break;

      case MotivationsConstants.frame.undrawMidgroundFrame  : 
             MotivationsPipe.animateFrameAndWait(
               (() => {mid.clearFrame()}                      ),
               (realExperience                                ),
               (MotivationsConstants.frame.undrawBackgroundFrame));
             break;
                
      case MotivationsConstants.frame.undrawBackgroundFrame  : 
             MotivationsPipe.animateFrameAndWait(
               (() => {behind.clearFrame()}                   ),
               (realExperience                                ),
               (MotivationsConstants.frame.drawBackgroundFrame));
             break;
                
      case MotivationsConstants.frame.drawBackgroundFrame :
             MotivationsPipe.animateFrameAndWait(
                (() => {behind.drawFrame(realExperience)}    ),
                (realExperience                              ) ,
                (MotivationsConstants.frame.drawMidgroundFame));
             break;
               
      case MotivationsConstants.frame.drawMidgroundFame :
             MotivationsPipe.animateFrameAndWait(
               (() => {mid.drawFrame(realExperience)}    ),
               (realExperience                           ), 
               (MotivationsConstants.frame.drawAforeFrame));
             break;
              
      case MotivationsConstants.frame.drawAforeFrame :
             MotivationsPipe.animateFrameAndWait(
               (() => {afore.drawFrame(realExperience)}    ),
               ((realExperience + 1) % Motivations.length  ), 
               (MotivationsConstants.frame.undrawAforegroundFrame));
             break;
             
      default : // unexpected condition, all animations will stop in place
    }; /*switch*/
    
    
    /*****************************/
    /* done animating this frame */
    /*****************************/
  }; /* animateFrame


  /***********************/
  /* animateFrameAndWait */
  /* releaseFrame        */
  /********************************************************************************************/
  /* Mutex-driven gateway to (animateFrame).  Specifically, React allows only a limited       */
  /* number of pending (render) requests.  To stop the Motivations Animations from            */
  /* overflowing the (render) stack, (MotivationsPipe) requests only one (render) at a time.  */
  /* animateFrameAndWait() will start this process by requesting the next frame and waiting.  *
  /*                       The parameters are the same as for (animateFrame).                 */
  /* releaseFrame       () can be called by a Frame renderer to release the mutex so that the */
  /*                       (MotivationsPipe) can proceed to the next render.                  */
  /*                       @param millisecondsToWait is the time to spend before moving on to */
  /*                                                 the next frame.                          */
  /********************************************************************************************/
  static animateFrameAndWait(animator = (()=> {}), nextExperience = 0, nextFrame = 0) {
    
    // save a function to call when the mutex is released by the requesting render() function
    MotivationsPipe.state = ({ animateNextFrame : (() => {MotivationsPipe.animateFrame(nextExperience, nextFrame)}) 
                                                  });
    animator();
  }; /* animateFrameAndWait */


  static releaseFrame(millisecondsToWait = 1000) {
    // if there is a next frame to animate, then do so.
    // asynchronous animations ensure no stack overflow.
    if (MotivationsPipe?.state?.animateNextFrame) {
      setTimeout(() => {MotivationsPipe.state.animateNextFrame();}, millisecondsToWait);
    } /*if*/
  }; /* releaseFrame */
  

  /*****************/
  /* registerAfore */
  /********************************************************************************************/
  /* set which is the (MotivationsAfore) Component that is currently rendering.               */
  /* Start the animations when ready.                                                         */
  /* @param afore is the component.                                                           */
  /*              by default, unregister the Component.                                       */
  /********************************************************************************************/
  static registerAfore(afore = null) {
    MotivationsPipe.aforeComponent = afore;
  }; /* registerAfore */
  

  /************/
  /* getAfore */
  /********************************************************************************************/
  /* @return the afore animation component.                                                   */
  /********************************************************************************************/
  static getAfore() {
    return (MotivationsPipe.aforeComponent);
  }; /* getAfore */


  /****************/
  /* registerAmid */
  /********************************************************************************************/
  /* set which is the (MotivationsAmid) Component that is currently rendering.               */
  /* Start the animations when ready.                                                         */
  /* @param amid is the component.                                                           */
  /*             by default, unregister the Component.                                       */
  /********************************************************************************************/
  static registerAmid(amid = null) {
    MotivationsPipe.amidComponent = amid;
  }; /* registerAfore */
  

  /***********/
  /* getAmid */
  /********************************************************************************************/
  /* @return the amid animation component.                                                   */
  /********************************************************************************************/
  static getAmid() {
    return (MotivationsPipe.amidComponent);
  }; /* getAmid */


  /******************/
  /* registerBehind */
  /********************************************************************************************/
  /* set which is the (MotivationsBehind) Component that is currently rendering.              */
  /* Start the animations when ready.                                                         */
  /* @param behind is the component, by default unregister the component.                     */
  /********************************************************************************************/
  static registerBehind(behind = null) {
    MotivationsPipe.behindComponent = behind;
  }; /* registerBehind */
  

  /*************/
  /* getBehind */
  /********************************************************************************************/
  /* @return the behind animation component.                                                  */
  /********************************************************************************************/
  static getBehind() {
    return (MotivationsPipe.behindComponent);
  }; /* getBehind */
  

  /*******************/
  /* startAnimations */
  /********************************************************************************************/
  /* The animations will start here, usually after the first parent render.                   */
  /* The animations require that both the afore and behind components are ready for           */
  /* animation instructions.                                                                  */
  /********************************************************************************************/
  static startAnimations() {
    // ready? 
    // start animating at the first frame of the first image:
    MotivationsPipe.stopAnimations();
    MotivationsPipe.doAnimate = true;
    MotivationsPipe.animateFrame(0,0);
  }; /* startAnimations */
  
  
  /******************/
  /* stopAnimations */
  /********************************************************************************************/
  /* The animations will stop here, usually as the parent component unmounts.                 */
  /********************************************************************************************/
  static stopAnimations() {
    MotivationsPipe.doAnimate = false;
  }; /* stopAnimations */

  /***************/
  /* isAnimating */
  /********************************************************************************************/
  /* @return whether this pipe is managing the Motivations Animations or is done.             */
  /********************************************************************************************/
  static isAnimating() {
    return MotivationsPipe.doAnimate;
  }; /* isAnimating */
  
  
}; /* MotivationsPipe */


/********************/
/* MotivationsAfore */
/**********************************************************************************************/
/* The part of the MotivationsAnimations that go in front of the brand image                  */
/**********************************************************************************************/
class MotivationsAfore extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw the next "afore" image of Motivations                                               */
  /********************************************************************************************/
  render() {
    // grab information needed to animate the experience' foreground:
    // default to first
    var experience = 0;
    var drawAfore  = false;
    
    if (this.state) {
      experience  = (this.state["experience"]);
      drawAfore   = (this.state["drawAfore" ]);
    } /*if*/
    
    // initially, this component does not have enough informaton, so render nothing:
    // Also, render nothing to simply just clear the image
    if (!drawAfore) {
      return (<></>);
    } /*if*/
    
    // render the experience' foreground:
    return (<img style={MotivationsStyles.portal} src={Motivations[experience][2]}/>);
  }; /* render */
  

  /**************/
  /* clearFrame */
  /********************************************************************************************/
  /* Unrender the image for this Component.                                                   */
  /********************************************************************************************/
  clearFrame() {
    this.setState({ "drawAfore"  : (false   ),
                    "experience" : (0       ),
                 }); /* setState */
  }; /* clearFrame */
  

  /*************/
  /* drawFrame */
  /********************************************************************************************/
  /* Render the afore image for this Component.                                               */
  /* @param experience - is the number of the experience for the Afore.                       */
  /********************************************************************************************/
  drawFrame(experience) {
    this.setState({ "drawAfore"  : (true      ),
                    "experience" : (experience ),
                 }); /* setState */
  }; /* drawFrame */
  

  /**********************/
  /* componentDidUpdate */
  /********************************************************************************************/
  /* Whenever this Component rerenders, cause the () to proceed to the next frame.            */
  /********************************************************************************************/
  componentDidUpdate() {
     // proceed to the next frame
    // if this request is for a clear, then do not wait
    // if this course is to display, then wait a small amount of time.
    var timeToWait = 0;
    if (this.state.drawAfore) {
      timeToWait = 3000;
    } /*if*/
    
   MotivationsPipe.releaseFrame(timeToWait)
  }; /* componentDidUpdate */


  /*********************/
  /* componentDidMount */
  /********************************************************************************************/
  /* Register this component with the Pipe so that it can animate.                            */
  /********************************************************************************************/
  componentDidMount() {
    MotivationsPipe.registerAfore(this);
  }; /* componentDidMount */


  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props); 
  }; /* CTOR */
  
}; /* MotivationsAfore */


/*******************/
/* MotivationsAmid */
/**********************************************************************************************/
/* The part of the MotivationsAnimations that go between the afore an behind images.          */
/**********************************************************************************************/
class MotivationsAmid extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw the next "middle" image of Motivations                                              */
  /********************************************************************************************/
  render() {
    // grab information needed to animate the experience' background:
    // default to first
    var experience = 0;
    var drawAmid   = false;
    
    if (this.state) {
      experience  = (this.state["experience"]);
      drawAmid    = (this.state["drawAmid"  ]);
    } /*if*/
    
    // initially, this component does not have enough informaton, so render nothing:
    // Also, render nothing to simply just clear the image
    if (!drawAmid) {
      return (<></>);
    } /*if*/
    
    // render the experience' midground:
    return (<img style={MotivationsStyles.portal} src={Motivations[experience][1]}/>);
  }; /* render */
  
  
  /**************/
  /* clearFrame */
  /********************************************************************************************/
  /* Unrender the image for this Component.                                                   */
  /********************************************************************************************/
  clearFrame() {
    this.setState({ "drawAmid"   : (false   ),
                    "experience" : (0       ),
                 }); /* setState */
  }; /* clearFrame */
  

  /*************/
  /* drawFrame */
  /********************************************************************************************/
  /* Render the afore image for this Component.                                               */
  /* @param experience - is the number of the experience for the Afore.                       */
  /********************************************************************************************/
  drawFrame(experience) {
    this.setState({ "drawAmid"   : (true      ),
                    "experience" : (experience ),
                 }); /* setState */
  }; /* drawFrame */
  

  /**********************/
  /* componentDidUpdate */
  /********************************************************************************************/
  /* Whenever this Component rerenders, cause the () to proceed to the next frame.            */
  /********************************************************************************************/
  componentDidUpdate() {
    // proceed to the next frame
    // if this request is for a clear, then do not wait
    // if this course is to display, then wait a small amount of time.
    var timeToWait = 0;
    if (this.state.drawAmid) {
      timeToWait = 3000;
    } /*if*/
    
    MotivationsPipe.releaseFrame(timeToWait);
  }; /* componentDidUpdate */


  /*********************/
  /* componentDidMount */
  /********************************************************************************************/
  /* Register this component with the Pipe so that it can animate.                            */
  /********************************************************************************************/
  componentDidMount() {
    MotivationsPipe.registerAmid(this);
  }; /* componentDidMount */


  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props); 
  }; /* CTOR */
  
}; /* MotivationsAmid */


/*********************/
/* MotivationsBehind */
/**********************************************************************************************/
/* The part of the MotivationsAnimations that go between the afore an behind images.          */
/**********************************************************************************************/
class MotivationsBehind extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw the next "behind" image of Motivations                                              */
  /********************************************************************************************/
  render() {
    // grab information needed to animate the experience' background:
    // default to first
    var experience = 0;
    var drawBehind = false;
    
    if (this.state) {
      experience  = (this.state["experience"]);
      drawBehind  = (this.state["drawBehind"  ]);
    } /*if*/
    
    // initially, this component does not have enough informaton, so render nothing:
    // Also, render nothing to simply just clear the image
    if (!drawBehind) {
      return (<></>);
    } /*if*/
    
    // render the experience' background:
    return (<img style={MotivationsStyles.portal} src={Motivations[experience][0]}/>);
  }; /* render */
  
  
  /**************/
  /* clearFrame */
  /********************************************************************************************/
  /* Unrender the image for this Component.                                                   */
  /********************************************************************************************/
  clearFrame() {
    this.setState({ "drawBehind" : (false   ),
                    "experience" : (0       ),
                 }); /* setState */
  }; /* clearFrame */
  

  /*************/
  /* drawFrame */
  /********************************************************************************************/
  /* Render the afore image for this Component.                                               */
  /* @param experience - is the number of the experience for the Afore.                       */
  /********************************************************************************************/
  drawFrame(experience) {
    this.setState({ "drawBehind" : (true      ),
                    "experience" : (experience ),
                 }); /* setState */
  }; /* drawFrame */
  
  
  /**********************/
  /* componentDidUpdate */
  /********************************************************************************************/
  /* Whenever this Component rerenders, cause the () to proceed to the next frame.            */
  /********************************************************************************************/
  componentDidUpdate() {

    // proceed to the next frame
    // if this request is for a clear, then do not wait
    // if this course is to display, then wait a small amount of time.
    var timeToWait = 0;
    if (this.state.drawBehind) {
      timeToWait = 4000;
    } /*if*/
    
    MotivationsPipe.releaseFrame(timeToWait);
  }; /* componentDidUpdate */


  /*********************/
  /* componentDidMount */
  /********************************************************************************************/
  /* Register this component with the Pipe so that it can animate.                            */
  /********************************************************************************************/
  componentDidMount() {
    MotivationsPipe.registerBehind(this);
  }; /* componentDidMount */


  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props); 
  }; /* CTOR */
  
}; /* MotivationsBehind */


/***********************/
/* MotivationsPortrait */
/**********************************************************************************************/
/* A component which is responsible only for displaying my portrait.                          */
/**********************************************************************************************/
class MotivationsPortrait extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw me.                                                                                 */
  /********************************************************************************************/
  render() {
    return (<img style={MotivationsStyles.portal} src={motivationImage_me}/>);
  }; /* render */


  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* MotivationsPortrait */


/**************************/
/* OrcadiaLabsMotivations */
/**********************************************************************************************/
/* Motivations Animations container.                                                          */
/* Presented as a Component.                                                                  */
/**********************************************************************************************/
export class OrcadiaLabsMotivations extends React.Component {

  
  /**********/
  /* render */
  /********************************************************************************************/
  /* Home page content.                                                                       */
  /********************************************************************************************/
  render() {
    
    var styles = {
      motivationsBlock : {
        position  : 'relative',
        top       : 0,
        left      : 0,
        width     : MotivationsConstants.block.width+10,
        height    : MotivationsConstants.block.height+10,
        maxWidth  : '90vw',
        maxHeight : '' + (90 * OrcadiaLabs_Constants.SPA.brand.heightToWidthRatio) + 'vw',
      }, /* motivationsBlock */
      
      motivationsBlockImage : {
        position  : 'absolute',
        top       : 0,
        left      : 0,
        width     : '100%',
        height    : '100%',
      }, /* motivationsBlockImage */
      
    }; /* styles */

    // A (MotivationPipe) coordinates all motivations animations
    var animationPipe = new MotivationsPipe();
    
    var props = {
      motivationsBlockImage1 : {
        src      : (motivationImageSolid),
        alt      : "Orcadia Labs Motivations",
      },  // motivationsBlockImage
      motivationsBlockImage2 : {
        src      : (motivationImageWithPortal),
        alt      : "Orcadia Labs Motivations",
      },  // motivationsBlockImage
      motivationsImage : {
        pipe     : (animationPipe),
      }, // motivationsImage 
    }; // props
    
    // A (MotivationPipe) coordinates all motivations animations
    // Create a container that is exactly the size of the logo image to draw the logo
    // This way, this Component can render the motivations animation,
    // partly behind and partly in front of the logo
    return (
      <div style={styles.motivationsBlock}>
        <img style={styles.motivationsBlockImage} {...props.motivationsBlockImage2} />
        <MotivationsBehind   {...props.motivationsImage}/>
        <MotivationsPortrait/>
        <MotivationsAmid     {...props.motivationsImage}/>
        <img style={styles.motivationsBlockImage} {...props.motivationsBlockImage1} />
        <MotivationsAfore    {...props.motivationsImage}/>
      </div>
    ); /*return*/
  }; /* render */


  /*********************/
  /* componentDidMount */
  /********************************************************************************************/
  /* After mounting, start the motivations animations.                                        */
  /********************************************************************************************/
  componentDidMount() {
    MotivationsPipe.startAnimations();
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Establish home page state.                                                               */
  /* @param props are the home page properties.                                               */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* OrcadiaLabsMotivations */

