/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* Scroll support.                                                                            */ 
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC, all rights reserved.                           */
/**********************************************************************************************/


/**********************************/
/* FourSeasons_SetScrollAnimating */
/**********************************************************************************************/
/* Declare whether scroll animations can occur.  Animations based upon the scroll position    */
/* can animate when (animating) is true and not animate when (animating) is false.            */
/**********************************************************************************************/
let FourSeasons_ScrollAnimating = false;
export const FourSeasons_SetScrollAnimating = (animating) => {
  FourSeasons_ScrollAnimating = !!animating;
}; /* FourSeasons_SetScrollAnimating */



/**********************************/
/* FourSeasons_GetScrollAnimating */
/**********************************************************************************************/
/* Retrieve whether scroll animations can occur.  Animations based upon the scroll position   */
/* can animate when (animating) is true and not animate when (animating) is false.            */
/**********************************************************************************************/
export const FourSeasons_GetScrollAnimating = (animating) => {
  return (!!FourSeasons_ScrollAnimating);
}; /* FourSeasons_GetScrollAnimating */


