
/******************/
/* OrcadiaLabs404 */
/**********************************************************************************************/
/* For routing to an unknown or unsupported route.                                            */
/*                                                                                            */     
/* Copyright (C) 2020-2022 Orcadia Labs LLC                                                   */   
/**********************************************************************************************/ 
// React boilerplate
import React from 'react';
import {Text} from 'react';

// Orcadia Labs 
import './App.css';
import {InAnyEvent_Colors} from './constants/InAnyEvent_Colors.js';

import OrcadiaLabsLogo from './assets/images/orcadialabs_logo.png';


/***************/
/* e404_Styles */
/**********************************************************************************************/
/* Styles for this 404 page.                                                                  */
/**********************************************************************************************/
const e404_styles = {
  e404     : {
               position   : 'absolute',
               top        : 0,
               left       : 0,
               width      : '100%',
               height     : '100%',
               background : (InAnyEvent_Colors.OrcadiaLabs_AlmostWhiteTeal),
             },
  heading  : {
               textAlign  : 'left',
               fontSize   : 'larger',
             },
  text     : {
               textAlign  : 'left',
             },
  emphasis : { fontSize   : 'larger',
               fontWeight : 'bold',
             },
  description : {
               marginLeft : 16,
             },
  sidebar  : {
               position : 'absolute',
               top      : 0,
               right    : 0,
               width    : '22%',
               maxWidth : 256,
             },
  brand    : {
               position : 'absolute',
               top      : 16,
               right    :  '5%',
               width    : '95%',
             },
  volume   : {
               position    : 'absolute',
               left        : 0,
               width       : '75%',
               height      : '100%',
               paddingLeft : 16,
               background  : 'linear-gradient(' 
                             + 'to right, ' 
                             + (InAnyEvent_Colors.OrcadiaLabs_LightestTeal   ) + ', 30%, ' 
                             + (InAnyEvent_Colors.OrcadiaLabs_AlmostWhiteTeal) + ')',
             },
}; /* e404_styles */


/*******/
/* 404 */
/****************************************/
/* Defines the Orcadia Labs 404 page.   */ 
/****************************************/
export class OrcadiaLabs404 extends React.Component { 
  
  render() {
    return (
      <div style={e404_styles.e404}>
        <div style={e404_styles.sidebar}>
          <img src={OrcadiaLabsLogo} style={e404_styles.brand}/>
        </div>
        <div style={e404_styles.volume}>
          <p style={e404_styles.text}>
            <p style={e404_styles.heading}>
              {"Our site can guide to the following destinations.  We are very excited about and are delighted to offer:"}
            </p>
            <ul>
              <li>
                <a href="/"> <p style={e404_styles.emphasis}>OrcadiaLabs.com</p> </a>
                <div style={e404_styles.description}>
                  <p><span style={e404_styles.emphasis}>Discover</span> the Orcadia Labs 
                     very successful process of <span style={e404_styles.emphasis}>Web Production Design.</span>
                  </p>
                  <p>Truly impactful, meaningful and engaging web design can not only 
                  <span style={e404_styles.emphasis}> deliver results</span> but also 
                  <span style={e404_styles.emphasis}> delight customers!</span></p>
                </div>
              </li>
            </ul>
          </p>
          <p style={e404_styles.text}>
          </p>
        </div>
      </div>
    ); /*return*/
  } /* render */
  
  constructor(props) {
    super(props);
  } /* CTOR */
  
  
} /* OrcadiaLabs404 */


