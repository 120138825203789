/************/
/* MeBox.js */
/**********************************************************************************************/
/* Copyright (C) 2021 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {Text} from 'react';
import {InAnyEvent_Colors} from '../constants/InAnyEvent_Colors.js'    ;
import imageMe             from '../assets/images/UX/me0128.png'       ;



/******************************/
/* OrcadiaLabs_AboutConstants */
/**********************************************************************************************/
/* All known values.                                                                          */
/**********************************************************************************************/
let OrcadiaLabs_AboutConstants = {
  innerBox : {
    spacings : {
      top     :  8,  // box top 
      right   :  4,  // box right
      bottom  :  8,  // box bottom
      left    : 12,  // box left
      meRight :  4,  // between me and scrollbar
      meLeft  :  4,  // between me and content
    }, /* spacings */
    
    scrollBarWidth :  12,
    imageWidth     : 160,
    minimumMargin  :   4,
  }, /* innerBox */
  
  outerBox : {
    margin    :  8,    // distance between this (meBox) and anything else
    headspace : 32,    // distance head extends above this (meBox)
  }, /* outerBox */
}; /* OrcadiaLabs_AboutConstants */


/*********/
/* MeBox */
/**********************************************************************************************/
/* A special, styled box that can contain any content and will scroll vertically.             */
/* Particularly, a MeBox featues an image of me without regard to scrolled content.           */
/* All scrolled content should be passed in as children.  (MeBox) does not restrict content.  */
/* A page can contain any number of (MeBox)'s.                                                */
/**********************************************************************************************/
export class MeBox extends React.Component {

  /*************/
  /* getStyles */
  /********************************************************************************************/
  /* @return the styles object for this (MeBox).                                              */
  /********************************************************************************************/
  getStyles() {
  
    return({
    
      // (meBox) encapsulates the entire (MeBox) component and defines the occupied real estate:
      meBox : {
        display         : 'inline-block',
        width           : 500,
        maxWidth        : '80%',
        height          : 300,
        overflow        : 'hidden',
        margin          : (OrcadiaLabs_AboutConstants.outerBox.margin),
      }, /* meBox */
    
      // (positionBox) changes from inline to absolute positioning for children:
      positionBox : {
        position        : 'relative',
        top             : 0,
        width           : '100%',
        height          : '100%',
      }, /* positionBox */
      
      // (outerBox) encapsulates the visible portion of (meBox), everything except my head:
      outerBox : {
        position        : 'absolute',
        top             : (OrcadiaLabs_AboutConstants.outerBox.headspace),
        right           : 0,
        bottom          : 0,
        left            : 0,
        backgroundColor : (InAnyEvent_Colors.OrcadiaLabs_DarkestTeal),
        overflow        : 'visible',
      }, /* outerBox */
      
      // (scopeBox) encapsulates everything inside the (MeBox) except the border area:
      // (scopeBox) is not a visible element but rather just defines real estate.
      scopeBox : {
        position        : 'absolute',
        display         : 'flex',
        top             : (OrcadiaLabs_AboutConstants.innerBox.spacings.top   ),
        right           : (OrcadiaLabs_AboutConstants.innerBox.spacings.right ),
        bottom          : (OrcadiaLabs_AboutConstants.innerBox.spacings.bottom),
        left            : (OrcadiaLabs_AboutConstants.innerBox.spacings.left  ),
        backgroundColor : (InAnyEvent_Colors.OrcadiaLabs_AlmostWhiteTeal      ),
      }, /* scopeBox */
      
      // (contentBox) holds the (meBox) content in a scrollable component
      contentBox : {
        overflowY : 'scroll',
        flex      : 1,
      }, /* contentBox */
      
      // (content) defines the space within (contentBox) for content:
      content : {
        marginTop       : (OrcadiaLabs_AboutConstants.innerBox.minimumMargin),
        marginRight     : (OrcadiaLabs_AboutConstants.innerBox.imageWidth 
                           + OrcadiaLabs_AboutConstants.innerBox.scrollBarWidth
                           + OrcadiaLabs_AboutConstants.innerBox.spacings.meRight
                           + OrcadiaLabs_AboutConstants.innerBox.spacings.meLeft ),
        marginBottom    : (OrcadiaLabs_AboutConstants.innerBox.minimumMargin),
        marginLeft      : (OrcadiaLabs_AboutConstants.innerBox.minimumMargin),
      }, /* content */
      
      // (imgBox) defines real estate for the image:
      // the width value is chosen aesthetically
      imgBox : {
        position        : 'absolute',
        bottom          : 0,
        right           : (OrcadiaLabs_AboutConstants.innerBox.scrollBarWidth
                           + OrcadiaLabs_AboutConstants.innerBox.spacings.meRight),
        width           : (OrcadiaLabs_AboutConstants.innerBox.imageWidth),
        height          : '100%',
      }, /* imgBox */
      
      // (me) defines location for the image:
      me : {
        position        : 'absolute',
        width           : '100%',
        bottom          : 0,
        left            : 0,
      }, /* me */
    }); /*return*/
    
  }; /* getStyles */

  
  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw and return the (MeBox).                                                             */
  /********************************************************************************************/
  render() {
    var styles = this.getStyles();
    
    return (
      <div style={styles.meBox}>
        <div style={styles.positionBox}>
          <div style={styles.outerBox}>
            <div style={styles.scopeBox}>
              <div style={styles.contentBox}>
                <div style={styles.content}>
                  {this.props.children}
                </div>
              </div>
              <div style={styles.imgBox}>
                <img src={imageMe} style={styles.me} alt="Orcadia Labs"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    ); /*return*/
  }; /* render */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* MeBox */
