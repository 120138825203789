
/*******************/
/* OrcadiaLabs.com */
/**********************************************************************************************/
/* Orcadia Labs (index)                                                                       */
/*                                                                                            */    
/* Copyright (C) 2019 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';

// Orcadia Labs - Red Wing Trading Post
import './index.css';
import {App} from './App.js';


import "./inanyevent/masterevents.json";
// Open Red Wing Trading Post with all context and information:
ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, 
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
