/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* The Four Seasons Experience presents the Four Seasons Sonnets Experience as a dialog.      */
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */
/**********************************************************************************************/
import React,
       {Text,
        TouchableWithoutFeedback}    from 'react'                                   ; 
import {FourSeasonsConstants,
        FourSeasonsColors}           from './FourSeasonsConst.js'                   ;
import {FourSeasonsScrollStatistics}           from './FourSeasonsStats.js'                   ;


/***********************************/
/* FourSeasonsExperienceBackground */
/**********************************************************************************************/
/* A component which renders one element: the background for the Four Seasons Sonnets.        */
/* This component is its own renderable because we want to just render it, individually, on a */
/* scroll event.                                                                              */ 
/* @prop -- none expected.                                                                    */
/**********************************************************************************************/
export class FourSeasonsExperienceBackground extends React.Component {


  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw just the full-sized block background with color.                                    */
  /********************************************************************************************/
  render() {
    // We are going to place the background behind the experience
    // It will exist in a very specific location
    var backgroundWidth = FourSeasonsConstants.experience.maxWidth
                        - FourSeasonsConstants.experience.spaceForTitles
  
    var backgroundStyles = {
          outerBoxStyles : {
            position               : 'absolute',
            backgroundColor        : (FourSeasonsScrollStatistics.GetSaturatedColor()),
            left                   : (FourSeasonsConstants.experience.spaceForTitles),
            top                    :  0,
            right                  :  0,
            bottom                 :  0,
            borderTopLeftRadius    : 128,
            borderBottomLeftRadius : 48,
          }, /* outerBoxStyles */
          innerBoxStyles : {
            position               : 'absolute', 
            backgroundColor        : (FourSeasonsScrollStatistics.GetLightenedColor()),
            top                    : (FourSeasonsConstants.experience.scrollBoxPadding.top   ),
            right                  : (FourSeasonsConstants.experience.scrollBoxPadding.right ),
            bottom                 : (FourSeasonsConstants.experience.scrollBoxPadding.bottom),
            left                   : (FourSeasonsConstants.experience.scrollBoxPadding.left  ),
            borderTopLeftRadius    : 8,
            borderBottomLeftRadius : 8,
          }, /* innerBoxStyles */
    }; /* backgroundStyles */
    
    
    return (<div style={backgroundStyles.outerBoxStyles}>
              <div style={backgroundStyles.innerBoxStyles}>
                {this.props.children}
              </div>
            </div>);
  }; /* render */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Initialize the background colors.                                                        */
  /********************************************************************************************/
  constructor(props) {
    super(props, true);
  }; /* CTOR */
}; /* FourSeasonsExperienceBackground */

  
