/*********************/
/* InvigoratePage.js */
/**********************************************************************************************/
/* Orcadia Labs Invigorate Page.                                                              */
/*   -- production design services --                                                         */
/*                                                                                            */    
/* Copyright (C) 2019 - 2021 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React,
       {Image}                 from 'react'                       ; 
import {OrcadiaLabs_Constants} from "../../constants/constants.js";





/*****************************/
/* OrcadiaLabsInvigoratePage */
/**********************************************************************************************/
/* Content for the orcadialabs.com invigorate page.                                           */
/**********************************************************************************************/
export class OrcadiaLabsInvigoratePage extends React.Component {


  /**********/
  /* render */
  /********************************************************************************************/
  /********************************************************************************************/
  render() {
    return (
      <div className = "defaultPage">
        <p className = "textGeneral">Invigorate Page is coming soon.</p>
      </div>
    ); /*return*/
  }; /* render */

}; /* OrcadiaLabsInvigoratePage */








