/***************************/
/* Four Seasons Experience */
/**********************************************************************************************/
/* The Four Seasons Experience presents the Four Seasons Sonnets Experience as a dialog.      */
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */
/**********************************************************************************************/
import React,
       {Text,
        TouchableWithoutFeedback      }  from 'react'                                    ; 
import {OrcadiaLabs_Constants         }  from '../../constants/constants.js'             ;
import {InAnyEvent_Colors             }  from '../../constants/InAnyEvent_Colors.js'     ;
import {ConduitManager                ,
        ConduitNames                  }  from '../../library/conduit/ConduitManager.js'  ;
import {FourSeasonsConstants           ,
        FourSeasonsColors              } from './FourSeasonsConst.js'                    ;
import {FourSeasonsScrollStatistics    ,
        FourSeasons_SetScrollMarker    ,
        FourSeasons_GetScrollMarker    } from './FourSeasonsStats.js'                    ;
import {FourSeasonsXit                 ,
        FourSeasons_SetExit            ,
        FourSeasons_GetExit            } from './FourSeasonsExit.js'                     ;
import {FourSeasonsExperienceBackground} from './FourSeasonsBackground.js'               ;
import {FourSeasonsScape               } from './FourSeasonsScape.js'                    ;
import {FourSeasonsTitle               ,
        FourSeasonsTitleOverlay        } from './FourSeasonsTitle.js'                    ;
import {FourSeasons_GetScrollAnimating ,
        FourSeasons_SetScrollAnimating } from './FourSeasonsScroll.js'                   ;

//images:
import ImageQuote                        from '../../assets/images/quotes.png'           ; 
import ImageFourSeasons                  from '../fourSeasons/assets/images/s4_title20b.png'; 

// exits:
import ImageXitSpring                    from './assets/images/s4_xit-spring.png'        ;
import ImageXitSummer                    from './assets/images/s4_xit-summer.png'        ;
import ImageXitAutumn                    from './assets/images/s4_xit-autumn.png'        ;
import ImageXitWinter                    from './assets/images/s4_xit-winter.png'        ;
 


/*********************/
/* FourSeasonsMarker */
/**********************************************************************************************/
/* Four Seasons Sonnets marker.                                                               */
/* The "Four Seasons Sonnets" marker identifies where in the scroll the user is currently     */
/* viewing                                                                                    */
/* @prop -- id identifies which () exit button to generate.                                   */
/**********************************************************************************************/
export class FourSeasonsMarker extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw just the full-sized block background with color.                                    */
  /********************************************************************************************/
  render() {
    /* select which marker to register: */
    var whichMarker = this.props.id;
    var xItImage    = undefined;
    var titleImage  = undefined;
    
    switch (whichMarker) {
      case FourSeasonsConstants.scrollMarker.scrollMarker_Top :
             xItImage   = ImageXitWinter;
             break;
      case FourSeasonsConstants.xItNumber.xItSpring :
             xItImage   = ImageXitSpring;
             break;
      case FourSeasonsConstants.xItNumber.xItSummer :
             xItImage   = ImageXitSummer;
             break;
      case FourSeasonsConstants.xItNumber.xItAutumn :
             xItImage   = ImageXitAutumn;
             break;
      default : // all expect use cases are defined above.
    }; /*switch*/
    
    return (
      <div id  = {whichMarker} 
           ref = {(markerElement) => {FourSeasons_SetScrollMarker(whichMarker, markerElement)}} />
    ); /*return*/
  }; /* render */
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
}; /* FourSeasonsMarker */


/*************************/
/* FourSeasonsExperience */
/**********************************************************************************************/
/* A component which renders a dialog which contains the actual                               */
/*     "Four Seasons Sonnets"                                                                 */
/* experience.                                                                                */
/**********************************************************************************************/
export class FourSeasonsExperience extends React.Component {


  /******************/
  /* calculateState */
  /********************************************************************************************/
  /* Calculate and return the next state object.  This object will differ from the current    */
  /* one.                                                                                     */
  /* @return the next, different state object.                                                */
  /********************************************************************************************/
  calculateState(reveal) {
    // load the next iteration number to ensure a unique state
    var iteration = ((this.state) ? (this.state.iteration +1) : 1);
    
    // return the object associated with this state:
      return ({
               iteration : (iteration),
               isRevealed: (reveal   ),
             });
  }; /* calculateState */
  
  
  /*****************/
  /* forceRerender */
  /********************************************************************************************/
  /* Redraw this experience.  Establish and maintain state.                                   */
  /* @param reveal states how this experience is to be redrawn.                               */
  /********************************************************************************************/
  forceRerender(reveal) {
    if (this.isRevealed()) {                       // experience is already drawn:
      if (reveal) {                                // and there is a request to redraw it:
        this.setState(this.calculateState(true));  // then redraw it:
      } /*if*/
      else {                                       // but there is a request to undraw it:
        this.setState(this.calculateState(false)); // then undraw it:      
      } /*else*/
    } /*if*/
    else {                                         // experience is not drawn:
      if (reveal) {                                // and there is a request to draw it:
        this.setState(this.calculateState(true));  // then draw it:
      } /*if*/
      else {                                       // but there is a requet to undraw it:
      } /*else*/                                   // just skip it.
    } /*if*/
  }; /* forceRerender */
  
  
  /**************/
  /* isRevealed */
  /********************************************************************************************/
  /* @return a boolean which states whether to draw or undraw the experience.                 */
  /********************************************************************************************/
  isRevealed() {
    return ((this.state) ? this.state.isRevealed : false);
  }; /* isRevealed */
  
  
  /**************/
  /* experience */
  /********************************************************************************************/
  /* Enable (draw) this experience.  Draw the dialog, if necessary and enable its iteraction  */
  /* with the user.  Establish and maintain state.                                            */
  /********************************************************************************************/
  experience() {
    this.forceRerender(true);
  }; /* experience */
  
  
  /**********/
  /* forget */
  /********************************************************************************************/
  /* Disable (undraw) this experience.  Draw the dialog, if necessary and disable its         */
  /* interaction with the user.  Establish and maintain state.                                */
  /********************************************************************************************/
  forget() {
    this.forceRerender(false);
  }; /* forget */
  
  
  /**********/
  /* toggle */
  /********************************************************************************************/
  /* Disable (undraw) this experience.  Draw the dialog, if necessary and disable its         */
  /* interaction with the user.  Establish and maintain state.                                */
  /********************************************************************************************/
  toggle() {
    if (this.isRevealed()) this.forget    ();
    else                   this.experience();
  }; /* toggle */
  
  
  /**********/
  /* pinged */
  /********************************************************************************************/
  /* This conduit listener has been pinged.  Shor or hide it.                                 */
  /********************************************************************************************/
  pinged() {
    this.toggle();
  }; /* pinged */

  
  /***********
  /* render */
  /********************************************************************************************/
  /* Conditionally present or hide the                                                        */
  /*     "Four Seasons Sonnets"                                                               */
  /* experience.                                                                              */
  /* One point of notice:                                                                     */
  /*   * some of the animations rely upon the scroll position of the scrollbox.  Therefore,   */
  /*     we need to obtain a reference to that element.                                       */
  /********************************************************************************************/
  render() {
    var sonnetStyles = {
          // sonnetBoxComplete leaves (virtual) room for the Scape animation images.
          sonnetBoxComplete : {
            position        : 'fixed', 
            display         : 'block',
            overflow        : 'hidden',
            height          : ( (FourSeasonsConstants.experience.defaultHeight)
                              + (FourSeasonsConstants.scape.bottomExtension   )),
            width           : (FourSeasonsConstants.experience.defaultWidth  ),
            maxWidth        : '96%',         // most of the dialog width
            maxHeight       : '90%',
            
            // top and left attempt to center the experience,
            // though is truly centered for just the (maxWidth).
            top             : ('' + ((100 - FourSeasonsConstants.experience.preferredWith) /2) + '%'),
            // left places the box slightly to the right 
            left            : '2%',          // centered horizontally
            zIndex          : 10000,
          },
          sonnetBox : {
            display         : 'block',
            
            // transparent background to make the left-hand side 'seam to scroll' over background content:
            backgroundColor : 'transparent',
            
            // allow the sonnet title to extend to the left:
            overflow        : 'hidden',   
            position        : 'absolute',
            top             : 0,
            right           : 0,
            left            : 0,
            height          : (FourSeasonsConstants.experience.defaultHeight ),
            width           : (FourSeasonsConstants.experience.defaultWidth  ),
            maxWidth        : (FourSeasonsConstants.experience.maxWidth      ),
          }, /* sonnetBox */
          
          innerBox : {
            overflowY       : 'scroll', 
            backgroundColor : 'transparent',
                              // give the Sonnets text enough room to appear over the scape:
            paddingTop      : (FourSeasonsConstants.experience.scrollBoxPadding.top), // a little bound before the heading
            paddingBottom   : (FourSeasonsConstants.scape.scaledImage.height -
                               FourSeasonsConstants.experience.scrollBoxPadding.bottom -11),
            position        : 'absolute',
            top             : (FourSeasonsConstants.experience.scrollBoxPadding.top),
            right           : 0,
// For Future : this property (bottom) is how I can get the scroll out of the way of the bottom animation.
// However, it might be more interesting to keep the scrollbar down level with the bottom of the
// scrollbox but have it scroll (underneath) the bottom image (text needs bottom margin)
            bottom          : (FourSeasonsConstants.experience.scrollBoxPadding.bottom + 11),
            left            : 0,
            width           : (FourSeasonsConstants.experience.defaultWidth  ),
            maxWidth        : (FourSeasonsConstants.experience.maxWidth      ),
          }, /* innerBox */ 
          sonnetAttribution : {
            flex:1,
            display         :'flex',
            flexDirection   : 'column',
            alignItems      : "flex-start",
            textAlign       : 'left',
            marginLeft      : (FourSeasonsConstants.title.horizontalOffset + FourSeasonsConstants.title.dimensions.horizontal),
            minWidth        : 200,
          }, /* sonnetAttribution */
          sonnetVerse : {
            flex:1,
            display         :'flex',
            flexDirection   : 'column',
            alignItems      : "flex-start",
            textAlign       : 'left',
            marginLeft      : (FourSeasonsConstants.title.horizontalOffset + FourSeasonsConstants.title.dimensions.horizontal),
            marginTop       : 16,
          }, /* sonnetTitle */
          sonnetSubtitle : {
            fontWeight      : 'bold',
          }, /* sonnetSubtitle */
          sonnetLyric : {
            width           : 200,
            padding         :  12,
          }, /* sonnetLyric */
          
          FourSeasonsImageTop  : {
            width            : (OrcadiaLabs_Constants.home.fourSeasons.width  / 4),  
            height           : (OrcadiaLabs_Constants.home.fourSeasons.height / 4),
            marginLeft       : (FourSeasonsConstants.title.horizontalOffset  + (FourSeasonsConstants.title.dimensions.horizontal * 0.6)),
          }, /* FourSeasonsImageTop */

          FourSeasonsImageBottom  : {
            width            : (OrcadiaLabs_Constants.home.fourSeasons.width  / 4),  
            height           : (OrcadiaLabs_Constants.home.fourSeasons.height / 4),
            marginLeft       : 16,
          }, /* FourSeasonsImageBottom */
          
          FourSeasonsTrailer : {
            display          : 'flex',
            flexDirection    : 'row', 
            flexWrap         : 'wrap', 
            alignItems       : 'center',
          }, /* FourSeasonsTrailer */
          
        }; /* sonnetStyles */
    
    
    var SonnetVerse = (props) => {
      return(
        <div style={sonnetStyles.sonnetVerse}>{props.children}</div>
      ); /*return*/
    }; /* SonnetVerse */
     
    
    var SonnetAttribution = (props) => {
      return(
        <div style={sonnetStyles.sonnetAttribution}>{props.children}</div>
      ); /*return*/
    }; /* SonnetAttribution */
     
    
    var SonnetSubtitle = (props) => {
      return(
        <div style={sonnetStyles.sonnetSubtitle}>
          {props.children}
        </div>
      ); /*return*/
    }; /* SonnetSubtitle */
    
    
    var SonnetLyric = (props) => {
      return(
        <div style={sonnetStyles.sonnetLyric}>
          {props.children}
        </div>
      ); /*return*/
    }; /* SonnetLyric */
    
    
    // if we wish to have no experience, then just return nothing:  
    if (!this.isRevealed()) return (<></>);
    
    return (
      <div style={sonnetStyles.sonnetBoxComplete}>
        <div style={sonnetStyles.sonnetBox}>
          <FourSeasonsExperienceBackground/>
          <div style={sonnetStyles.innerBox} 
               onScroll = {(scrollEvent) => {this.handleScrollEvent(scrollEvent)}}
               ref      = {(scrollBox  ) => {this.setScrollBox(scrollBox       )}} >
          
            <FourSeasonsMarker id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Top}    />
            <img style = {sonnetStyles.FourSeasonsImageTop}
                 src = {ImageFourSeasons}  alt = {"Four Seasons Sonnets"} />
          
            <FourSeasonsMarker id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Spring} />
            <FourSeasonsTitle  id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Spring}  >
              <p>Spring</p>
            </FourSeasonsTitle>

  
  
            <SonnetVerse>  
              <SonnetSubtitle>Allegro</SonnetSubtitle>
              <p>Springtime is upon us.</p>
              <p>The birds celebrate her return with festive song</p>
              <p>and murmuring streams are</p>
              <p>softly caressed by the breezes.</p>
              <p>Thunderstorms, those heralds of Spring, roar,</p>
              <p> casting their dark mantle over heaven.</p>
              <p>Then they die away to silence, and the birds take up their charming songs once more.</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Largo</SonnetSubtitle> 
              <p>On the flower-strewn meadow, with leafy branches</p>
              <p>rustling overhead, the goat-herd sleeps.</p>
              <p> his faithful dog beside him.</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Allegro</SonnetSubtitle>
              <p>Led by the festive sound of rustic bagpipes.</p>
              <p>nymphs and shepherds lightly dance</p>
              <p>beneath the brilliant canopy of Spring.</p>    
            </SonnetVerse>
      
      
            <FourSeasonsMarker id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Summer} />
            <FourSeasonsTitle  id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Summer}  >  
              <p>Summer</p>
            </FourSeasonsTitle>

                  
                  
                  
            <SonnetVerse>  
              <SonnetSubtitle>Allegro non molto</SonnetSubtitle>
              <p>Under a hard Season, fired up by the Sun</p>
              <p>Languishes man, languishes the flock and burns the pine</p>
              <p>We hear the cuckoo's voice.</p>
              <p>then sweet songs of the turtledove and finch are heard.</p>
              <p>Soft breezes stir the air, but threatening</p>
              <p>the North Wind sweeps them suddenly aside.</p>
              <p>The shepherd trembles, fearing violent storms and his fate.</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Adagio e piano - Presto e forte</SonnetSubtitle>
              <p>The fear of lightning and fierce thunder</p>
              <p>robs his tired limbs of rest.</p>
              <p>As gnats and flies buzz furiously around.</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Presto</SonnetSubtitle>
              <p>Alas, his fears were justified.</p>
              <p>The Heavens thunders and roars and with hail</p>
              <p> Cuts the head off the wheat and damages the grain.</p>
            </SonnetVerse>

      
      
      
            <FourSeasonsMarker id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Autumn} />
            <FourSeasonsTitle  id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Autumn}  >
              <p>Autumn</p>
            </FourSeasonsTitle>

            
            
            
            
            <SonnetVerse>  
              <SonnetSubtitle>Allegro</SonnetSubtitle>
              <p>Celebrates the peasant, with songs and dances.</p>
              <p>The pleasure of a bountiful harvest.</p>
              <p>And fired up by Bacchus' liquor.</p>
              <p> many end their revelry in sleep.</p>
            </SonnetVerse>

            <SonnetVerse>  
              <SonnetSubtitle>Adagio Molto</SonnetSubtitle>
              <p>Everyone is made to forget their cares and to sing and dance</p>
              <p>By the air which is tempered with pleasure</p>
              <p>And (by) the season that invites so many, many</p>
              <p>Out of their sweetest slumber to fine enjoyment</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Allegro</SonnetSubtitle>
              <p>The hunters emerge at the new dawn,</p>
              <p>And with horns and dogs and guns depart upon their hunting</p>
              <p>The beast flees and they follow its trail;</p>
              <p>Terrified and tired of the great noise</p>
              <p>Of guns and dogs, the beast, wounded, threatens</p>
              <p>Languidly to flee, but harried, dies.</p>
            </SonnetVerse>

      
            <FourSeasonsMarker id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Winter} />
            <FourSeasonsTitle  id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Winter}  >
              <p>Winter</p>
            </FourSeasonsTitle>

            
            
            
            <SonnetVerse>  
              <SonnetSubtitle>Allegro non molto</SonnetSubtitle>
              <p>To tremble from cold in the icy snow,</p>
              <p>In the harsh breath of a horrid wind;</p>
              <p>To run, stamping one's feet every moment,</p>
              <p>Our teeth chattering in the extreme cold</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Largo</SonnetSubtitle>
              <p>Before the fire to pass peaceful,</p>
              <p>Contented days while the rain outside pours down.</p>
            </SonnetVerse>
            <SonnetVerse>  
              <SonnetSubtitle>Allegro</SonnetSubtitle>
              <p>We tread the icy path slowly and cautiously,</p>
              <p>for fear of tripping and falling.</p>
              <p>Then turn abruptly, slip, crash on the ground and,</p>
              <p>rising, hasten on across the ice lest it cracks up.</p>
              <p>We feel the chill north winds course through the home</p>
              <p>despite the locked and bolted doors...</p>
              <p>this is winter, which nonetheless</p>
              <p>brings its own delights.</p>  
            </SonnetVerse>
          
            <div style={sonnetStyles.FourSeasonsTrailer}>
              <img style = {sonnetStyles.FourSeasonsImageBottom}
                   src = {ImageFourSeasons}  alt = {"Four Seasons Sonnets"} />
              <SonnetAttribution>  
                <p>"The Four Seasons Sonnets"<br/>Antonio Vivaldi, 1725</p>
              </SonnetAttribution>
            </div>
            
            <FourSeasonsMarker id  = {FourSeasonsConstants.scrollMarker.scrollMarker_Bottom} />
          </div>
        
          <FourSeasonsXit   id={FourSeasonsConstants.xItNumber.xItSpring} />
          <FourSeasonsXit   id={FourSeasonsConstants.xItNumber.xItSummer} />
          <FourSeasonsXit   id={FourSeasonsConstants.xItNumber.xItAutumn} />
          <FourSeasonsXit   id={FourSeasonsConstants.xItNumber.xItWinter} />
          <FourSeasonsXit   id={FourSeasonsConstants.xItNumber.xItNone  } />

        </div>
          
        <FourSeasonsScape id={FourSeasonsConstants.xItNumber.xItWinter} />
        <FourSeasonsScape id={FourSeasonsConstants.xItNumber.xItAutumn} />
        <FourSeasonsScape id={FourSeasonsConstants.xItNumber.xItSummer} />
        <FourSeasonsScape id={FourSeasonsConstants.xItNumber.xItSpring} />
        <FourSeasonsScape id={FourSeasonsConstants.xItNumber.xItNone  } />
        
        <FourSeasonsTitleOverlay id={FourSeasonsConstants.scrollMarker.scrollMarker_Spring} />
        <FourSeasonsTitleOverlay id={FourSeasonsConstants.scrollMarker.scrollMarker_Summer} />
        <FourSeasonsTitleOverlay id={FourSeasonsConstants.scrollMarker.scrollMarker_Autumn} />
        <FourSeasonsTitleOverlay id={FourSeasonsConstants.scrollMarker.scrollMarker_Winter} />

      </div>    
    ); /*return*/
  }; /* render */

  
  /********************************************************************************************/
  /* Lifecycle                                                                                */
  /********************************************************************************************/
  componentDidMount() {
  }; /* componentDidMount */
  
  
  /********************************************************************************************/
  /* All of the animations and control code lies below:                                       */
  /********************************************************************************************/

  /****************/
  /* setScrollBox */
  /********************************************************************************************/
  /* Establish the value of the scroll box for future reference.                              */
  /* @param scrollBox - the scrollbox                                                         */
  /*                    the default value of (null) means that this component does not yet    */
  /*                    know the value of the scroll box.  It will upon render.               */
  /********************************************************************************************/
  setScrollBox(scrollBox = null) {
    this.sonnetsScrollBox = scrollBox;
  }; /* setScrollBox */

  
  /****************/
  /* getScrollBox */
  /********************************************************************************************/
  /* Return the value of the scroll box for reference.                                        */
  /* @return the scrollBox                                                                    */
  /********************************************************************************************/
  getScrollBox(scrollBox) {
    return (this.sonnetsScrollBox);
  }; /* getScrollBox */
  

  /*********************/
  /* handleScrollEvent */
  /********************************************************************************************/
  /* This method will respond to every scroll event.                                          */
  /* For "Four Seasons Sonnets" scroll events, this Component invoke each specific method to  */
  /* animate specific elements.                                                               */
  /* @param scrollEvent is the record with all of the scroll information.                     */ 
  /********************************************************************************************/
  handleScrollEvent(scrollEvent) {
    scrollEvent.preventDefault();

    // The first time of a scroll event, scrolling has started, set the global flag.
    FourSeasons_SetScrollAnimating(true);
    
    // for animations, this component needs to know the scroll position of the scrollBox.
    var scrollBox = this.getScrollBox();
    
    // calculate the physical dimensions of the scroll position:
    var scrollPixels = {
      top    : (scrollBox.scrollTop                         ),
      bottom : (scrollBox.scrollTop + scrollBox.offsetHeight),
    }; /* scrollPixels */
    
    var scrollPercent = {
      top    : ((scrollPixels.top   ) / scrollBox.scrollHeight),
      bottom : ((scrollPixels.bottom) / scrollBox.scrollHeight),
    }; /* scrollPercent */
    
    // Perform individual calculations for scroll animations:
    FourSeasonsScrollStatistics.CalculateStats(scrollPixels);
    
    // Perform special calculations and transforms for the title images:
    FourSeasonsTitleOverlay.HandleScroll(scrollPixels);
    
    this.experience ();
  }; /* handleScrollEvent */
  
  
  /********/ 
  /* CTOR */
  /********************************************************************************************/
  /* Create a new 'disabled' experience dialog and await further action.                      */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.state      = this.calculateState(false);
    ConduitManager.getConduit(ConduitNames.footnoteFourSeasonsContentConduitName).addListener(this);
    this.setScrollBox();
  }; /* CTOR */
  
}; /* FourSeasonsExperience */





