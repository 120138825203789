
/************************/
/* InAnyEventPrivacy.js */
/**********************************************************************************************/
/* Orcadia Labs permissions page for In Any Event...                                          */
/*                                                                                            */    
/* Copyright (C) 2020 - 2022 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React           from 'react'; 
import InAnyEventImage from './assets/images/inanyevent.png';
import {InAnyEvent}    from '../../components/InAnyEvent.js';


/************************/
/* InAnyEventPrivacy */
/**********************************************************************************************/
/* Content for Orcadia Labs' In Any Event permission page.                                    */
/**********************************************************************************************/
export class InAnyEventPrivacy extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Home page content.                                                                       */
  /********************************************************************************************/
  render() {
    var privacyStyles = {
      privacy : {                     // entire privacy page
        padding          : 16            ,
        display          : 'flex'        ,
        flexDirection    : 'row'         ,
        flexWrap         : 'wrap'        ,
        justifyContent   : 'space-around',
        textAlign        : 'left'        ,
      },
      privacyText : {                // text section of block
        flexDirection    : 'column'      ,
        alignItems       : 'flex-start'  ,
      },
      p : {                             // one statement
        justifySelf      : 'flex-end'    ,
        width            : 200           ,
      },
      image : {
        width            : 120           ,
        height           : 140           ,
      }, /* image */
    }; /* privacyStyles */
    
    
    return (
      <div className = "defaultPage">
        <div style={privacyStyles.privacy}>
          <img src={InAnyEventImage} style={privacyStyles.image}/>
          <div style={privacyStyles.privacyText}>
            <p style={privacyStyles.p}>Your privacy is important to us.</p>
            <p style={privacyStyles.p}>Neither Orcadia Labs, LLC nor the</p>
            <InAnyEvent/> 
            <p style={privacyStyles.p}>app collects any personal information from users.</p>
          </div>
        </div>
      </div>
    ); /*return*/
  }; /* render */


  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Establish page state.                                                                    */
  /* @param props are the home page properties.                                               */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
  
}; /* InAnyEventPrivacy */




